import * as React from 'react';
import { FC } from 'react';
import { RootState } from '../../store/rootReducer';
import { AgeGroupClass } from '../../commons/tournament/model';
import { ageGroupActions } from '../../commons/ageGroup/ageGroupActionReducer';
import { BaseStateManager } from '../stateManager/state.manager';
import { ageGroupClassActions } from '../../commons/ageGroupClass/ageGroupClassReducer';

type Props = {
    onStateChange?: (model: AgeGroupClass[]) => void
}

export const AgeGroupClassState: FC<Props> = props => {
    return(<BaseStateManager
        onStateChange={(model: any) => {
            if(props?.onStateChange){
                props.onStateChange(model)
            }
        }} 
        selectorFunc={(state: any) => (state as RootState).ageGroupClasses}
        getterFunc={() => ageGroupClassActions.getAll()}
    />)
}