import { TournamentService } from "../../api/tournament.service";
import { BaseActionReducer } from "../baseActionReducer";
import { TournamentPatchRequest } from "./model";

export const tournamentsActionReducer = new BaseActionReducer({ keyStart: 'TOURNAMENTS', payloadName: 'tournaments' });
export const tournamentActionReducer = new BaseActionReducer({ keyStart: 'TOURNAMENT', payloadName: 'tournament' });
export const tournamentEventsActionReducer = new BaseActionReducer({ keyStart: 'TOURNAMENT_EVENTS', payloadName: 'tournamentevents' });

const tournamentSyncActionCreators = tournamentActionReducer.buildSyncActions();
const tournamentsSyncActionCreators = tournamentsActionReducer.buildSyncActions();
const tournamentEventsSyncActionCreators = tournamentEventsActionReducer.buildSyncActions();

const tournamentClient = new TournamentService();

const asyncActionCreators = {
    get: (sr: TournamentPatchRequest) => {
        return tournamentsActionReducer.createAsyncAction(
            tournamentClient.get(sr));
    },
    getByTournamentClassId: (tournamentClassId: number) => {
        return tournamentActionReducer.createAsyncAction(
            tournamentClient.getByTournamentClassId(tournamentClassId)
        )
    },
    getTournamentEvents: (tournamentClassId: number, tournamentEventId?: number) => {
        return tournamentEventsActionReducer.createAsyncAction(
            tournamentClient.getTournamentEvents(tournamentClassId, tournamentEventId)
        )
    },
    updateAgeGroup:(tournamentId: number, ageGroupId: number)=> {
        return tournamentEventsActionReducer.createAsyncAction(
            tournamentClient.updateAgeGroup(tournamentId, ageGroupId)
        )
    }
};

export const tournamentActions = {
    ...tournamentActionReducer,
    ...tournamentsSyncActionCreators,
    ...tournamentEventsSyncActionCreators,
    ...tournamentSyncActionCreators,
    ...asyncActionCreators
};