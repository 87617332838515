
import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { default as lodash } from 'lodash';
import { SaveOutlined } from '@mui/icons-material';
import { CounterFinishMatch } from './counterFinishMatch';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { ReducerStateProperties } from '../commons/baseReducer';
import { ClubResponse } from '../commons/clubs/model';
import { clubActions } from '../commons/clubs/clubsActionReducer';
import { blockMatchActions } from '../commons/blockMatch/blockMatchActionReducer';
import { getMode } from '../commons/utils/ui.utils';
import { LiveScoreMatchState } from '../pages/leagueMatch/leagueMatchSetCounter';
import { isPageLiveScore } from '../commons/utils/businessUtils';
import { getPlayerServeStyle, isMultipleMatch, setPlayersInitial } from '../commons/utils/matchCounterUtils';
import { PlayerDataBasic } from '../commons/player/model';
import '../styles/functionalities/current-serve-player.css';
import { BreakCounterMatch } from './breakCounterMatch';
import { BlockMatchModel, BlockMatchToSave } from '../commons/blockMatch/model';
import { getUctDateAsLocal } from '../commons/helpers';
import { useParams } from 'react-router-dom';
import MatchCounterInteraction from './matchCounter/matchCounterInteraction';
import MatchCounterDialogBeforeClickStart from './matchCounter/matchCounterDialogBeforeClickStart';
import BlockMatchDialogWhenMatchIsInUse from './blockMatch/blockMatchDialogWhenMatchIsInUse';
import makeStyles from "./muiComponents/core/makeStyles";
import Grid from "./muiComponents/core/Grid";
import Button from "./muiComponents/core/Button";
import Paper from "./muiComponents/core/Paper";
import Fab from "./muiComponents/core/Fab";
import IconButton from "./muiComponents/core/IconButton";
import CircularProgress from "./muiComponents/core/CircularProgress";

export enum MatchSideEnum {
  Home,
  Away
}

enum OperationTypeEnum {
  AddPointHome,
  AddPointAway,
  ToggleSides,
  ToggleLeftSide,
  ToggleRightSide
}

type props = {
  isLoading: boolean,
  isUmpire: boolean,
  initMatchSetResult: MatchSetResult[],
  matchPlayers: MatchPlayers,
  onChange: (event: MatchSetResult[]) => void,
  onStateChange: (matchResults: MatchSetResult[], playersFiledLocation: PlayersFiledLocation, currentPlayerServeId: number) => void,
  matchResultFromMatchCounter?: (matchResults: MatchSetResult[]) => void,
  liveScoreState: LiveScoreMatchState | undefined,
  isReadonly: boolean,
  isLeagueMatch: boolean,
  isTournament: boolean
}


const styles = makeStyles(() => {
  const mode = getMode()

  return ({
    set: {
      marginTop: '2%',
      padding: '2%',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.2rem'
    },
    setCurrent: {
      background: '#f50057',
      color: 'white'
    },
    playerName: {
      textAlign: 'center',
      padding: '5%',
      color: 'white',
      fontSize: '1.4rem'
    },
    clubName: {
      color: 'grey',
      fontSize: '1rem'
    },
    matchFieldExtend: {
      background: mode === 'light' ? '#035c03' : '#424242'
    },

    CounterFieldExtend: {
      background: mode === 'light' ? '#ffffff' : '#424242'
    },
    counter: {
      color: mode === 'light' ? '#0f0f0f' : '#e0e0e0'
    },
    fabPlus: {
      height: '88px',
      width: '88px',

    },
    fabMinus: {
      height: '50px',
      width: '50px',
    },
    Btn: {
      textAlign: 'center',

    },
  })
});

export const MatchCounter: FC<props> = (props) => {
  const maxSetIndex = 3;
  const elevenPointsBreak = 11;
  const classes = styles();
  let { leagueMatchIdStr, leagueMatchIndividualMatchId, tournamentEventIdStr, matchId } = useParams();
  const [isLiveScore] = useState<boolean>(isPageLiveScore())
  const dispatch = useDispatch();
  const [isInitial, setIsInitial] = useState(true)
  const [currentSetIndex, setCurrentSetIndex] = useState<number>(0);
  const [matchResults, setMatchResults] = useState<MatchSetResult[]>(
    props?.initMatchSetResult?.length === 0
      ? [{ score1: 0, score2: 0, setIndex: 0 }]
      : lodash.isNil(props.liveScoreState)
        ? [{ score1: 0, score2: 0, setIndex: 0 }]
        : props.liveScoreState.matchResults
  );

  const [operations, setOperations] = useState(new Array<OperationTypeEnum>());
  const [currentOperationIndex, setCurrentOperationIndex] = useState<number>(-1);
  const [isFinishDialogOpen, setIsFinishDialogOpen] = useState<boolean>(false);
  const [isBreakDialogOpen, setIsBreakDialogOpen] = useState<boolean>(false);
  const [isBreakOne, setIsBreakOne] = useState<boolean>(true);
  const [isBreakEndOfSet, setIsBreakEndOfSet] = useState<boolean>(true);
  const [isEndOfSetState, setIsEndOfSetState] = useState<boolean>(false);
  //const [blockMatchBtn, setBlockMatchBtn] = useState<boolean>(false);
  const [blockMatchMatchInUse, setBlockMatchMatchInUse] = useState<boolean>(true); // Will be false when block inwork
  const [userInteractPoints, setUserInteractPoints] = useState<number>(0);

  const clubState = useSelector<RootState>(state => state.clubs.all) as ReducerStateProperties<ClubResponse[]>
  const [clubs, setClubs] = useState<ClubResponse[]>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [lastMatchFieldSide, setLastMatchFieldSide] = useState<MatchFieldSide | undefined>(undefined)

  const blockMatchesState = useSelector<RootState>((state) => state.blockMatch.all) as ReducerStateProperties<BlockMatchModel[]>

  const [isMultiple] = useState<boolean>(
    isMultipleMatch(props.matchPlayers)
  )

  const getCoreHomePlayer = () => {
    return playersFiledLocation?.playerB ?? playersFiledLocation?.playerA
  }

  const getCoreAwayPlayer = () => {
    return playersFiledLocation?.playerC ?? playersFiledLocation?.playerD
  }

  const [playersFiledLocation, setPlayersFiledLocation] = useState<PlayersFiledLocation>(setPlayersInitial(props.matchPlayers))
  const initCurrentPlayerServeId = () => {
    const { liveScoreState } = props
    if (liveScoreState?.currentPlayerServeId) {
      return liveScoreState?.currentPlayerServeId
    } else {
      return getCoreHomePlayer()?.playerId
    }
  }
  const [currentPlayerServeId, setCurrentPlayerServeId] = useState<number>(
    initCurrentPlayerServeId()
  );

  const initCurrentLastPointSide = () => {
    const defaultSide = MatchSideEnum.Home
    const { liveScoreState } = props
    if (liveScoreState?.currentPlayerServeId && liveScoreState?.playersLocation) {
      const { playersLocation, currentPlayerServeId } = liveScoreState
      const { playerA, playerB, playerC, playerD } = playersLocation
      if (playerA?.playerId === currentPlayerServeId) {
        return playerA.side
      } else if (playerB?.playerId === currentPlayerServeId) {
        return playerB.side
      } else if (playerC?.playerId === currentPlayerServeId) {
        return playerC.side
      } else if (playerD?.playerId === currentPlayerServeId) {
        return playerD.side
      }
    } else {
      return defaultSide
    }
  }

  const [lastPointSide, setLastPointSide] = useState(initCurrentLastPointSide());
  const isPlayerLeftSide = (player: PlayerLocation, playersLocation: PlayersFiledLocation) => {
    const { playerA, playerB } = playersLocation
    return player?.playerId === playerA?.playerId || player?.playerId === playerB?.playerId
  }
  const isPlayerRightSide = (player: PlayerLocation, playersLocation: PlayersFiledLocation) => {
    const { playerC, playerD } = playersLocation
    return player?.playerId === playerC?.playerId || player?.playerId === playerD?.playerId
  }

  const decideWhichPlayerGotPoint = () => {
    const { playerA, playerB, playerC, playerD } = playersFiledLocation
    let nextPlayerServeId = 0;
    const getSidePoints = () => {
      const currentResult = matchResults[matchResults.length - 1]
      return lastPointSide === MatchSideEnum.Home ? currentResult?.score1 : currentResult?.score2
    }
    const processSetPlayerIdMultiple = (playerTop: PlayerLocation, playerBottom: PlayerLocation, playersLocation: PlayersFiledLocation) => {
      if (playerTop.playerId === currentPlayerServeId) {
        nextPlayerServeId = playerTop.playerId
      } else if (playerBottom.playerId === currentPlayerServeId) {
        nextPlayerServeId = playerBottom.playerId
      } else {
        const score = getSidePoints()
        if (score === 0) {
          nextPlayerServeId = playerTop?.side === MatchSideEnum.Away ? playerTop.playerId : playerBottom.playerId
          return;
        }
        const isEven = score % 2 === 0
        const leftSideNextServe = () => {
          if (isEven) {
            nextPlayerServeId = playerBottom.playerId
          } else {
            nextPlayerServeId = playerTop.playerId
          }
        }
        const rightSideNextServe = () => {
          if (isEven) {
            nextPlayerServeId = playerTop.playerId
          } else {
            nextPlayerServeId = playerBottom.playerId
          }
        }
        if (isPlayerLeftSide(playerTop, playersLocation) && isPlayerLeftSide(playerBottom, playersLocation)) {
          leftSideNextServe()
        } else if (isPlayerRightSide(playerTop, playersLocation) && isPlayerRightSide(playerBottom, playersLocation)) {
          rightSideNextServe()
        }

      }
    }

    if (lastPointSide === playerA?.side && isMultiple) {
      processSetPlayerIdMultiple(playerA, playerB, playersFiledLocation)
    } else if (isMultiple) {
      processSetPlayerIdMultiple(playerC, playerD, playersFiledLocation)
    } else {
      if (lastPointSide === playerB?.side) {
        nextPlayerServeId = playerB?.playerId ?? playerA?.playerId
      } else {
        nextPlayerServeId = playerC?.playerId ?? playerD?.playerId
      }
    }
    return nextPlayerServeId
  }

  useEffect(() => {
    dispatch(blockMatchActions.getAllBlockMatch());
  }, [])

  useEffect(() => {
    const actuallyValues = prepareBlockMatchPayloadToDispatch();

    if (blockMatchesState.model?.some(bm => bm.parentMatchId === actuallyValues.parentMatchId)
      && blockMatchesState.model?.some(bm => bm.matchId === actuallyValues.matchId)) {
      //setBlockMatchMatchInUse(true);
    } else {
      //setBlockMatchMatchInUse(false);
    }
  }, [blockMatchesState])


  useEffect(() => {
    if (lodash.isEmpty(clubState.errors) && lodash.isEmpty(clubState.model)
      && !(clubState.loadStarted && clubState.isLoading)) {
      dispatch(clubActions.getAll())
    }
    if (!lodash.isEmpty(clubState.model) && !lodash.isEqual(clubState.model, clubs)) {
      setClubs(clubState.model)
    }
  }, [clubState])

  useEffect(() => {
    if (!lodash.isEmpty(props.liveScoreState?.matchResults)
      && !lodash.isEqual(props.liveScoreState?.matchResults, matchResults)) {
      setMatchResults(matchResults)
      props.matchResultFromMatchCounter(matchResults)
    }

    if (!lodash.isEmpty(props.liveScoreState?.playersLocation)
      && !lodash.isEqual(props.liveScoreState?.playersLocation, playersFiledLocation)) {

      setPlayersFiledLocation(playersFiledLocation)

      const match = getMatch();
      const amountOfPlayers = getAmountOfPlayersField();
      const isSkiftServer = props.isUmpire && !isStart();

      if (isSkiftServer && match.score1 === 0 && match.score2 === 0 && amountOfPlayers === 4) {
        moveUnderlyingPositionBandCRest(playersFiledLocation);
      }

    }
  }, [props.liveScoreState])

  useEffect(() => {
    if (!lodash.isEmpty(matchResults) && (!props.liveScoreState?.currentPlayerServeId || !isInitial)) {
      const nextPlayerServeId = decideWhichPlayerGotPoint()

      setCurrentPlayerServeId(nextPlayerServeId)
      props.onStateChange(matchResults, playersFiledLocation, nextPlayerServeId)
    }

  }, [matchResults, playersFiledLocation, lastPointSide])

  useEffect(() => {
    if (currentSetIndex > maxSetIndex) {
      setCurrentSetIndex(maxSetIndex)
    }

    setIsBreakOne(true);
    setIsBreakEndOfSet(true)
    setIsEndOfSetState(false);
  }, [currentSetIndex])

  useEffect(() => {
    if (isLiveScore) {
      return;
    }

    if (isEndIfSetIndexLower()) {
      let nextIndex = currentSetIndex + 1;
      switchSides()
      if (isMatchSetInvalid(nextIndex) || isTwoMatchesWon(nextIndex)) {
        if (!isInitial) {
          setIsFinishDialogOpen(true)
        }
        setCurrentSetIndex(getLastIndex())
        return;
      }
      let matchResNext = getMatch(nextIndex);
      while (isEndOfSet(matchResNext?.score1, matchResNext?.score2)) {
        nextIndex += 1;
        matchResNext = getMatch(nextIndex)
      }
      let newMatchResults: MatchSetResult[] = null;
      if (!(isMatchSetInvalid(nextIndex) && isTwoMatchesWon(nextIndex))) {
        newMatchResults = updateMatchset(nextIndex, matchResNext?.score1, matchResNext?.score2);
      }
      if (!lodash.isEqual(currentSetIndex, nextIndex)) {
        setCurrentSetIndex(nextIndex)
      }

      if (!lodash.isEqual(matchResults, newMatchResults) && newMatchResults) {
        setMatchResults(newMatchResults);
        props.matchResultFromMatchCounter(newMatchResults);
      }
    } else if (!props.initMatchSetResult && lodash.isEmpty(matchResults)) {
      setMatchResults([{ score1: 0, score2: 0, setIndex: currentSetIndex }])
      props.matchResultFromMatchCounter([{ score1: 0, score2: 0, setIndex: currentSetIndex }]);
    }
    setIsInitial(false)
  }, [matchResults])


  useEffect(() => {
    const pointsOperations = operations
      ?.filter(x => x === OperationTypeEnum.AddPointAway || x === OperationTypeEnum.AddPointHome)
    if (pointsOperations?.length === 0) {
      return;
    }

    if (!isEndIfSetIndexLower()) {
      setPlayersPosition();
    }
  }, [matchResults])

  const increment = (side: MatchSideEnum) => {
    userTryIncrementOrMinusPoints();

    //if (blockMatchBtn === false) {
    //  return;
    //}

    const currentMatch = getMatch()
    let homePoints = currentMatch.score1;
    let awayPoints = currentMatch.score2;
    switch (side) {
      case MatchSideEnum.Home:
        homePoints += 1;
        break;
      case MatchSideEnum.Away:
        awayPoints += 1;
        break;
      default:
        break;
    }
    if (isEndOfSet(currentMatch.score1, currentMatch.score2) && isEndOfSet(homePoints, awayPoints)) {
      return;
    }

    isBreakTimeDialog(homePoints, awayPoints);

    runTimerWhenUserNotUsePoints(userInteractPoints + 1);

    setMatchResults(updateMatchset(currentSetIndex, homePoints, awayPoints))
    props.matchResultFromMatchCounter(updateMatchset(currentSetIndex, homePoints, awayPoints));
  }

  const minus = (side: MatchSideEnum) => {
    userTryIncrementOrMinusPoints();

    //if (blockMatchBtn === false) {
    //  return;
    //}

    const currentMatch = getMatch()
    let homePoints = currentMatch.score1;
    let awayPoints = currentMatch.score2;
    switch (side) {
      case MatchSideEnum.Home:
        homePoints -= 1;
        break;
      case MatchSideEnum.Away:
        awayPoints -= 1;
        break;
      default:
        break;
    }
    if (homePoints < 0 || awayPoints < 0)
      return;
    if (isEndOfSet(currentMatch.score1, currentMatch.score2) && isEndOfSet(homePoints, awayPoints)) {
      return;
    }

    runTimerWhenUserNotUsePoints(userInteractPoints + 1);

    setMatchResults(updateMatchset(currentSetIndex, homePoints, awayPoints))
    props.matchResultFromMatchCounter(updateMatchset(currentSetIndex, homePoints, awayPoints))
  }

  const runTimerWhenUserNotUsePoints = (interactWithPoints: number) => {
    setUserInteractPoints(interactWithPoints);
  }

  const userTryIncrementOrMinusPoints = () => {
    setUserInteractPoints(userInteractPoints + 1);
  }

  const getMatch = (setIndex?: number) => {
    if (setIndex) {
      return matchResults.find(x => x.setIndex === setIndex);
    }
    return matchResults.find(x => x.setIndex === currentSetIndex);
  }

  const isEndOfSet = (score1: number, score2: number) => {
    score1 = score1 ?? 0;
    score2 = score2 ?? 0;
    const isdifferenceRule = Math.abs(score1 - score2) >= 2;
    const absolutePoint = 30;
    return ((score1 >= 21 || score2 >= 21) && isdifferenceRule)
      || (score1 === absolutePoint || score2 === absolutePoint);
  }

  const isMatchSetInvalid = (index: number) => {
    return index > maxSetIndex;
  }

  const isTwoMatchesWon = (index: number) => {
    let homeWons = 0;
    let awayWons = 0;
    const isLastIndex = getLastIndex() >= index
    let isTwoMatchesWon = false;
    if (!isInitial || isLastIndex) {
      matchResults.forEach(item => {
        if (item.score1 > item.score2) {
          homeWons += 1;
        } else if (item.score2 > item.score1) {
          awayWons += 1;
        }
      })
      isTwoMatchesWon = (awayWons >= 2 || homeWons >= 2)
    }
    return isTwoMatchesWon;
  }

  const getLastIndex = () => {
    return matchResults.reduce((prev, current) => {
      return (prev.setIndex > current.setIndex) ? prev : current
    })?.setIndex
  }

  const isEndIfSetIndexLower = () => {
    const currentMatch = getMatch();
    return currentSetIndex <= maxSetIndex && isEndOfSet(currentMatch?.score1, currentMatch?.score2)
  }

  const updateMatchset = (index: number, homePoints: number, awayPoints: number) => {
    const match = matchResults?.find(x => x.setIndex === index);
    if (lodash.isEmpty(match)) {
      const newSet: MatchSetResult = {
        score1: 0,
        score2: 0,
        setIndex: index
      }
      return [
        ...matchResults,
        newSet
      ]
    }

    return matchResults?.map(item => {
      if (item.setIndex === index) {
        item = {
          ...getMatch(index),
          score1: homePoints,
          score2: awayPoints
        };
      }
      return item;
    });
  }

  const toggleSide = (hasAddOperation: boolean) => {
    if (hasAddOperation) {
      addOperation(OperationTypeEnum.ToggleSides)
    }
  }

  const addOperation = (operation: OperationTypeEnum) => {
    let newCurrentIndexOperation = currentOperationIndex !== null ? currentOperationIndex + 1 : 0
    let newOperations = Array.from(operations);
    const lastIndexOperation = operations.length - 1
    if (newCurrentIndexOperation < lastIndexOperation) {
      newOperations = newOperations.slice(newCurrentIndexOperation, lastIndexOperation)
    }
    newOperations.push(operation);
    setOperations(newOperations);
    setCurrentOperationIndex(newOperations.length - 1)
  }

  const backwardOperation = () => {
    const lastOperation = operations[currentOperationIndex]
    setCurrentOperationIndex(currentOperationIndex - 1)
    processOperation(lastOperation, true);
  }

  const forwardOperation = () => {
    const lastOperation = operations[currentOperationIndex + 1]
    setCurrentOperationIndex(currentOperationIndex + 1)
    processOperation(lastOperation, false);
  }

  const processOperation = (lastOperation: OperationTypeEnum, isBackward: boolean) => {
    const currentMatch = getMatch()
    let newMatchRes = matchResults;
    let points = -1;
    switch (lastOperation) {
      case OperationTypeEnum.AddPointHome:
        points = isBackward ? -1 : 1;
        if (points === -1 && currentMatch.score1 === 0) {
          newMatchRes = updateMatchset(currentSetIndex, currentMatch.score1 - points, currentMatch.score2);
        } else {
          newMatchRes = updateMatchset(currentSetIndex, currentMatch.score1 + points, currentMatch.score2);
        }
        break;
      case OperationTypeEnum.AddPointAway:
        points = isBackward ? -1 : 1;
        if (points === -1 && currentMatch.score2 === 0) {
          newMatchRes = updateMatchset(currentSetIndex, currentMatch.score1, currentMatch.score2 - points);
        } else {
          newMatchRes = updateMatchset(currentSetIndex, currentMatch.score1, currentMatch.score2 + points);
        }
        break;
      case OperationTypeEnum.ToggleSides:
        switchSides()
        switchServeSide(false)
        break;
      case OperationTypeEnum.ToggleLeftSide:
        const playerAside = getCoreHomePlayer()?.side
        switchPlayers(playerAside)
        switchServeSidePlayer(playerAside)
        break;
      case OperationTypeEnum.ToggleRightSide:
        const playerCside = playersFiledLocation.playerC?.side
        switchPlayers(playerCside)
        switchServeSidePlayer(playerCside)
        break;
      default:
        break;
    }
    if (!lodash.isEqual(newMatchRes, matchResults)) {
      setMatchResults(newMatchRes)
      props.matchResultFromMatchCounter(newMatchRes)
    }
  }

  const isBackwardVisible = () => {
    return currentOperationIndex > -1;
  }

  const isForwardVisible = () => {
    return currentOperationIndex < (operations.length - 1);
  }

  const handleSubmit = () => {
    props.onChange(matchResults)
    setIsSubmitted(true)
  }

  const onFinishMatchDecission = (isFinish: boolean) => {
    if (!isFinish) {
      backwardOperation()
    }
    setIsFinishDialogOpen(false)
  }

  const switchPlayers = (side?: MatchSideEnum) => {
    const switchPlayerLeft = () => {
      setPlayersFiledLocation({
        ...playersFiledLocation,
        playerB: playersFiledLocation.playerA,
        playerA: playersFiledLocation.playerB
      })
    }
    const switchPlayerRight = () => {
      setPlayersFiledLocation({
        ...playersFiledLocation,
        playerC: playersFiledLocation.playerD,
        playerD: playersFiledLocation.playerC
      })
    }
    const swithPlayerSingle = () => {
      setPlayersFiledLocation({
        ...playersFiledLocation,
        playerB: playersFiledLocation.playerA,
        playerA: playersFiledLocation.playerB,
        playerC: playersFiledLocation.playerD,
        playerD: playersFiledLocation.playerC
      })
    }

    if (!isMultiple) {
      swithPlayerSingle()
    } else {
      switch (side) {
        case MatchSideEnum.Home:

          if (getCoreHomePlayer()?.side === MatchSideEnum.Home) {
            switchPlayerLeft()
          } else {
            switchPlayerRight()
          }

          break;
        case MatchSideEnum.Away:
          if (getCoreAwayPlayer()?.side === MatchSideEnum.Away) {
            switchPlayerRight()
          } else {
            switchPlayerLeft()
          }
          break
        default:
          setPlayersFiledLocation({
            ...playersFiledLocation,
            playerC: playersFiledLocation.playerD,
            playerA: playersFiledLocation.playerB,
            playerB: playersFiledLocation.playerA,
            playerD: playersFiledLocation.playerC
          })
          break;
      }
    }
  }

  const setPlayersPosition = () => {
    const pointsOperations = operations
    const lastOperation = pointsOperations[pointsOperations.length - 1]
    const secondLastOperation = pointsOperations[pointsOperations.length - 2]
    const initialRun = secondLastOperation === undefined;

    if (isMultiple) {
      if (initialRun || (lastOperation === secondLastOperation || secondLastOperation === OperationTypeEnum.ToggleSides)) {
        switchPlayers(
          lastOperation === OperationTypeEnum.AddPointAway ? MatchSideEnum.Away : MatchSideEnum.Home
        )
      }
    } else {
      let pointSide = 0;
      const currentSet = matchResults[currentSetIndex]
      if (lastOperation === OperationTypeEnum.AddPointAway) {
        pointSide = currentSet?.score2 ?? 0
      } else if (lastOperation === OperationTypeEnum.AddPointHome) {
        pointSide = currentSet?.score1 ?? 0
      }
      const isEven = (pointSide % 2) === 0
      singleSwitchPlayers(isEven)
    }
  }

  const singleSwitchPlayers = (isEven: boolean) => {
    const leftPlayer = playersFiledLocation.playerA?.name ? playersFiledLocation.playerA : playersFiledLocation.playerB;
    const rightPlayer = playersFiledLocation.playerC?.name ? playersFiledLocation.playerC : playersFiledLocation.playerD;

    if (isEven) {
      setPlayersFiledLocation({
        ...playersFiledLocation,
        playerB: leftPlayer,
        playerA: {
          ...playersFiledLocation.playerA,
          name: '',
          playerId: undefined
        },
        playerC: rightPlayer,
        playerD: {
          ...playersFiledLocation.playerD,
          name: '',
          playerId: undefined
        }
      })
    } else {
      setPlayersFiledLocation({
        ...playersFiledLocation,
        playerB: {
          ...playersFiledLocation.playerB,
          name: '',
          playerId: undefined
        },
        playerA: leftPlayer,
        playerC: {
          ...playersFiledLocation.playerC,
          name: '',
          playerId: undefined
        },
        playerD: rightPlayer
      })
    }
  }

  const getScoresLeft = () => {
    const match = getMatch()
    const playerAside = getCoreHomePlayer()?.side
    if (playerAside === MatchSideEnum.Home) {
      return match?.score1 ?? 0
    }
    return match?.score2;
  }

  const getScoresRight = () => {
    const match = getMatch()
    const playerAside = playersFiledLocation.playerC?.side;
    if (playerAside === MatchSideEnum.Away) {
      return match?.score2;
    }
    return match?.score1
  }

  let isStart = () => {
    if (getScoresLeft() > 0 || getScoresRight() > 0) { return true; }
    return false;
  }

  const getClubName = (clubId: number) => {
    if (clubId > 0)
      return '(' + clubs?.find(x => x.clubId === clubId)?.clubName + ')'
  }

  const switchServeSidePlayer = (side: MatchSideEnum) => {
    const switchLeft = () => {
      if (isServeLeft()) {
        if (currentPlayerServeId === playersFiledLocation.playerB?.playerId) {
          setCurrentPlayerServeId(playersFiledLocation.playerA?.playerId)
        } else {
          setCurrentPlayerServeId(playersFiledLocation.playerB?.playerId)
        }
      }
    }
    const switchRight = () => {
      if (isServeRight()) {
        if (currentPlayerServeId === playersFiledLocation.playerD?.playerId) {
          setCurrentPlayerServeId(playersFiledLocation.playerC?.playerId)
        } else {
          setCurrentPlayerServeId(playersFiledLocation.playerD?.playerId)
        }
      }
    }

    switch (side) {
      case MatchSideEnum.Home:
        if (getCoreHomePlayer()?.side === MatchSideEnum.Home) {
          switchLeft()
        } else {
          switchRight()
        }
        break;
      case MatchSideEnum.Away:
        if (getCoreAwayPlayer()?.side === MatchSideEnum.Away) {
          switchRight()
        } else {
          switchLeft()
        }
        break;
    }
  }

  const getAmountOfPlayersField = () => {
    const { playerA, playerB, playerC, playerD } = playersFiledLocation;
    const players = [playerA, playerB, playerC, playerD];
    let counter = 0;

    players.forEach((player) => {
      if (!!player && lodash.isNumber(player?.playerId)) {
        counter += 1;
      }
    });

    return counter;
  }

  const moveUnderlyingPositionBandCRest = (playersFiledLocation: PlayersFiledLocation) => {
    /* moveUnderlyingPositionBandCPart for rest */
    const { playerA, playerB, playerC, playerD } = playersFiledLocation;

    if (!lodash.isEmpty(playerA)
      && !lodash.isEmpty(playerB)
      && !lodash.isEmpty(playerC)
      && !lodash.isEmpty(playerD)) {
      if (playerA?.playerId === currentPlayerServeId) {
        setLastPointSide(playerB.side);
        setCurrentPlayerServeId(playerB.playerId);
      } else if (playerB?.playerId === currentPlayerServeId) {
        setLastPointSide(playerB.side);
        setCurrentPlayerServeId(playerB.playerId);
      } else if (playerC?.playerId === currentPlayerServeId) {
        setLastPointSide(playerC.side);
        setCurrentPlayerServeId(playerC.playerId);
      } else if (playerD?.playerId === currentPlayerServeId) {
        setLastPointSide(playerC.side);
        setCurrentPlayerServeId(playerC.playerId);
      }
    }
  }

  const moveUnderlyingPositionBandC = (playersFiledLocation: PlayersFiledLocation) => {
    /* moveUnderlyingPositionBandC for skiftServer */
    const { playerA, playerB, playerC, playerD } = playersFiledLocation;

    if (!lodash.isEmpty(playerA)
      && !lodash.isEmpty(playerB)
      && !lodash.isEmpty(playerC)
      && !lodash.isEmpty(playerD)) {
      if (playerA?.playerId === currentPlayerServeId) {
        setLastPointSide(playerC.side);
        setCurrentPlayerServeId(playerC.playerId);
      } else if (playerB?.playerId === currentPlayerServeId) {
        setLastPointSide(playerC.side);
        setCurrentPlayerServeId(playerC.playerId);
      } else if (playerC?.playerId === currentPlayerServeId) {
        setLastPointSide(playerB.side);
        setCurrentPlayerServeId(playerB.playerId);
      } else if (playerD?.playerId === currentPlayerServeId) {
        setLastPointSide(playerB.side);
        setCurrentPlayerServeId(playerB.playerId);
      }
    }
    }

  const switchServeSide = (isSkiftServer: boolean) => {
    const match = getMatch();
    const amountOfPlayers = getAmountOfPlayersField();

      if (!isSkiftServer && match.score1 === 0 && match.score2 === 0 && amountOfPlayers === 4) {
          return;
      } else if (isSkiftServer && match.score1 === 0 && match.score2 === 0 && amountOfPlayers === 4) {
          moveUnderlyingPositionBandC(playersFiledLocation);
          return;
      }

    if (isServeLeft()) {
      const nexSide = getCoreHomePlayer().side === MatchSideEnum.Away ? MatchSideEnum.Home : MatchSideEnum.Away;
      setLastPointSide(nexSide)
    } else if (isServeRight()) {
      const nexSide = getCoreAwayPlayer().side === MatchSideEnum.Away ? MatchSideEnum.Home : MatchSideEnum.Away;
      setLastPointSide(nexSide)
    }

    }

  const switchSides = () => {
    setPlayersFiledLocation({
      ...playersFiledLocation,
      playerC: playersFiledLocation.playerB,
      playerA: playersFiledLocation.playerD,
      playerB: playersFiledLocation.playerC,
      playerD: playersFiledLocation.playerA
    })
  }

  //const getClubId = (primary: PlayerLocation, second: PlayerLocation) => {
  //  if (primary?.name) {
  //    return primary.clubId
  //  }
  //  return second.clubId
  //}

  const getClubId = (primary: PlayerLocation) => {
    if (primary?.name) {
      return primary.clubId
    }
  }
  const chooseSet = (setIndex: number) => {
    setCurrentSetIndex(setIndex)
  }
  const isServeLeft = () => lastPointSide === getCoreHomePlayer().side;
  const isServeRight = () => lastPointSide === getCoreAwayPlayer().side;

  const isSkiftServerLeftForUmpire = (
    <>
      {props.isUmpire && !isStart()
        ? (
          <Grid item xs={3} style={{ display: 'inline-grid', visibility: !isServeLeft() ? 'hidden' : 'initial' }}>
            <Button onClick={() => {
              toggleSide(true);
              switchServeSide(true)
            }}>Skift server</Button>
          </Grid>)
        : (<Grid item xs={3} />)
      }
    </>
  )

  const isSkiftServerRightForUmpire = (
    <>
      {props.isUmpire && !isStart()
        ? (
          <Grid item xs={3} style={{ display: 'inline-grid', visibility: !isServeRight() ? 'hidden' : 'initial' }}>
            <Button onClick={() => {
              toggleSide(true);
              switchServeSide(true)
            }}>Skift server</Button>
          </Grid>)
        : (<Grid item xs={3} />)
      }
    </>
  )

  const isBreakTimeDialog = (score1: number, score2: number) => {
    if (isBreakOne && (score1 === elevenPointsBreak || score2 === elevenPointsBreak)) {
      setIsBreakDialogOpen(true);
      setIsBreakOne(false)
    }

    const endOfSet = isEndOfSet(score1, score2);
    if (isBreakEndOfSet && endOfSet) {
      setIsBreakDialogOpen(true);
      setIsBreakEndOfSet(false)
      setIsEndOfSetState(true);
    }
  }

  const isBreakCounterMatchClosed = (value: boolean) => {
    setIsBreakDialogOpen(false);
  }

  //const blockMatchHandle = () => {
  //  const payload: BlockMatchToSave = prepareBlockMatchPayloadToDispatch();

  //  if (blockMatchBtn) {
  //    setBlockMatchBtn(false)
  //    //dispatch(blockMatchActions.deleteBlockMatch(payload));
  //    //MatchCounterInteraction will remove in useEffect cleanfunction
  //  } else {
  //    setBlockMatchBtn(true)
  //    dispatch(blockMatchActions.saveBlockMatch(payload));
  //  }
  //}

  const prepareBlockMatchPayloadToDispatch = () => {
    let parentMatchIdFromProps = props.liveScoreState?.parentMatchId;
    let matchIdFromProps = props.liveScoreState?.matchId;

    if (props.isLeagueMatch) {
      if (lodash.isEmpty(parentMatchIdFromProps)) {
        parentMatchIdFromProps = parseInt(leagueMatchIdStr);
      }
      if (lodash.isEmpty(matchIdFromProps)) {
        matchIdFromProps = parseInt(leagueMatchIndividualMatchId);
      }
    } else if (props.isTournament) {
      if (lodash.isEmpty(parentMatchIdFromProps)) {
        parentMatchIdFromProps = parseInt(tournamentEventIdStr);
      }
      if (lodash.isEmpty(matchIdFromProps)) {
        matchIdFromProps = parseInt(matchId);
      }
    }

    const payload: BlockMatchToSave = {
      matchId: matchIdFromProps,
      parentMatchId: parentMatchIdFromProps,
      startBlockedDataTime: getUctDateAsLocal(new Date()),
      isBlock: true,
    };

    return payload;
  }

  return (
    <React.Fragment>

      {/*{blockMatchMatchInUse &&*/}
      {/*  <BlockMatchDialogWhenMatchIsInUse*/}
      {/*    isMatchBlock={blockMatchMatchInUse}*/}
      {/*    setIsMatchBlock={() => { }}*/}
      {/*  />*/}
      {/*}*/}

      {/*{blockMatchBtn ? (*/}
      {/*  <MatchCounterInteraction*/}
      {/*    userInteractWithPoints={userInteractPoints}*/}
      {/*    unlockMatch={prepareBlockMatchPayloadToDispatch}*/}
      {/*    setBlockMatchBtn={setBlockMatchBtn}*/}
      {/*    blockMatchBtnStartLeave={blockMatchBtn}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <MatchCounterDialogBeforeClickStart*/}
      {/*    userInteraction={userInteractPoints}*/}
      {/*    isMatchStart={blockMatchBtn}*/}
      {/*  />*/}
      {/*)}*/}

      {isBreakDialogOpen && props.isUmpire &&
        <BreakCounterMatch
          onClose={isBreakCounterMatchClosed}
          isClosed={isBreakDialogOpen}
          matchScore={getMatch}
          endOfSet={isEndOfSetState}
        />
      }

      {isFinishDialogOpen && <CounterFinishMatch onDecission={(value) => onFinishMatchDecission(value)} />}
      <Grid container style={{ marginBottom: isLiveScore ? '2%' : 'initial' }}>
        <Grid item xs={2} style={{ position: 'absolute', width: '100%' }}>
          {matchResults.map((item, index) => {
            return (
              <Paper style={{ cursor: 'pointer' }} key={index} onClick={() => chooseSet(item.setIndex)} elevation={1} className={`${classes.set} ${item.setIndex === currentSetIndex ? classes.setCurrent : ''}`}>
                {item.score1 ? item.score1 : 0} - {item.score2 ? item.score2 : 0}
              </Paper>
            )
          })}
        </Grid>
      </Grid>

      <Grid container item xs={12} md={12} xl={12} spacing={1} style={{paddingBottom:"14px"}}>
        {!isLiveScore &&
          <React.Fragment>
            <Grid container item xs={6} spacing={1}>
              <Grid className={`btn-field ${classes.CounterFieldExtend}`} item xs={12}>
                <div className={classes.Btn}>
                  {
                    getScoresLeft() > 0 ?
                      <Fab
                        className={`counter-component__count-label ${classes.fabMinus}`}
                        color={lastPointSide === getCoreHomePlayer()?.side ? "secondary" : "default"} size="small" onClick={() => {
                          const sideToIncrement = getCoreHomePlayer()?.side
                          minus(sideToIncrement);
                          setLastPointSide(sideToIncrement);
                          addOperation(sideToIncrement === MatchSideEnum.Home ? OperationTypeEnum.AddPointHome : OperationTypeEnum.AddPointAway);
                        }}>
                        <RemoveIcon />
                      </Fab> : (
                        <Fab
                          className={`counter-component__count-label ${classes.fabMinus}`}
                          color={lastPointSide === getCoreHomePlayer()?.side ? "secondary" : "default"} size="small">
                          <RemoveIcon />
                        </Fab>
                      )
                  }
                </div>
              </Grid>
              <Grid className={`btn-field ${classes.CounterFieldExtend}`} item xs={12}>
                <div className={classes.Btn}>
                  <Fab
                    className={`counter-component__count-label ${classes.fabPlus}`}
                    color={lastPointSide === getCoreHomePlayer()?.side ? "secondary" : "default"} aria-label="add"
                    onClick={() => {
                      const sideToIncrement = getCoreHomePlayer()?.side
                      increment(sideToIncrement);
                      setLastPointSide(sideToIncrement);
                      addOperation(sideToIncrement === MatchSideEnum.Home ? OperationTypeEnum.AddPointHome : OperationTypeEnum.AddPointAway);
                      setLastMatchFieldSide(MatchFieldSide.Left);
                    }}>
                    <AddIcon />
                  </Fab>
                </div>
              </Grid>
              <Grid className={`btnScore-field ${classes.CounterFieldExtend}`} item xs={12}>
                <div className={classes.Btn}>
                  <h1 className={`counter-component__count-label ${classes.counter}`}>{getScoresLeft()}</h1>
                </div>
              </Grid>
            </Grid>

            <Grid container item xs={1} spacing={1}>
              <div className={classes.Btn}>
                {/*<Button variant="outlined" onClick={blockMatchHandle}>*/}
                {/*  {blockMatchBtn ? "Stop" : "Start"}*/}
                {/*</Button>*/}
              </div>
            </Grid>

            <Grid container item xs={5} spacing={1}>
              <Grid className={`btn-field ${classes.CounterFieldExtend}`} item xs={12}>
                <div className={classes.Btn}>
                  <>
                    {
                      getScoresRight() > 0 ?
                        <Fab
                          className={`counter-component__count-label ${classes.fabMinus}`}
                          color={lastPointSide === playersFiledLocation.playerC?.side ? "secondary" : "default"} size="small" onClick={() => {
                            const sideToIncrement = playersFiledLocation.playerC?.side
                            minus(sideToIncrement);
                            setLastPointSide(sideToIncrement);
                            addOperation(sideToIncrement === MatchSideEnum.Home ? OperationTypeEnum.AddPointHome : OperationTypeEnum.AddPointAway);
                          }}>
                          <RemoveIcon />
                        </Fab> : (
                          <Fab
                            className={`counter-component__count-label ${classes.fabMinus}`}
                            color={lastPointSide === playersFiledLocation.playerC?.side ? "secondary" : "default"} size="small">
                            <RemoveIcon />
                          </Fab>
                        )
                    }</>
                </div>
              </Grid>
              <Grid className={`btn-field ${classes.CounterFieldExtend}`} item xs={12}>
                <div className={classes.Btn}>
                  <Fab className={`counter-component__count-label ${classes.fabPlus}`}
                    color={lastPointSide === playersFiledLocation.playerC?.side ? "secondary" : "default"} aria-label="add" onClick={() => {
                      const sideToIncrement = playersFiledLocation.playerC?.side
                      increment(sideToIncrement);
                      setLastPointSide(sideToIncrement);
                      addOperation(sideToIncrement === MatchSideEnum.Away ? OperationTypeEnum.AddPointAway : OperationTypeEnum.AddPointHome);
                      setLastMatchFieldSide(MatchFieldSide.Right);
                    }}>
                    <AddIcon />
                  </Fab>
                </div>
              </Grid>
              <Grid className={`btnScore-field ${classes.CounterFieldExtend}`} item xs={12}>
                <div className={classes.Btn}>
                  <h1 className={`counter-component__count-label ${classes.counter}`}>{getScoresRight()}</h1>
                </div>
              </Grid>
            </Grid>
            <Grid container item xs={1} style={{ display: 'contents' }}></Grid>
          </React.Fragment>}
      </Grid>

      <Grid container spacing={3}>
        {props.isUmpire && <Grid container item xs={1} style={{ display: 'contents' }}>
          {!isLiveScore &&
            <IconButton style={{ padding: '0px' }}
              onClick={() => {
                if (getAmountOfPlayersField() > 2) {
                  const playerBside = getCoreHomePlayer().side
                  switchPlayers(playerBside)
                  switchServeSidePlayer(playerBside)
                  addOperation(OperationTypeEnum.ToggleLeftSide)
                }
              }}>
              <CompareArrowsIcon style={{ width: '2rem', height: '3rem', transform: 'rotate(90deg)' }} />
            </IconButton>
          }
        </Grid>
        }
        {props.isUmpire &&
          <Grid container item xs={isLiveScore ? 12 : 10} className={`match-box`}>
            <Grid className={`match-field ${classes.matchFieldExtend}`} id={'field_a'} item xs={6}>
              <div className={`${classes.playerName} ${getPlayerServeStyle(currentPlayerServeId, playersFiledLocation?.playerA?.playerId)}`}>{playersFiledLocation?.playerA?.name} {getClubName(getClubId(playersFiledLocation?.playerA))}</div>
            </Grid>
            <Grid className={`match-field ${classes.matchFieldExtend}`} id={'field_c'} item xs={6}>
              <div className={`${classes.playerName} ${getPlayerServeStyle(currentPlayerServeId, playersFiledLocation?.playerC?.playerId)}`}>{playersFiledLocation?.playerC?.name} {getClubName(getClubId(playersFiledLocation?.playerC))}</div>
            </Grid>
            <Grid className={`match-field ${classes.matchFieldExtend}`} id={'field_b'} item xs={6}>
              <div className={`${classes.playerName} ${getPlayerServeStyle(currentPlayerServeId, playersFiledLocation?.playerB?.playerId)}`}>{playersFiledLocation?.playerB?.name} {getClubName(getClubId(playersFiledLocation?.playerB))}</div>
            </Grid>
            <Grid className={`match-field ${classes.matchFieldExtend}`} id={'field_d'} item xs={6}>
              <div className={`${classes.playerName} ${getPlayerServeStyle(currentPlayerServeId, playersFiledLocation?.playerD?.playerId)}`}>{playersFiledLocation?.playerD?.name} {getClubName(getClubId(playersFiledLocation?.playerD))}</div>
            </Grid>
          </Grid>
        }
        {!props.isUmpire &&
          <Grid container item xs={isLiveScore ? 12 : 10} className={`match-box`}>
            <Grid className={`match-field ${classes.matchFieldExtend}`} item xs={6}>
              <div className={`${classes.playerName}`}>{playersFiledLocation?.playerA?.name} {getClubName(getClubId(playersFiledLocation?.playerA))}</div>
              <div className={`${classes.playerName}`}>{playersFiledLocation?.playerB?.name} {getClubName(getClubId(playersFiledLocation?.playerB))}</div>
            </Grid>

            <Grid className={`match-field ${classes.matchFieldExtend}`} item xs={6}>
              <div className={`${classes.playerName}`}>{playersFiledLocation?.playerC?.name} {getClubName(getClubId(playersFiledLocation?.playerC))}</div>
              <div className={`${classes.playerName}`}>{playersFiledLocation?.playerD?.name} {getClubName(getClubId(playersFiledLocation?.playerD))}</div>
            </Grid>
          </Grid>
        }

        {props.isUmpire && <Grid container style={{ display: 'contents' }} item xs={1}>
          {!isLiveScore &&
            <IconButton style={{ padding: '0px' }}
              onClick={() => {
                if (getAmountOfPlayersField() > 2) {
                  const playerCside = playersFiledLocation.playerC?.side
                  switchPlayers(playerCside)
                  switchServeSidePlayer(playerCside)
                  addOperation(OperationTypeEnum.ToggleRightSide)
                }
              }}>
              <CompareArrowsIcon style={{ width: '2rem', height: '3rem', transform: 'rotate(90deg)' }} />
            </IconButton>
          }
        </Grid>}
      </Grid>

          {!isLiveScore &&
              <Grid container spacing={3}>
                  {isSkiftServerLeftForUmpire}
                  
                  <Grid item xs={6} style={{ display: 'inline-grid' }}>
                      {currentSetIndex < 2 &&
                          <IconButton
                              onClick={() => {
                                  switchSides()
                                  switchServeSide(false)
                                  addOperation(OperationTypeEnum.ToggleSides)
                              }}>
                              <CompareArrowsIcon style={{ width: '3rem', height: '3rem' }} />
                          </IconButton>
                      }
                      {currentSetIndex === 2 &&
                          <IconButton
                              onClick={() => {
                                  switchSides()
                              }}>
                              <CompareArrowsIcon style={{ width: '3rem', height: '3rem' }} />
                          </IconButton>
                          }
                   </Grid>               

          {isSkiftServerRightForUmpire}
        </Grid>
      }

      {!isLiveScore &&
        <Grid container spacing={3} style={{ visibility: isBackwardVisible() ? 'initial' : 'hidden' }}>
          <Grid item xs={1}>
            <IconButton
              onClick={() => backwardOperation()}>
              <ChevronLeftIcon style={{ width: '3rem', height: '3rem' }} />
            </IconButton>
          </Grid>
          <Grid item xs={1} style={{ visibility: isForwardVisible() ? 'initial' : 'hidden' }}>
            <IconButton
              onClick={() => forwardOperation()}>
              <ChevronRightIcon style={{ width: '3rem', height: '3rem' }} />
            </IconButton>
          </Grid>
        </Grid>}

      {(!isLiveScore && !props.isReadonly) &&
        <Grid container>
          <Grid item xs={10}>
            <Button
              variant="contained"
              color="secondary"
              endIcon={props.isLoading ? <CircularProgress style={{ color: '#f50057' }} /> : <SaveOutlined />}
              disabled={props.isLoading}
              onClick={handleSubmit}>
              {isSubmitted && "Gemt"}
              {!isSubmitted && "Afslut kamp"}
            </Button>
          </Grid>
        </Grid>}

    </React.Fragment>
  )
}



export interface MatchSetResult {
  setIndex: number,
  score1: number,
  score2: number
}
export interface MatchSideSet {
  setIndex: number,
  score: number,
}

export interface MatchPlayers {
  homeTeam: MatchTeam,
  awayTeam: MatchTeam
}
export class MatchPlayer {
  name: string
  playerId: number
  clubId: number
  constructor(name: string, playerId: number, clubId: number) {
    this.name = name
    this.playerId = playerId
    this.clubId = clubId
  }
  static createInstance(playerLocation: PlayerLocation | PlayerDataBasic) {
    return new MatchPlayer(playerLocation?.name, playerLocation?.playerId,
      playerLocation?.clubId
    )
  }
}
export interface MatchTeam {
  player1: MatchPlayer,
  player2: MatchPlayer,
  clubId: number
}

export interface PlayersFiledLocation {
  playerA: PlayerLocation,
  playerB: PlayerLocation,
  playerC: PlayerLocation,
  playerD: PlayerLocation
}

export class PlayerLocation {
  name: string
  clubId: number
  playerId: number
  side: MatchSideEnum
  constructor(name: string, clubId: number, playerId: number, side: MatchSideEnum) {
    this.name = name;
    this.clubId = clubId;
    this.playerId = playerId;
    this.side = side
  }
  static createInstance(matchPlayer: MatchPlayer, side: MatchSideEnum): PlayerLocation | undefined {
    if (!matchPlayer) return undefined;
    const { name, playerId, clubId } = matchPlayer
    return new PlayerLocation(name, clubId, playerId, side)
  }
}

enum MatchFieldSide {
  Left,
  Right
}





