import React, { FC, useEffect, useState } from "react"
import { LeagueMatchAggregateViewModel } from "../../commons/leagueMatch/model"
import { CourtForm } from "../courts/courtForm"
import * as lodash from 'lodash'
import { useDispatch, useSelector } from "react-redux"
import { courtsActions } from "../../commons/courts/courtsActionReducer"
import { RootState } from "../../store/rootReducer"
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer"
import { CourtLeagueMatchIndividualMatchResponse } from "../../commons/courts/model"
import { SaveOutlined } from "@mui/icons-material"
import { getMode } from "../../commons/utils/ui.utils"
import DeleteIcon from '@mui/icons-material/Delete';
import { CourtMatchesFormIndividualMatch } from "./courtMatchesFormIndividualMatch"
import { leagueMatchActions } from "../../commons/leagueMatch/leagueMatchReducer"
import { CourtData } from "../tournament/nextCounterTournament"
import Grid from "../muiComponents/core/Grid";
import IconButton from "../muiComponents/core/IconButton";
import Button from "../muiComponents/core/Button";


type props = {
  match: LeagueMatchAggregateViewModel,
  currentLeagueMatchIndividualMatchId: number
  handleNextMatch: (leagueMatchIndividualMatchId: number) => void,
  handleCourtStateChange: (courtData: CourtData) => void,
  onRemoveAssigment: () => void,
  isReadonly: boolean
}


export const NextCounterIndividualMatch: FC<props> = (props) => {
  const dispatch = useDispatch()
  const [courtData, setCourtData] = useState<CourtData>(undefined)
  const matchHomeClub = props.match.match.clubId1
  const homeClubIdState = useSelector<RootState>(x => x.leagueMatch.homeClubId) as ReducerStateProperties<number>
  const [homeClubId, setHomeClubId] = useState<number | undefined>(matchHomeClub)
  const assigmentState = useSelector<RootState>(x => {
    return x.courts.courtAssigment
  }) as ReducerStateProperties<CourtLeagueMatchIndividualMatchResponse>
  const isAssigmentModelEmpty = () => lodash.isEmpty(assigmentState?.model)
  const [isDispatched, setIsDispatched] = useState<boolean>(false)

  const handleCourtChange = (courtId: number, courtNumber: number, isMatchii: boolean) => {
    setCourtData({
      ...courtData,
      courtId: courtId, isMatchii: isMatchii, courtNumber: courtNumber
    })
  }
  const [leagueMatchIndividualMatchId, setLeagueMatchIndividualMatchId] = useState<number | undefined>(isAssigmentModelEmpty() ? props.currentLeagueMatchIndividualMatchId : undefined)
  const handleLeagueMatchIndividualChange = (leagueMatchIndividualMatchId: number) => {
    setLeagueMatchIndividualMatchId(leagueMatchIndividualMatchId)
  }

  useEffect(() => {
    const leagueMatchId = props?.match?.match?.leagueMatchId
    const statusNotInitiated = RequestStatus.NotInitiated
    if (!homeClubId && homeClubIdState.status.valueOf() === statusNotInitiated && leagueMatchId > 0) {
      dispatch(leagueMatchActions.getHomeClubId(leagueMatchId))
    }
    if (!homeClubId && homeClubIdState.model > 0) {
      setHomeClubId(homeClubIdState.model)
    }
  }, [homeClubIdState])

  useEffect(() => {
    if (!isAssigmentModelEmpty() && isDispatched &&
      lodash.isEqual(assigmentState.model?.leagueMatchIndividualMatchId, leagueMatchIndividualMatchId)) {
      setIsDispatched(false)
      props.handleNextMatch(leagueMatchIndividualMatchId)
    }
    if (!isAssigmentModelEmpty() &&
      assigmentState.model?.leagueMatchIndividualMatchId !== props.currentLeagueMatchIndividualMatchId &&
      (assigmentState.status !== RequestStatus.Started && assigmentState.status !== RequestStatus.Ok)) {
      dispatch(courtsActions.getCurrentCourt(props.currentLeagueMatchIndividualMatchId))
    }
    if (isAssigmentModelEmpty() && !lodash.isEqual(props.currentLeagueMatchIndividualMatchId, leagueMatchIndividualMatchId)) {
      setLeagueMatchIndividualMatchId(props.currentLeagueMatchIndividualMatchId)
    }
    if (!lodash.isEmpty(assigmentState?.model) && assigmentState.status === RequestStatus.Ok && !lodash.isEqual(assigmentState?.model?.courtId, courtData?.courtId)) {
      const { courtId, courtNumber, venueId, courtExtId } = assigmentState?.model
      const newCourtData: CourtData = {
        ...courtData,
        courtId,
        courtNumber,
        venueId,
        courtExtId
      }
      setCourtData(newCourtData)
      props.handleCourtStateChange(newCourtData)
    }
  }, [assigmentState])

  const createOrUpdateAssigment = () => {
    if (courtData?.courtId !== undefined && leagueMatchIndividualMatchId !== undefined) {
      dispatch(courtsActions.assignLeagueMatchIndividualMatchToCourt(courtData?.courtId,
        leagueMatchIndividualMatchId, 0))
      setIsDispatched(true)
    }
  }
  const removeAssigment = () => {
    dispatch(courtsActions.removeAssigment(props.currentLeagueMatchIndividualMatchId))
    props.onRemoveAssigment()
  }

  const buttonText = isAssigmentModelEmpty() ? 'Gem banen' : 'Start næste kamp'
  const mode = useSelector<RootState>(x => x.mode.model ?? getMode()) as 'light' | 'dark'
  const style = { color: mode == 'light' ? 'initial' : 'white' }
  return (
    <React.Fragment>
      <Grid container>
        {(!isAssigmentModelEmpty() && !props.isReadonly) &&
          <React.Fragment>
            <Grid item xs={2} style={{ marginTop: '1%', marginBottom: '1%' }}>
              <label style={style}>Kampen er sat til {`${assigmentState.model.venueName} Bane: ${assigmentState.model.courtNumber}`}</label></Grid>
            <Grid item xs={2}>
              <IconButton onClick={removeAssigment}><DeleteIcon /></IconButton>
            </Grid>
          </React.Fragment>
        }
        {isAssigmentModelEmpty() &&
          <label style={style}>Kampen mangler at f&#229; valgt bane</label>
        }

      </Grid>
      {
        (!isAssigmentModelEmpty() && !props.isReadonly) &&
        <CourtMatchesFormIndividualMatch
          currentLeagueMatchIndividualMatchId={props.currentLeagueMatchIndividualMatchId}
          handleLeagueMatchIndividualChange={handleLeagueMatchIndividualChange}
          leagueMatchId={props.match.match.leagueMatchId}
          leagueGroupTeamId1={props.match?.match?.leagueGroupTeamId1}
          leagueGroupTeamId2={props.match?.match?.leagueGroupTeamId2} />
      }
      {(homeClubId && leagueMatchIndividualMatchId && !props.isReadonly) && <CourtForm
        handleCourtChange={handleCourtChange}
        clubId={homeClubId} />
      }
      {
        ((courtData?.courtId !== undefined && leagueMatchIndividualMatchId !== undefined)
          && !props.isReadonly) &&
        <Button
          variant="contained"
          color="secondary"
          endIcon={<SaveOutlined />}
          onClick={createOrUpdateAssigment}>
          {buttonText}
        </Button>
      }

    </React.Fragment>
  )
}