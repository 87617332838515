import * as React from 'react';
import { FC } from 'react';
import { loginStyles, SubComponentTypes } from './model';
import { ArrowBackIos } from "@mui/icons-material";
import DialogTitle from "../../components/muiComponents/core/DialogTitle";
import Grid from "../../components/muiComponents/core/Grid";
import IconButton from "../../components/muiComponents/core/IconButton";
import Button from "../../components/muiComponents/core/Button";


type Props = {
  previousPage: () => void,
  changePage: (value: number, navigationPage: string, isUmpire: boolean) => void,
  isLivescore: boolean,
  isUmpire: boolean,
};

export const LeagueMatchOrTournament: FC<Props> = props => {
  const urlPath = props.isUmpire ? "umpire" : "count";

  const styles = loginStyles();
  const getNavigationPage = (name: string) => {
    return name
  }
  const contextLivescoreTitle = 'Jeg vil se livescore'
  const contextCountTitle = 'Tælle'
  return (
    <React.Fragment>
      <DialogTitle className={styles.dialogTitle}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <IconButton aria-label='previous'
              onClick={() => props.previousPage()}>
              <ArrowBackIos />
            </IconButton>
          </Grid>
          <Grid item xs={10} style={{ alignSelf: 'center' }}>
            <div className={styles.dialogTitle}>{props.isLivescore ? contextLivescoreTitle : contextCountTitle}</div>
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button className={styles.button}
            onClick={
              () => props.changePage(
                SubComponentTypes.ContextSet,
                getNavigationPage(`${urlPath}/leagueMatch`),
                props.isUmpire
              )
            } variant="contained" color="secondary"
          >Holdkamp</Button>
        </Grid>
        <Grid item xs={6}>
          <Button className={styles.button}
            onClick={
              () => props.changePage(
                SubComponentTypes.ContextSet,
                getNavigationPage(`${urlPath}/tournament`),
                props.isUmpire
              )
            } variant="contained" color="secondary"
          >Turnering</Button>
        </Grid>

      </Grid>
      {props.isLivescore &&
        <DialogTitle className={styles.dialogTitle}>
          <Grid container spacing={3}>
            <Grid item xs={10} style={{ alignSelf: 'center' }}>
              <div className={styles.dialogTitle}>Du kan her se livescore fra danske
                Holdkampe og turneringer, hvis klubben t&#230;ller live.</div>
            </Grid>
          </Grid>
        </DialogTitle>
      }

    </React.Fragment>
  )
}