import * as React from 'react';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ContextPages } from '../contextPagesEnum';
import { LeagueMatchOrTournament } from './leagueMatchOrTournament';
import { CountOrLivescore } from './countOrLivescore';
import { CurrentPage, SubComponentTypes } from './model';
import * as lodash from 'lodash';
import { redirectActions } from '../../commons/redirectReducer';
import useTheme from "../../components/muiComponents/core/useTheme";
import useMediaQuery from "../../components/muiComponents/core/useMediaQuery";
import Dialog from "../../components/muiComponents/core/Dialog";


type Props = {

};

export const Layout: FC<Props> = props => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currentPage, setCurrentPage] = useState<CurrentPage>({currentPage: SubComponentTypes.CountOrLivescore, isLiveScore: false, isUmpire: false});
    const [open, setOpen] = useState<boolean>(true);
    const [isReadonly, setIsReadonly] = useState<boolean>(true)
   
    const changePage = (val: number, isLiveScore:boolean, isUmpire: boolean, navigationPage?: string) => {
        if(navigationPage){
            localStorage.setItem('navigationPage', navigationPage);
        }
        
        setCurrentPage({currentPage: val, isLiveScore, isUmpire});
    }

    const dispatchRedirect = (pageContext?: ContextPages) => {
        const pathname = window.location.pathname;
        const emptyPath = '/'
        let redirectPath = ''
        if(pageContext){
            redirectPath = `${pageContext}/${localStorage.getItem('navigationPage')}`
        }else{
            redirectPath = `${localStorage.getItem('navigationPage')}`
        }
        if(lodash.isEqual(pathname, emptyPath)){
            dispatch(redirectActions.postRedirectPath(`/${redirectPath}`))
        }
    }
    const getContextPage = (currentPage: CurrentPage, isReadonly:boolean) =>{
        return currentPage.isLiveScore ? ContextPages.Livescore : (isReadonly ? ContextPages.Readonly : ContextPages.Admin)
    }

    const renderSwitch = (currentPage: CurrentPage): JSX.Element => {
        switch(currentPage.currentPage){
            case SubComponentTypes.CountOrLivescore:
                return <CountOrLivescore
                          nextPage={(value,isLiveScore,isLoginRequired, isUmpire) => {                  
                            setIsReadonly(isLoginRequired)
                            changePage(value,isLiveScore, isUmpire)
                          }} 
                        />
            case SubComponentTypes.LeagueMatchOrTournament:
                return <LeagueMatchOrTournament 
                          isUmpire={currentPage.isUmpire}
                          isLivescore={currentPage.isLiveScore} 
                          previousPage={
                            () => setCurrentPage(
                              {
                                currentPage: SubComponentTypes.CountOrLivescore,
                                isLiveScore: false,
                                isUmpire: currentPage.isUmpire
                              }
                            )}
                          changePage={(val, naviagationPage, isUmpire) => 
                            changePage(val, currentPage.isLiveScore, isUmpire, naviagationPage)} 
                          
                        />
            case SubComponentTypes.ContextSet:                
                dispatchRedirect(getContextPage(currentPage, isReadonly))
                break;
            default:
                return <React.Fragment></React.Fragment>;
        }   
    }    
    return(
        <React.Fragment>
            {
                    <Dialog
                    disableEscapeKeyDown
                    className='dialogCustom'
                    fullScreen={fullScreen}
                    onClose={() => setOpen(false)} open={open}>
                        <div style={{padding: '5%'}}>
                            {renderSwitch(currentPage)}
                        </div>
                    </Dialog>
            }
        </React.Fragment>
    )
}


