import axios from "axios";
import { ClubResponse } from "../commons/clubs/model";
import { RegionResponse } from "../commons/regions/model";
import { BaseService } from "./base.service";
import * as signalR from "@microsoft/signalr";

export class SignalRService extends BaseService {
    constructor(){
        super("");
    }

    public getConnection = (resource: string): signalR.HubConnection => { 
           
        return new signalR.HubConnectionBuilder()
        .withUrl(`${this.baseUrl}ws/livescore/${resource}`)
        .configureLogging(signalR.LogLevel.Debug)
        .build()
    }
}