import axios from "axios";
import { ClassGroupApiVIewModel } from "../commons/classGroup/model";
import { BaseService } from "./base.service";

export class ClassGroupService extends BaseService{
    constructor(){
        super('ClassGroup')
    }

    public getAll = (): Promise<ClassGroupApiVIewModel[]> => {
        const url = `${this.baseUrl}/Get`;
        return axios.get<ClassGroupApiVIewModel[]>(url).then(res => res.data);
    }
}