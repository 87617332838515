import axios from "axios";
import { extend } from "lodash";
import { AgeGroupApiViewModel } from "../commons/ageGroup/model";
import { BaseService } from "./base.service";

export class AgeGroupService extends BaseService{
    constructor(){
        super('AgeGroup')
    }

    public getAll = (): Promise<AgeGroupApiViewModel[]> => {
        const url = `${this.baseUrl}/Get`;
        return axios.get<AgeGroupApiViewModel[]>(url).then(res => res.data);
    }
}