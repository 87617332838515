export interface ErrorConstructor {
    new(message?: string): Error;
    (message?: string): Error;
    readonly prototype: Error;
}

declare var Error: ErrorConstructor;

export class ApiException extends Error {
    message: string;
    status?: number;
    response?: string;
    headers?: { [key: string]: any; };
    result?: any;
}

export interface IErrorResponse {
    status?: number;
    description?: string | undefined;
    source?: string | undefined;
    step?: string | undefined;
    response?: { [key: string]: string; } | undefined;
    metadata?: any | undefined;
}

export class ErrorResponse implements IErrorResponse {
    status?: number;
    description?: string | undefined;
    source?: string | undefined;
    step?: string | undefined;
    response?: { [key: string]: string; } | undefined;
    metadata?: any | undefined;

    constructor(error: IErrorResponse){
        this.status = error.status;
        this.description = error.description;
        this.source = error.source;
        this.step = error.step;
        this.response = error.response;
        this.metadata = error.metadata;
    }

}