import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavigationState } from "../commons/navigationActionReducer";
import { getFontColor, getMode } from "../commons/utils/ui.utils";
import { RootState } from "../store/rootReducer";
import makeStyles from "./muiComponents/core/makeStyles";
import IconButton from "./muiComponents/core/IconButton";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

type props = {
}

const useStyles = makeStyles(() => {
  return ({
    pageTitle: {
      marginTop: '1%',
      marginLeft: '5%',
      color: getFontColor()
    }
  })
});

export const NavigationComponent: FC<props> = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const titleState = useSelector<RootState>((state) => state.navigation.model) as NavigationState;
  const onNavigate = (delta: number) => {
    if (titleState?.backwardAction && delta === -1) {
      titleState.backwardAction();
      return;
    }
    if (titleState?.forwardAction && delta === +1) {
      titleState?.forwardAction()
    }

    navigate(delta)
  }
  return (
    <React.Fragment>
      {
        titleState?.title &&
        <h1 className={classes.pageTitle}>
          <IconButton aria-label='previous'
            onClick={() => onNavigate(-1)}>
            <ArrowBackIos />
          </IconButton>
          {titleState?.title}
          <IconButton aria-label='forward'
            onClick={() => onNavigate(+1)}>
            <ArrowForwardIos />
          </IconButton>
        </h1>
      }
    </React.Fragment>
  )
}