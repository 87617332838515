import axios from "axios";
import { GeoRegionApiVIewModel } from "../commons/geoRegion/model";
import { BaseService } from "./base.service";

export class GeoRegionService extends BaseService{
    constructor(){
        super('GeoRegion')
    }

    public getAll = (): Promise<GeoRegionApiVIewModel[]> => {
        const url = `${this.baseUrl}/Get`;
        return axios.get<GeoRegionApiVIewModel[]>(url).then(res => res.data);
    }
}