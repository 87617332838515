import axios from "axios";
import { BaseService } from "./base.service";
import { BlockMatchModel, BlockMatchToSave } from "../commons/blockMatch/model";

export class BlockMatchService extends BaseService {
  constructor() {
    super("BlockMatch");
  }

  public getAllBlockMatch = (): Promise<BlockMatchModel[]> => {
    const url = `${this.baseUrl}/getAll`;
    return axios.get<BlockMatchModel[]>(url).then(res => res.data);
  }

  public saveBlockMatch = (payload: BlockMatchToSave): Promise<BlockMatchToSave> => {
    const url = `${this.baseUrl}/create`;
    return axios.post<BlockMatchToSave>(url, payload).then(res => res.data);
  }

  public deleteBlockMatch = (payload: BlockMatchToSave): Promise<boolean> => {
    const url = `${this.baseUrl}/delete`;
    return axios.delete<boolean>(url, { data: payload }).then(res => res.data);
  }

}