import React, { useEffect, useState } from 'react';
import './App.css';

import { DashBoard } from './pages/dashboard/dashboard';
import { BrowserRouter } from 'react-router-dom';
import { RoutesComponent } from './routes';
import { ResponseHandler } from './components/responseHandler/ResponseHandler';
import { getMode } from './commons/utils/ui.utils';
import { useSelector } from 'react-redux';
import { RootState } from './store/rootReducer';
import { createTheme, ThemeProvider } from "@mui/material";

function App() {
  const getTheme = (mode: 'light' | 'dark') => {
    const currentMode = mode ?? getMode();
    return createTheme({
      palette: {
        mode: currentMode,
        common: {
          black: "#424242",
        },
        secondary: {
          main: "#f50057"
        },
      }
    })
  }
  const mode = useSelector<RootState>(x => x.mode.model ?? getMode()) as 'light' | 'dark'
  const [theme, setTheme] = useState(getTheme(mode))

  useEffect(() => {
    if (theme.palette.mode !== mode) {
      setTheme(getTheme(mode))
    }
  }, [mode])
  return (
    <ThemeProvider theme={theme}>
      {/* <InstallationPrompt /> */}
      <ResponseHandler />
      <BrowserRouter>
        <DashBoard routes={
          <RoutesComponent />
        } />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
