import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import lodash from "lodash";
import { LeagueMatchResponse } from "../../commons/leagueMatch/model";
import { BaseProps } from "../../routes";
import { LeagueMatchLivescoreCodeForm } from "../../components/leagueMatch/leagueMatchLivescoreCodeForm";
import { LeagueMatchService } from "../../api/leagueMatch.service";

type props = BaseProps & {};

export const LeagueMatchLineupMoveToCheckPassword: FC<props> = () => {
  const navigate = useNavigate();
  const { leagueMatchIdStr } = useParams();
  const [selectedMatch, setSelectedMatch] = useState<LeagueMatchResponse>();
  const [hasToOpenDialog, setHasToOpenDialog] = useState<boolean>(false);
  const leagueMatchClient = new LeagueMatchService();

  useEffect(() => {
    const GetLeagueMatch = async () => {
      const leagueMatchId = lodash.toNumber(leagueMatchIdStr)
      const result = await leagueMatchClient.getByParameters(null, null, leagueMatchId, null, null, null)
      const selectedMatchTemp = result?.find((x) => x.leagueMatchId === leagueMatchId);

      setSelectedMatch(selectedMatchTemp);
      setHasToOpenDialog(true);

      return result;
    }

    GetLeagueMatch();
  }, []);

  const navigateToLeagueMatch = (code: string) => {
    let url = `${code}`;
    navigate(url);
  };

  return (
    <>
      {hasToOpenDialog && (
        <LeagueMatchLivescoreCodeForm
          liveScoreCode={selectedMatch?.livescoreCode}
          onClose={() => setHasToOpenDialog(false)}
          onLiveScoreCodeSubmit={navigateToLeagueMatch}
        />
      )}
    </>
  );
};
