import { BaseActionReducer } from "../baseActionReducer";

export const  signalRHubActionReducer = new BaseActionReducer({ keyStart: 'SIGNALR_STATE', payloadName: 'signalrstate' });
const syncSignalRActions = signalRHubActionReducer.buildSyncActions();

const asyncSignalRHubActionCreators = {
    setState: (hub: signalR.HubConnection, onRefresh: () => void) => {
        const state: SignalRState = {
            hub,
            onRefresh
        }
        return signalRHubActionReducer.createAsyncAction(Promise.resolve(state), (response: any) => response);
    }
};

export const signalRStateActions = {
    ...syncSignalRActions,
    ...asyncSignalRHubActionCreators
};

export interface SignalRState{
    hub: signalR.HubConnection;
    onRefresh: () => void
}