import { BaseActionReducer } from './baseActionReducer';

export const  navigationActionReducer = new BaseActionReducer({ keyStart: 'NAVIGATION', payloadName: 'navigation' });
const syncNavigationActions = navigationActionReducer.buildSyncActions();

const asyncNavigationActionCreators = {
    setNavigation: (title: string, backwardAction?: (event?: any) => void, forwardAction?: (event?: any) => void) => {
        const titleObj: NavigationState = {
           title,
           backwardAction,
           forwardAction
        }
        return navigationActionReducer.createAsyncAction(Promise.resolve(titleObj));
    }
};

export const titleActions = {
    ...syncNavigationActions,
    ...asyncNavigationActionCreators
};

export interface NavigationState{
    title: string,
    backwardAction?: (event?: any) => void,
    forwardAction?: (event?: any) => void
}