import * as React from 'react';
import { ApiException, ErrorResponse } from '../../commons/models/ErrorModel';
import makeStyles from "../muiComponents/core/makeStyles";
import Snackbar from "../muiComponents/core/Snackbar";
import Alert from "../muiComponents/lab/Alert";
import AlertTitle from "../muiComponents/lab/AlertTitle";
import SlideTransition from "../muiComponents/custom/SlideTransition";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        '& li': {
            marginLeft: -10
        }
    },
}));

export type SystemError = ApiException;

export type ApplicationError = {
    [key: string]: any[]
};

export type ErrorHandlerProps = {
    appError?: ErrorResponse | { status: number },
    systemErrors?: SystemError[],
    errorTitle404?: string,
    errorBody404?: string,
    errorTitle?: string,
    errorBody?: string,
    isCustomError?: boolean
    handleClose?: (event?: React.SyntheticEvent, reason?: string) => any,
};

export const ErrorHandler: React.FC<ErrorHandlerProps> = props => {

    const errorMessages = (responseCode: number) => {
        if(props.isCustomError){
            return {
                title: "Du har ikke tilladelse",
                body: <span>{props.systemErrors.map(x => x.message).join(",")}</span>

            }
        }
        switch (responseCode) {
            case 403: return {
                title: "Du har ikke tilladelse",
                body: <span>Du har ikke tilladelse til denne del af hjemmesiden.<br />Kontakt Badminton Danmark hvis du mener at dette er en fejl.</span>
            }
            case 404: return {
                title: props.errorTitle404 || "Kunne ikke findes",
                body: props.errorBody404 || <span>Du har ikke tilladelse til denne del af hjemmesiden.<br />Kontakt Badminton Danmark hvis du mener at dette er en fejl.</span>
            }
            case 500: return {
                title: "Der opstod en systemfejl",
                body: <span>Der opstod en serverfejl i behandlingen af din anmodning. Prøv igen på et senere tidspunkt eller,<br />kontakt Badminton Danmark hvis problemet bliver ved med at opstå.</span>
            }
            case 401: return {
                title: "Manglende brugergodkendelse",
                body: <span>Brugeren er ikke godkendt. Log ind igen eller kontakt Badminton Danmark ved fejl</span>
            }
            default: return {
                title: "Der opstod en fejl",
                body: <span>Der opstod en ukendt fejl.<br />Kontakt Badminton Danmark hvis fejlen fortsætter.</span>
            }
        }
    }

    const classes = useStyles();
    const [open, setOpen] = React.useState(props.appError !== null || props.systemErrors.length > 0);
    // Tag højde for både objekt og array typer for error
    const isSystemError = props.systemErrors && props.systemErrors.length > 0,
        autoHideDuration = isSystemError ? null : 6000,
        errorType = isSystemError ? (props.systemErrors[0].status ? props.systemErrors[0].status : 0) : 0,
        defaultErrorTitle = props.errorTitle || "Der opstod en fejl",
        title = isSystemError ? errorMessages(errorType).title : defaultErrorTitle;
    let body: JSX.Element[] = [];

    if (isSystemError && props.systemErrors.length > 0) {
        body = props.systemErrors.map((error, idx) => <li key={"sysError" + idx}>{errorMessages(errorType).body}</li>);
    }
    else if (!isSystemError && props.appError) {
        /*if (props.appError.status === 500) {
            body.push(props.appError['message']);
        } else {
            body = Object.keys(props.appError).map((errorKey, keyIdx) =>
                Array.isArray(props.appError[errorKey]) && props.appError[errorKey].length > 0
                    ? props.appError[errorKey].map((error: any, idx: number) => <li key={"error" + keyIdx + "" + idx}>{error}</li>)
                    : <li key={"error" + keyIdx}>{props.appError[errorKey][0]}</li>)
        }*/
        body.push((props.appError as any)['description']);
    }
    else if (props.errorBody) {
        body.push(<li>{props.errorBody}</li>);
    } else {
        body.push(<li>Ukendt fejl</li>);
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        props?.handleClose(event, reason)
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose} TransitionComponent={SlideTransition} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <div>
                    <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
                        <AlertTitle>{title}</AlertTitle>
                        <ul style={{ color: '#fff' }}>
                            {body}
                        </ul>
                    </Alert>
                </div>
            </Snackbar>
        </div>
    );
}