import axios from "axios";
import { extend } from "lodash";
import { AgeGroupApiViewModel } from "../commons/ageGroup/model";
import { AgeGroupClass } from "../commons/tournament/model";
import { BaseService } from "./base.service";

export class AgeGroupClassService extends BaseService{
    constructor(){
        super('AgeGroupClass')
    }

    public getAll = (): Promise<AgeGroupClass[]> => {
        const url = `${this.baseUrl}/Get`;
        return axios.get<AgeGroupClass[]>(url).then(res => res.data);
    }
}