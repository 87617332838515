import axios, { AxiosRequestConfig } from "axios";
import { getUctDateAsLocal } from "../commons/helpers";
import { LeagueMatchAggregateViewModel, LeagueMatchResponse } from "../commons/leagueMatch/model";
import { BaseService } from "./base.service";

export class LeagueMatchService extends BaseService {
    
    constructor(){
        super("LeagueMatch");
    }

    public getHomeClubIdByLeagueMatch = (leagueMatchId: number): Promise<number> => {
        const url = `${this.baseUrl}/clubid/${leagueMatchId}`;
        return axios.get<number>(url).then(res => res.data);
    }

    public getLeagueMatch = (leagueMatchId: number, password?: string, regionId?: number, isLivescore?: boolean)
    : Promise<LeagueMatchAggregateViewModel> => {  
        
        const config: AxiosRequestConfig = {
            params: {'password': password ? btoa(password) : '', 'regionId': regionId, 'isLiveScore': isLivescore }
        }
        const url = `${this.baseUrl}/${leagueMatchId}`;
        return axios.get<LeagueMatchAggregateViewModel>(url, config).then(res => res.data);
    }

    public updateLeagueMatch = (leagueMatchId: number, leagueMatch: LeagueMatchAggregateViewModel, password?: string,
        regionId?: number): Promise<LeagueMatchAggregateViewModel> => {
        const regionid = regionId ?? 2;
        if(leagueMatch.match.matchTime){
            leagueMatch.match.matchTime = getUctDateAsLocal(new Date(leagueMatch.match.matchTime));
        }
        
        const config: AxiosRequestConfig = {
            params: {'regionId': regionid }
        }
        const url = `${this.baseUrl}/${leagueMatchId}/${password}`;
        leagueMatch.IsDraft = true
        return axios.put<LeagueMatchAggregateViewModel>(url, leagueMatch, config).then(res => res.data);
    }

    public getByParameters = (clubId?: number, regionId?: number, leagueMatchId?: number, 
        startDate?: Date, endDate?: Date, ageGroupId?: number): Promise<LeagueMatchResponse[]> =>{
        const config: AxiosRequestConfig = {
            params: {
                'clubId': clubId, 'regionId': regionId, 'leagueMatchId':  leagueMatchId,
                'startDate': startDate, 'endDate': endDate, 'ageGroupId': ageGroupId
            }   
        }
        return axios.get<LeagueMatchResponse[]>(this.baseUrl, config).then(res => res.data);
    }
}