import { getFontColor } from "../../commons/utils/ui.utils";
import makeStyles from "../../components/muiComponents/core/makeStyles";

export enum SubComponentTypes {
  CountOrLivescore = 0,
  LeagueMatchOrTournament,
  ContextSet
}

export interface CurrentPage {
  currentPage: number,
  isLiveScore: boolean,
  isUmpire: boolean,
}

export const loginStyles = () => {
  return makeStyles(() => ({
    button: {
      height: '5em',
      width: '100%'
    },
    dialogTitle: {
      alignSelf: 'center',
      color: getFontColor()
    },
    'dialogCustom': {
    }
  }))();
}