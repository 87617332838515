import * as React from 'react';
import makeStyles from "../muiComponents/core/makeStyles";
import Snackbar from "../muiComponents/core/Snackbar";
import Alert from "../muiComponents/lab/Alert";
import AlertTitle from "../muiComponents/lab/AlertTitle";
import SlideTransition from "../muiComponents/custom/SlideTransition";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        '& li': {
            marginLeft: -10
        }
    },
}));


type Props = {
    isTriggered: boolean;
    warningMsg: string;
    handleClose: (event?: React.SyntheticEvent, reason?: string) => any,
    open: boolean
}


export const SuccessHandler: React.FC<Props> = props => {
    const defaultSuccessMessage ="Anmodningen lykkedes";
    const classes = useStyles();
    const autoHideDuration = 6000;
    return (
        <div className={classes.root}>
            <Snackbar open={props.open} autoHideDuration={autoHideDuration} onClose={props.handleClose} TransitionComponent={SlideTransition} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <div>
                    <Alert elevation={6} variant="filled" onClose={props.handleClose} severity= {props.warningMsg ? 'warning' : 'success'}>
                        <AlertTitle>{props.warningMsg ? props.warningMsg : defaultSuccessMessage}</AlertTitle>
                    </Alert>
                </div>
            </Snackbar>
        </div>
    );
}