import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import lodash from "lodash";
import { BaseProps } from "../../routes";
import { LeagueMatchLivescoreCodeForm } from "../../components/leagueMatch/leagueMatchLivescoreCodeForm";
import { TournamentService } from "../../api/tournament.service";
import { TournamentPatchRequest } from "../../commons/tournament/model";

type props = BaseProps & {};

export const TournamentMoveToCheckPassword: FC<props> = () => {
  const navigate = useNavigate();
  const { tournamentClassIdStr, clubId } = useParams();
  const [liveScoreCodeStr, setLiveScoreCodeStr] = useState<string>('');
  const [hasToOpenDialog, setHasToOpenDialog] = useState<boolean>(false);
  const tournamentService = new TournamentService();

  useEffect(() => {
    const GetLeagueMatch = async () => {
      const tournamentClassId = lodash.toNumber(tournamentClassIdStr)
      const clubIdNumber = lodash.toNumber(clubId);

      let searchCriteria = new TournamentPatchRequest()
      searchCriteria.clubIds = [clubIdNumber];
      searchCriteria.dateFrom = null;

      const result = await tournamentService.get(searchCriteria);
      const tournamentAdmin = lodash.find(result.tournamentAdmins, (item) => item.tournamentClassID === tournamentClassId);

      setLiveScoreCodeStr(tournamentAdmin?.liveScoreCode);
      setHasToOpenDialog(true);

      return result;
    }
    
    GetLeagueMatch();
  }, []);

  const navigateToLeagueMatch = (code: string) => {
    let url = `${code}`;
    navigate(url);
  };

  return (
    <>
      {hasToOpenDialog && (
        <LeagueMatchLivescoreCodeForm
          liveScoreCode={liveScoreCodeStr}
          onClose={() => setHasToOpenDialog(false)}
          onLiveScoreCodeSubmit={navigateToLeagueMatch}
        />
      )}
    </>
  );
};
