import axios from "axios";
import { BulkCourtResponse, CourtLeagueMatchIndividualMatchResponse, CourtMatchesResponse, CourtResponse, CourtTournamentEventMatchResponse, VenueCourtsResponse } from "../commons/courts/model";
import { BaseService } from "./base.service";

export class CourtsService extends BaseService {
  constructor() {
    super("Court");
  }

  public getCourtsByIndividualMatchId = (leagueMatchIndividualMatchId: number): Promise<BulkCourtResponse> => {
    const url = `${this.baseUrl}?leagueMatchIndividualMatchId=${leagueMatchIndividualMatchId}`;
    return axios.get<BulkCourtResponse>(url).then(res => res.data);
  }
  public getCourtsByClubId = (clubId: number): Promise<BulkCourtResponse> => {
    const url = `${this.baseUrl}/club?clubId=${clubId}`;
    return axios.get<BulkCourtResponse>(url).then(res => res.data);
  }
  public getCurrentTournamentCourt = (tournamentId: number, tournamentEventId: number, matchId: number) => {
    const url = `${this.baseUrl}/current/tournament?tournamentId=${tournamentId}&tournamentEventId=${tournamentEventId}&matchId=${matchId}`;
    return axios.get<CourtTournamentEventMatchResponse>(url).then(res => res.data);
  }

  public getCourtTournamentMatches = (clubId: number,
    tournamentId: number, matchId: number, tournamentEventId: number) => {
    const url = `${this.baseUrl}/court/tournament?clubId=${clubId}&tournamentId=${tournamentId}&matchId=${matchId}&tournamentEventId=${tournamentEventId}`;
    return axios.get<CourtTournamentEventMatchResponse[]>(url).then(res => res.data);
  }

  public getMatchesForCourtByLeagueMatchId = (leagueMatchId: number) => {
    const url = `${this.baseUrl}/matches?leagueMatchId=${leagueMatchId}`
    return axios.get<CourtMatchesResponse[]>(url).then(res => res.data);
  }

  public getCurrentCourt = (leagueMatchIndividualMatchId: number): Promise<CourtLeagueMatchIndividualMatchResponse> => {
    const url = `${this.baseUrl}/current?leagueMatchIndividualMatchId=${leagueMatchIndividualMatchId}`;
    return axios.get<CourtLeagueMatchIndividualMatchResponse>(url).then(res => res.data);
  }

  public getByVenueIdAndCourtNumber = (venueId: number, courtNumber: number): Promise<CourtResponse> => {
    const url = `${this.baseUrl}/venue?venueId=${venueId}&courtNumber=${courtNumber}`;
    return axios.get<CourtResponse>(url).then(res => res.data);
  }

  public assignLeagueMatchIndividualMatchToCourt = (courtId: number, leagueMatchIndividualMatchId: number,
    index: number) => {
    const url = `${this.baseUrl}?courtId=${courtId}&leagueMatchIndividualMatchId=${leagueMatchIndividualMatchId}&index=${index}`;
    return axios.put<CourtLeagueMatchIndividualMatchResponse>(url).then(res => res.data);
  }

  public assignTournamentMatchToCourt = (courtId: number, tournamentId: number, tournamentEventId: number, matchId: number, index: number) => {
    const url = `${this.baseUrl}/tournament?courtId=${courtId}&tournamentId=${tournamentId}&tournamentEventId=${tournamentEventId}&matchId=${matchId}&index=${index}`;
    return axios.put<CourtLeagueMatchIndividualMatchResponse>(url).then(res => res.data);
  }

  public removeAssigment = (leagueMatchIndividualMatchId: number): Promise<boolean> => {
    const url = `${this.baseUrl}?leagueMatchIndividualMatchId=${leagueMatchIndividualMatchId}`;
    return axios.delete<boolean>(url).then(res => res.data);
  }
  public removeTournamentAssigment = (tournamentEventId: number, matchId: number): Promise<boolean> => {
    const url = `${this.baseUrl}/tournament/?tournamentEventId=${tournamentEventId}&matchId=${matchId}`;
    return axios.delete<boolean>(url).then(res => res.data);
  }
}