import { ClubsService } from "../../api/clubs.service";
import { BaseActionReducer } from "../baseActionReducer";

export const clubsActionReducer = new BaseActionReducer({ keyStart: 'CLUBS', payloadName: 'clubs' });
const syncActionCreators = clubsActionReducer.buildSyncActions();

const ClubsClient = new ClubsService();

const asyncActionCreators = {
    getAll: () => {
        return clubsActionReducer.createAsyncAction(
            ClubsClient.getAll());
    },
};

export const clubActions = {
    ...syncActionCreators,
    ...asyncActionCreators
};