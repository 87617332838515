import React, { FC, useEffect, useState } from "react";
import CachedIcon from '@mui/icons-material/Cached';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { SignalRState } from "../../commons/singalR/signalRStateReducer";
import * as lodash from 'lodash'
import { responseHandlerActions } from "../../commons/responseHandler/responseHandlerActionReducer";
import Dialog from "../muiComponents/core/Dialog";
import Grid from "../muiComponents/core/Grid";
import CircularProgress from "../muiComponents/core/CircularProgress";
import IconButton from "../muiComponents/core/IconButton";
import makeStyles from "../muiComponents/core/makeStyles";
type props = {

}

const useStyles = makeStyles(() => ({
    dialog:{
        '& MuiPaper-root MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm MuiPaper-elevation24 MuiPaper-rounded':{
            background: 'transparent'
        }
    }
}));

export const SignalRStateComponent: FC<props> = (props) => {
    const connectionState = useSelector<RootState>(state => {
      return state.signalRState.model
    }) as SignalRState  
    const classes = useStyles()
    const [isConnectionAvailable, setIsConnectionAvailable] = useState<boolean | undefined>(undefined)
    const [isLivescoreConnected, setIsLivescoreConnected] = useState<boolean | undefined>(undefined)
    const [isConnectionLoading, setIsConnectionLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLivescoreConnected(connectionState?.hub?.state.toLowerCase() === 'Connected')
    },[connectionState])

    useEffect(() => {
        if(connectionState?.hub && !lodash.isEmpty(connectionState.onRefresh?.toString())){
            setIsConnectionAvailable(true)
        }else if(isLivescoreConnected){
            setIsConnectionAvailable(false)
        }

        if(lodash.isEmpty(connectionState?.hub?.connectionId)){
            setIsConnectionLoading(true)          
        }else{
            setIsConnectionLoading(false)
        }
    }, [connectionState, connectionState?.hub?.connectionId])
    useEffect(() => {
        if(isLivescoreConnected === false && isConnectionAvailable === false){
            dispatch(responseHandlerActions.call({
                error: {
                    isCustomError: true,
                    systemErrors: [{message: "Can't connect to the stream", name: "LiveScoreConnectionException"}]
                }
            }))
        }
    }, [isLivescoreConnected, isConnectionAvailable, connectionState])

    const onRefreshClick = () => {
        if(isConnectionAvailable){
            connectionState.onRefresh()
        }
    }
    if(!isConnectionAvailable){
        return(null)
    } 
    return (
        <React.Fragment>
            {
                isConnectionLoading &&
                <Dialog
                className={`dialogTransparent dialogCustom`}                    
                disableEscapeKeyDown = {false}
                hideBackdrop
                fullScreen={false}
                open={isConnectionLoading}>
                    <Grid container>
                        <Grid item xs={12} style={{textAlign:'center'}}>
                            <CircularProgress style={{color: '#f50057'}} />
                        </Grid>
                        <Grid item xs={12} style={{textAlign:'center'}}>
                            <label style={{color:'white', fontSize: 'large'}}>Connection establishing</label>
                        </Grid>                            
                    </Grid>                                                
                </Dialog>              
            }            
            { isLivescoreConnected && !isConnectionLoading &&
                <IconButton  style={{ cursor: 'pointer' }}>
                    <label style={{fontSize: '0.875rem'}}>Livescore connected</label>
                </IconButton> }
            { !isLivescoreConnected && !isConnectionLoading &&
                <IconButton style={{ cursor: 'pointer' }} onClick={onRefreshClick}>
                    <label style={{fontSize: '0.875rem'}}>Refresh livescore</label> <CachedIcon />
                </IconButton> }
        </React.Fragment>
    )
}