import { TournamentService } from '../api/tournament.service';
import { BaseActionReducer } from './baseActionReducer';
import { storageModeItemName } from './utils/ui.utils';

export const tournamentLiveActionReducer = new BaseActionReducer({ keyStart: 'TOURNAMENTLIVE', payloadName: 'model' });
const syncTournamentLiveActions = tournamentLiveActionReducer.buildSyncActions();
const tournamentClient = new TournamentService();
const asyncTournamentActionCreators = {
    get: (tournamentClassId: number, tournamentEventIds: number[]) => {
        return tournamentLiveActionReducer.createAsyncAction(
            tournamentClient.getTournamentEventsByMultiTe(tournamentClassId, tournamentEventIds)
        );
    }
};

export const tournamentLiveActions = {
    ...syncTournamentLiveActions,
    ...asyncTournamentActionCreators
};

