import { AgeGroupService } from "../../api/ageGroup.service";
import { ClubsService } from "../../api/clubs.service";
import { BaseActionReducer } from "../baseActionReducer";

export const ageGroupActionReducer = new BaseActionReducer({ keyStart: 'AGEGROUP', payloadName: 'agegroup' });
const syncActionCreators = ageGroupActionReducer.buildSyncActions();

const ageGroupClient = new AgeGroupService();

const asyncActionCreators = {
    getAll: () => {
        return ageGroupActionReducer.createAsyncAction(
            ageGroupClient.getAll());
    },
};

export const ageGroupActions = {
    ...syncActionCreators,
    ...asyncActionCreators
};