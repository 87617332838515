import React, { FC, useState } from "react";
import { FormControl } from "../../commons/forms";
import { FormValidationError } from "../../commons/formValidationError";
import { default as lodash } from 'lodash';
import { SaveOutlined } from '@mui/icons-material';
import Dialog from "../muiComponents/core/Dialog";
import Grid from "../muiComponents/core/Grid";
import TextField from "../muiComponents/core/TextField";
import Button from "../muiComponents/core/Button";
import useTheme from "../muiComponents/core/useTheme";
import useMediaQuery from "../muiComponents/core/useMediaQuery";

type props = {
  liveScoreCode: string,
  onLiveScoreCodeSubmit: (livescoreCode: string) => void,
  onClose: () => void
}


interface LiveScoreCodeForm {
  code: FormControl
}

export const LeagueMatchLivescoreCodeForm: FC<props> = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState<boolean>(true);
  const [liveScoreCodeForm, setLiveScoreCodeForm] = React.useState<LiveScoreCodeForm>({ code: {} });
  const validForm = (): boolean => {
    return liveScoreCodeForm.code.touched
      && !lodash.isEmpty(liveScoreCodeForm.code.value)
      && lodash.isEqual(props.liveScoreCode, liveScoreCodeForm.code?.value)
  }

  const onFieldChange = (name: string, value: any) => {
    const formControl: FormControl = {
      value,
      touched: true
    }
    setLiveScoreCodeForm({
      ...liveScoreCodeForm,
      [name]: formControl
    });
  }

  const handleSubmit = () => {
    props.onLiveScoreCodeSubmit(liveScoreCodeForm.code.value)
  }

  return (
    <React.Fragment>
      {!lodash.isEmpty(props.liveScoreCode) &&
        <Dialog
          disableEscapeKeyDown={false}
          hideBackdrop={false}
          className='dialogCustom'
          fullScreen={fullScreen}
          onClose={() => { setOpen(false); props.onClose() }} open={open}>
          <div style={{ padding: '5%' }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  color='secondary'
                  name="code"
                  variant="outlined"
                  type="password"
                  InputLabelProps={{ shrink: true }}
                  label={"Indtast Livescore"}
                  autoComplete="off"
                  required={true}
                  onChange={(props) => onFieldChange(
                    props.currentTarget.name, props.currentTarget.value)}
                  value={liveScoreCodeForm.code.value}
                />
              </Grid>
              <Grid item xs={12} style={{ visibility: validForm() ? 'hidden' : 'initial' }}>
                <FormValidationError message={'Livescore kode er påkrævet, koden kan fås ved dommerbordet'} />
              </Grid>
              <Grid item>
                <Button disabled={!validForm()}
                  variant="contained" size="small" color="secondary"
                  startIcon={<SaveOutlined />}
                  onClick={() => handleSubmit()}>
                  Login
                </Button>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      }
      {lodash.isEmpty(props.liveScoreCode) &&
        <Dialog
          disableEscapeKeyDown={false}
          hideBackdrop={false}
          className='dialogCustom'
          fullScreen={fullScreen}
          onClose={() => { setOpen(false); props.onClose() }} open={open}>
          <div style={{ padding: '5%' }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <label style={{ fontSize: 'medium' }}>Livescore er deaktiveret. Turnering uden angivet livescore-kode.</label>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      }
    </React.Fragment>
  )
}