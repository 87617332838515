import { ClassGroupService } from "../../api/classGroup.service";
import { BaseActionReducer } from "../baseActionReducer";

export const classGroupActionReducer = new BaseActionReducer({ keyStart: 'CLASSGROUP', payloadName: 'classgroup' });
const syncActionCreators = classGroupActionReducer.buildSyncActions();

const classGroupClient = new ClassGroupService();

const asyncActionCreators = {
    getAll: () => {
        return classGroupActionReducer.createAsyncAction(
            classGroupClient.getAll());
    },
};

export const classGroupActions = {
    ...syncActionCreators,
    ...asyncActionCreators
};