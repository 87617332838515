import * as React from 'react';
import { FC } from 'react';
import { RootState } from '../../store/rootReducer';
import { Tournament } from '../../commons/tournament/model';
import { BaseStateManager } from '../../components/stateManager/state.manager';
import { tournamentActions } from '../../commons/tournament/tournamentActionReducer';

type Props = {
    onStateChange?: (model: Tournament) => void,
    tournamentClassId: number
}

export const TournamentStateManager: FC<Props> = props => {
    return(<BaseStateManager
        onStateChange={(model: any) => {
            if(props?.onStateChange){
                props.onStateChange(model)
            }
        }} 
        selectorFunc={(state: any) => (state as RootState).tournaments.tournament}
        getterFunc={() => tournamentActions.getByTournamentClassId(props.tournamentClassId)}
    />)
}