import React, { FC } from "react"
import { Disciplines } from "../../commons/leagueMatch/disciplines"
import { LeagueIndividualMatchViewModel } from "../../commons/leagueMatch/model"
import { SportResultsTable, SportResultsTableRow } from "../../commons/ui/table/sportresult.table"
import { default as lodash } from 'lodash'
import { getMode } from "../../commons/utils/ui.utils"
import '../../styles/functionalities/current-serve-player.css';
import TableCell from "../muiComponents/core/TableCell";
import Grid from "../muiComponents/core/Grid";
import InputLabel from "../muiComponents/core/InputLabel";

type props = {
  team1Name: string,
  team2Name: string,
  leagueGroupTeamId1: number,
  leagueGroupTeamId2: number,
  maxSets: number,
  combinedTeamMatches: LeagueIndividualMatchViewModel[],
  homePoints: number,
  awayPoints: number,
  onLeagueMatchSetChange: (leagueMatchIndividualId: number) => void
}


export const LeagueMatchLineupTable: FC<props> = (props) => {
  const currentServePlayerClass = 'current-serve-player';
  const matchSetCount = props.maxSets;
  const headerLabels = getHeaderLabels(props.team1Name, props.team2Name, matchSetCount);
  const headers = headerLabels.map((item, index) => {
    let result: JSX.Element;
    if (index === 0) {
      result = <TableCell>{item}</TableCell>
    } else {
      if (index === 2) {
        result = <TableCell align="left">{item}</TableCell>
      } else {
        result = <TableCell align="right">{item}</TableCell>
      }

    }
    return result;
  })
  const hasPlayers = props.combinedTeamMatches.some(x => !lodash.isEmpty(x.teamPlayers))

  const tableData = props.combinedTeamMatches.map((combinedTeamMatch, index) => {
    if (combinedTeamMatch.leagueMatchIndividualMatchId === 0) {
      return undefined // return undefined when leagueMatchIndividualMatch isn't saved in DB
    }

    const disciplineCode = Disciplines.getShortName(combinedTeamMatch.disciplineCode) || combinedTeamMatch.disciplineCode;
    const teamPlayers = combinedTeamMatch?.teamPlayers;
    if (!teamPlayers) {
      return;
    }
    const getServePlayerClassStyle = (playerId: number, currentServerPlayerId?: number): string => {
      return currentServerPlayerId == playerId ? currentServePlayerClass : ""
    }
    const teamHomePlayers = combinedTeamMatch?.teamPlayers[props.leagueGroupTeamId1]?.map(x =>
      <Grid item xs={12}>
        <div className={getServePlayerClassStyle(x.playerId, combinedTeamMatch?.currentPlayerServeId)}>{x.player.name}</div>
      </Grid>
    );
    const teamAwayPlayers = combinedTeamMatch?.teamPlayers[props.leagueGroupTeamId2]?.map(x =>
      <Grid className={getServePlayerClassStyle(x.playerId, combinedTeamMatch?.currentPlayerServeId)}
        style={{ textAlign: 'left' }} item xs={12}>{`${x.player.name}`}</Grid>
    );

    if (lodash.isEmpty(teamHomePlayers) || lodash.isEmpty(teamAwayPlayers)) {
      return;
    }

    const matchSetHeaders = headerLabels.slice(3, headers.length);
    const leagueMatchSets = matchSetHeaders.map((item, headerIndex) => {
      const leagueMatchSet = combinedTeamMatch.leagueMatchSet[headerIndex];
      return <TableCell id={`${combinedTeamMatch.leagueMatchIndividualMatchId}_${headerIndex}`}
        aria-label={item} align='right'>{leagueMatchSet?.points1} - {leagueMatchSet?.points2}</TableCell>
    })
    const getDisciplineIndex = () => {
      const matchesByDiscipline = props.combinedTeamMatches
        .filter(x => x.disciplineCode === combinedTeamMatch.disciplineCode)
      const index = matchesByDiscipline.indexOf(combinedTeamMatch) + 1;
      return index;
    }
    const cells: JSX.Element[] = [
      <TableCell aria-label={headerLabels[0]} scope='row'>{getDisciplineIndex()} {disciplineCode}</TableCell>,
      <TableCell aria-label={headerLabels[1]} align='right' >
        <Grid container>
          {teamHomePlayers}
        </Grid>
      </TableCell>,
      <TableCell aria-label={headerLabels[2]} align='left' >
        <Grid container>
          {teamAwayPlayers}
        </Grid>
      </TableCell>,
      ...leagueMatchSets
    ]
    const result: SportResultsTableRow = {
      id: combinedTeamMatch.leagueMatchIndividualMatchId,
      cells
    }
 
    return result;
  }).filter(x => x !== undefined);

  const onLeagueMatchSetSelect = (event: SportResultsTableRow) => {
    props.onLeagueMatchSetChange(event.id);
  }

  const { homePoints, awayPoints } = props
  const mode = getMode()
  const fontColor = mode === 'light' ? '#0f0f0f' : '#e0e0e0'
  return (
    <React.Fragment>
      <Grid container>
        <Grid><InputLabel style={{ marginBottom: '2%' }}>Sk&#230;rmvisning er optimeret til bredformat (flip din mobil).</InputLabel></Grid>
        {
          <Grid container xs={12} style={{ paddingRight: '15%' }}>
            <Grid item xs={6} style={{ textAlign: 'right', paddingRight: '1%' }}>
              <Grid item xs={12} xl={12}>
                <label style={{ fontSize: '1rem', color: fontColor }}>{props.team1Name}</label>
              </Grid>
              <Grid item xs={12} xl={12}>
                <label style={{ fontSize: '2rem', color: fontColor }}>{homePoints}</label>
              </Grid>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'left', paddingLeft: '1%' }}>
              <Grid item xs={12} xl={12}>
                <label style={{ fontSize: '1rem', color: fontColor }}>{props.team2Name}</label>
              </Grid>
              <Grid item xs={12} xl={12}>
                <label style={{ fontSize: '2rem', color: fontColor, textAlign: 'center' }}>{awayPoints}</label>
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid item xs={12}>
          {hasPlayers && <SportResultsTable data={tableData} headers={headers} footerLabel={null}
            tableLabel="Ligakampopstilling" onRowSelect={onLeagueMatchSetSelect} passFooter={false} />}
          {
            !hasPlayers && <h2>Opstilling er endnu offenligtgjort</h2>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const getHeaderLabels = (team1Name: string, team2Name: string, matchSetCount: number) => {
  let headerLabels = ['', team1Name, team2Name];
  for (let index = 0; index < matchSetCount; index++) {
    headerLabels.push(`${index + 1}`);

  }
  return headerLabels;
}

