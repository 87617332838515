import * as React from 'react';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TournamentClassResponse } from '../../commons/tournamentClass/model';
import { RootState } from '../../store/rootReducer';
import { ReducerStateProperties, RequestStatus } from '../../commons/baseReducer';
import { tournamentClassActions } from '../../commons/tournamentClass/tournamentClassActionReducer';
import * as lodash from 'lodash';
import { responseHandlerActions } from '../../commons/responseHandler/responseHandlerActionReducer';
import { ErrorResponse } from '../../commons/models/ErrorModel';

type Props = {
    onStateChanged: (model: TournamentClassResponse) => void,
    onIsLoading?:(isLoading: boolean) => void,
    tournamentClassId?: number    
}

export const TournamentClassState: FC<Props> = props => {
    const dispatch = useDispatch();
    const [ tournamentClassResponse, setTournamentClassResponse ] = React.useState<TournamentClassResponse>()
    const [tournamentClassId, setTournamentClassId] = React.useState<number>()
    const tournamentCLassState = useSelector<
    RootState,
    ReducerStateProperties<TournamentClassResponse>>(
        (state) => state.tournaments.tournamentClass as ReducerStateProperties<TournamentClassResponse>
    )
    const onPropsChanged = () => {   
        if(props.tournamentClassId != tournamentClassId){
            dispatch(tournamentClassActions.get(props.tournamentClassId))
            setTournamentClassId(tournamentClassId)
        }
    }
    const onTournamentClassResponse = () => {
        const {status, model, errors, isLoading} = tournamentCLassState
        if(status === RequestStatus.Ok && !lodash.isEqual(tournamentClassResponse, model)){            
            setTournamentClassResponse(model)
            props.onStateChanged(model)
        }
        if(errors){
            dispatch(responseHandlerActions.call({error: {appError: errors as ErrorResponse}}))
        }
        if(props.onIsLoading){
            props.onIsLoading(isLoading)
        }
    }
    React.useEffect(onTournamentClassResponse, [tournamentCLassState.status])
    React.useEffect(onPropsChanged, [props.tournamentClassId])
    return(<React.Fragment></React.Fragment>)
}