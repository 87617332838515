import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStateProperties } from "../../commons/baseReducer";
import {default as lodash} from 'lodash';
import { RootState } from "../../store/rootReducer";
import { SportResultsDropdown } from "../../commons/ui/dropdown/sportResultsDropdown";
import { SelectItem } from "../../commons/common.types";
import { AgeGroupApiViewModel } from "../../commons/ageGroup/model";
import { ageGroupActions } from "../../commons/ageGroup/ageGroupActionReducer";
import Skeleton from "../muiComponents/core/Skeleton";

type props = {
    handleChange: (event: any) => void,
    defaultAgeGrouIds?: number[],
    isDefaultAgeGroups?: boolean,
    isMultiple?: boolean
}

export const AgeGroupDropdown: FC<props> = (props) => {
   
    const ageGroupState = useSelector<RootState, ReducerStateProperties<AgeGroupApiViewModel[]>>((state) => state.ageGroups)
    // const [defaultAgeGroups, setDefaultAgeGroups] = useState<SelectItem[]>(null)
    const [selectItems, setSelectItems] = useState<SelectItem[]>();
    const distapch = useDispatch();
    useEffect(() => {

        if(!lodash.isEqual(props.defaultAgeGrouIds, selectItems)){
            let selectItem: SelectItem[] = []
            if(lodash.isEmpty(props.defaultAgeGrouIds)){
                setSelectItems(selectItem);
                return;
            }
            const defaultAgeGroups = ageGroupState.model?.filter(x => props.defaultAgeGrouIds?.some(a => a === x.ageGroupId))
            selectItem = defaultAgeGroups?.map(x => {
                return {
                    name: x.ageGroupName,
                    value: x.ageGroupId
                }
            })
            setSelectItems(selectItem)
        }
    }, [props.defaultAgeGrouIds])
    useEffect(() => {
        if(props.isDefaultAgeGroups){
            return;
        }
        if(!ageGroupState.loadStarted && lodash.isEmpty(ageGroupState.errors)){            
            distapch(ageGroupActions.getAll())
            
        }
        const selectItemIds = selectItems?.map(x => x.value);
        const ageGroupIds = ageGroupState?.model?.map(x => x.ageGroupId);
        if(!lodash.isEmpty(ageGroupState.model) && !lodash.isEqual(selectItemIds, ageGroupIds)){
            setSelectItems(ageGroupState.model?.map(x => {
                const item: SelectItem = {
                    name: x.ageGroupName,
                    value: x.ageGroupId
                }
                return item;
            }))
        }
    }, [ageGroupState])

    return(
        <React.Fragment>
            {selectItems &&
                <SportResultsDropdown multiple={props?.isMultiple} label="Årgang" data={selectItems} handleChange={props.handleChange}/>
            }
            {
               ageGroupState.isLoading && ageGroupState.loadStarted && <Skeleton variant="rectangular" />
            }            
        </React.Fragment>
    )
}