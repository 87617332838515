import { MatchPlayers, MatchSideEnum, PlayerLocation, PlayersFiledLocation } from "../../components/matchCounter"
import { ResponseHandlerState } from "../../components/responseHandler/ResponseHandler"
import { LivescoreSignalRException, SignarRExceptionPriority } from "../../pages/leagueMatch/leagueMatchSetCounter"
import { responseHandlerActions } from "../responseHandler/responseHandlerActionReducer"

export const setPlayersInitial = (matchPlayers: MatchPlayers): PlayersFiledLocation => {
    const {awayTeam, homeTeam} = matchPlayers
    return {
        playerA: PlayerLocation.createInstance(homeTeam?.player2, MatchSideEnum.Home),
        playerB: PlayerLocation.createInstance(homeTeam?.player1, MatchSideEnum.Home),
        playerC: PlayerLocation.createInstance(awayTeam?.player1, MatchSideEnum.Away),
        playerD: PlayerLocation.createInstance(awayTeam?.player2, MatchSideEnum.Away),
    }
}
export const isMultipleMatch = (matchPlayers: MatchPlayers):boolean => {
    const result = matchPlayers?.awayTeam?.player2?.playerId !== undefined
    && matchPlayers?.homeTeam?.player2?.playerId !== undefined    
    return result
}
export const getPlayerServeStyle = (currentPlayerServeId:number,playerId: number): string => {
    const currentServePlayerClass = 'current-serve-player';
    return currentPlayerServeId === playerId ? currentServePlayerClass : 'none'
}

export const getDispatchException = (exception: LivescoreSignalRException) => {  
    const {message, priority} = exception
    let exceptionToDispatch: ResponseHandlerState = undefined 
    switch(priority){
        case SignarRExceptionPriority.High:
            exceptionToDispatch = {error: {appError: {description: message}}}
            break;
        case SignarRExceptionPriority.Medium:
            exceptionToDispatch = {warningMsg: message,success: false}            
            break;
        default:
            throw Error("No type of SignarRExceptionPriority specified")
    }
    return exceptionToDispatch
}