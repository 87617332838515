import React, { FC } from 'react'
import Dialog from "../muiComponents/core/Dialog";
import Grid from "../muiComponents/core/Grid";

type props = {
  isMatchBlock: boolean;
  setIsMatchBlock: (isBLockMatchBtn: boolean) => void;
}

const BlockMatchDialogWhenMatchIsInUse: FC<props> = (props) => {
  
  return (
    <Dialog
      className='dialogCustom'
      onClose={() => props.setIsMatchBlock(false)} open={props.isMatchBlock}>
      <div>
        <Grid container style={{ maxWidth: '500px', maxHeight: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '2rem' }}>{'Du kan ikke tilf\u00F8je point, da kampen er taget'}</p>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default BlockMatchDialogWhenMatchIsInUse