import * as React from 'react';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TournamentEvent } from '../../commons/tournament/model';
import { RootState } from '../../store/rootReducer';
import { tournamentActions } from '../../commons/tournament/tournamentActionReducer';
import { RequestStatus } from '../../commons/baseReducer';
import * as lodash from 'lodash'
import { responseHandlerActions } from '../../commons/responseHandler/responseHandlerActionReducer';
import { ErrorResponse } from '../../commons/models/ErrorModel';

type Props = {
  onStateChanged: (model: TournamentEvent[]) => void,
  tournamentClassId: number,
  tournamentEventId?: number
}

export const TournamentDetailsState: FC<Props> = props => {
  const dispatch = useDispatch();
  const [tournamentClassId, setTournamentClassId] = React.useState<number>()
  const [tournamentEventId, setTournamentEventId] = React.useState<number>()
  const [tournamentEvents, setTournamentEvents] = React.useState<TournamentEvent[]>([])
  const tournamentDetailsSelector = useSelector(state => (state as RootState).tournaments.tournamentEvents)

  const onPropsChanged = () => {
    if (tournamentClassId != props.tournamentClassId || tournamentEventId != props.tournamentEventId) {
      dispatch(tournamentActions.getTournamentEvents(props.tournamentClassId, props.tournamentEventId))
      setTournamentClassId(tournamentClassId)
      setTournamentEventId(tournamentEventId)
    }
  }
  const onTournamentEventResponse = () => {
    const { status, model, errors } = tournamentDetailsSelector
    if (status === RequestStatus.Ok && !lodash.isEqual(tournamentEvents, model)) {
      setTournamentEvents(model)
      props.onStateChanged(model)
    }
    if (errors) {
      dispatch(responseHandlerActions.call({ error: { appError: errors as ErrorResponse } }))
    }
  }
  React.useEffect(onTournamentEventResponse, [tournamentDetailsSelector.status])
  React.useEffect(onPropsChanged, [props.tournamentClassId, props.tournamentEventId])
  return (<React.Fragment></React.Fragment>)
}