import { Action, ActionFunctionAny, createActions } from 'redux-actions';

import { generateKeyNames, IActionNames, IKeyStartAndPayloadName } from './keyAndPayloadName';
import { HttpStatusCode } from './models';
import { jsonParse } from './helpers';
import { ErrorResponse } from './models/ErrorModel';
const camelcase = require('camelcase');
export class BaseActions {

    keyStartAndPayloadName: IKeyStartAndPayloadName;
    syncActions: { [actionName: string]: ActionFunctionAny<Action<any>> }
    keyNames: IActionNames;

    constructor(keyStartAndPayloadName: IKeyStartAndPayloadName) {
        this.keyStartAndPayloadName = keyStartAndPayloadName;
        this.buildSyncActions = this.buildSyncActions.bind(this);
        this.keyNames = generateKeyNames(this.keyStartAndPayloadName);
    }

    buildSyncActions(): { [actionName: string]: ActionFunctionAny<Action<any>> } {
        this.syncActions = createActions({
            [this.keyNames.START]: () => {  },
            [this.keyNames.SUCCESS]: (payload: any) => {
                return Array.isArray(payload) ? Object.assign([], payload) : (typeof payload === 'object' ? Object.assign({}, payload) : payload)
            },
            [this.keyNames.FAILURE]: (errors: any) => { return errors },
            [this.keyNames.RESET]: () => {}
        });
        return this.syncActions;
    }

    createAsyncAction<TPayload>(promise: Promise<TPayload>, successResponseFunc?: (response: any) => boolean): (dispatch: any) => Promise<TPayload> | Promise<void> {

        if (!successResponseFunc)
            successResponseFunc = (response: any) => response;

        return (dispatch: any) => {
            dispatch(this.syncActions[camelcase(this.keyNames.START)]());
            
            return promise
                .then(response => {
                    // @ts-ignore: Object is possibly 'null'.
                    if (successResponseFunc(response)) {
                        const action = this.syncActions[camelcase(this.keyNames.SUCCESS)](response);
                        dispatch(action);                        
                    } else {
                        dispatch(this.syncActions[camelcase(this.keyNames.FAILURE)](response));
                    }
                }).catch((e: ErrorResponse) => {
                    let parsedResponse = jsonParse(e?.response?.data as any) as ErrorResponse;

                    parsedResponse = parsedResponse ? parsedResponse : new ErrorResponse((e as any).toJSON());
                    
                    
                    // Bad request -> API says data is not valid
                    if (parsedResponse.status === HttpStatusCode.BadRequest ||
                        parsedResponse.status === HttpStatusCode.Conflict) {
                        dispatch(this.syncActions[camelcase(this.keyNames.FAILURE)](parsedResponse));
                    }
                    else if (parsedResponse.status === HttpStatusCode.Forbidden) {
                        parsedResponse = this.processErrorResponse(parsedResponse, "Du har ikke tilladelse til at se denne del af hjemmesiden.")                  
                        dispatch(this.syncActions[camelcase(this.keyNames.FAILURE)](parsedResponse));                         
                        dispatch({ type: 'ADD_ERROR', payload: parsedResponse });
                    }
                    // API says data could not be found
                    else if (parsedResponse.status === HttpStatusCode.NotFound) {
                        parsedResponse = this.processErrorResponse(parsedResponse, "Ressourcen kunne ikke findes i systemet.")                        
                        dispatch(this.syncActions[camelcase(this.keyNames.FAILURE)](parsedResponse));
                    }
                    else if (parsedResponse.status === HttpStatusCode.InternalServerError) {
                        dispatch(this.syncActions[camelcase(this.keyNames.FAILURE)](parsedResponse));
                        dispatch({ type: 'ADD_ERROR', payload: parsedResponse });
                    }
                    else if (parsedResponse.status === HttpStatusCode.Unauthorized) {
                        parsedResponse = this.processErrorResponse(parsedResponse, "Brugeren er ikke godkendt.")
                        dispatch(this.syncActions[camelcase(this.keyNames.FAILURE)](parsedResponse));                       
                        dispatch({ type: 'ADD_ERROR', payload: parsedResponse });
                    }
                    else if (parsedResponse.status === HttpStatusCode.NoContent) {
                        dispatch(this.syncActions[camelcase(this.keyNames.SUCCESS)](null));
                    }
                    else {
                        const e: ErrorResponse = { description: "", response: {}, metadata: "", source: "" };
                        dispatch(this.syncActions[camelcase(this.keyNames.FAILURE)](e));
                        dispatch({ type: 'ADD_ERROR', payload: e });
                    }                    
                });
        };
    }

    processErrorResponse = (error: ErrorResponse, defaultMessage: string) => {
        if(!error?.description){
            error.response = {"": `${defaultMessage}`} 
        }
        return error;
    }
}