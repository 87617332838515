import { Enviroment } from "../commons/enviroment";

export class BaseService {
    protected env: Enviroment;
    protected baseUrl: string;
    
    constructor(resourceName: string){
        this.env = new Enviroment();
        const protocol = window.location.protocol;
        this.baseUrl = `${protocol}//${this.env.api}/${resourceName}`           
    }
}