import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { TournamentClassLiveScoreState, TournamentEvent } from '../../commons/tournament/model';
import * as lodash from 'lodash'
import { SignalRService } from '../../api/signalR.service';
import { getLiveTournamentEvents } from '../../commons/utils/tournament.utils';

type Props = {
  tournamentEvents: TournamentEvent[],
  onTournamentEventsUpdate: (tournamentEvents: TournamentEvent[]) => void,
  onTournamentClassLiveStateUpdate: (tournamentClassLiveState: TournamentClassLiveScoreState) => void,
  tournamentClassId: number,
}

export const TournamentEventsLiveScore: FC<Props> = props => {
  const [hasConnectSignalR, setHasConnectSignalR] = useState(false)
  const [hubConnection, setHubConnection] = useState<signalR.HubConnection | undefined>()
  const [signalRService] = useState(new SignalRService())
  const [liveScoreState, setLiveScoreState] = useState<TournamentClassLiveScoreState | undefined>(undefined)
  const [liveTournaments, setLiveTournaments] = useState<TournamentEvent[] | undefined>();
  const receiveResults = (results: TournamentClassLiveScoreState) => {
    setLiveScoreState(results)
  }
  useEffect(() => {
    if (!lodash.isEmpty(liveScoreState)) {
      const liveTournamentEvents = getLiveTournamentEvents(liveScoreState, props.tournamentEvents)
      if (liveTournamentEvents.hasBeenChanged) {
        setLiveTournaments(liveTournamentEvents.result)
        props.onTournamentClassLiveStateUpdate(liveScoreState)
        props.onTournamentEventsUpdate(liveTournamentEvents.result)
      }
    }

  }, [liveScoreState, props.tournamentEvents])
  useEffect(() => {
    if (!hasConnectSignalR && !lodash.isEmpty(props.tournamentEvents) && !hasConnectSignalR) {
      const hubConnection = signalRService.getConnection('tournament')
      setHubConnection(hubConnection)
      setHasConnectSignalR(true)
      hubConnection.start().then(a => {
        if (hubConnection.connectionId) {
          hubConnection.invoke("sendConnectionId", hubConnection.connectionId,
            `tournamentClass_${props.tournamentClassId.toString()}`)
            .then(() => {
              hubConnection.invoke<any>('getParentMatchLastResultsTournament',
                `tournamentClass_${props.tournamentClassId.toString()}`)
                .then(results => {
                  receiveResults(results)
                })
              hubConnection.on('TournamentNewResults', receiveResults)
            })
        }
      })
    }
  }, [hasConnectSignalR, props.tournamentEvents])

  return (<React.Fragment></React.Fragment>)
}