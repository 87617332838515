import React, { FC, useState } from "react";
import {default as lodash} from 'lodash';
import { SelectItem } from "../../commons/common.types";
import { TournamentClassResponse } from "../../commons/tournamentClass/model";
import { SportResultsDropdown } from "../../commons/ui/dropdown/sportResultsDropdown";
import { TournamentClassState } from "./tournamentClassState";
import Skeleton from "../muiComponents/core/Skeleton";

type props = {
    handleChange: (event: any) => void,
    defaultTournamentClassId: number
}

export const AgeGroupTournamentClassDropdown: FC<props> = (props) => {
    
    const [selectItems, setSelectItems] = useState<SelectItem[]>();
    const [defaultValue, setDefaultValue] = useState<SelectItem>();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const onStateChanged = (tournamentClassResponse: TournamentClassResponse) => {
        const selectItemIds = selectItems?.map(x => x.value);
        const tcIds = tournamentClassResponse?.tournamentClasses?.map(x => x.tournamentClassID);
        if(!lodash.isEmpty(tournamentClassResponse) && !lodash.isEqual(selectItemIds, tcIds)){
            let newSelectItems = tournamentClassResponse?.tournamentClasses?.map(x => {
                const item: SelectItem = {
                    name: `${x.ageGroupName} ${x.className}`,
                    value: x.tournamentClassID
                }
                return item;
            })
            if(props.defaultTournamentClassId){
                setDefaultValue(newSelectItems.find(s => props.defaultTournamentClassId === s.value))
            }
            setSelectItems(newSelectItems)
        }
    }
    return(
        <React.Fragment>
            <TournamentClassState
            tournamentClassId={props.defaultTournamentClassId}
            onStateChanged={(model:TournamentClassResponse) => onStateChanged(model)}
            onIsLoading={(isLoading: boolean) => setIsLoading(isLoading)}/>
            {selectItems &&
                <SportResultsDropdown defaultValue={defaultValue} label="Årgang" data={selectItems} handleChange={props.handleChange}/>
            }
            {
               isLoading && <Skeleton variant="rectangular" />
            }            
        </React.Fragment>
    )
}