import React, { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReducerStateProperties } from "../../commons/baseReducer"
import { LeagueMatchResponse } from "../../commons/leagueMatch/model"
import { SportResultsTable, SportResultsTableRow } from "../../commons/ui/table/sportresult.table"
import { RootState } from "../../store/rootReducer"
import { default as lodash } from 'lodash'
import { getMomentDate } from "../../commons/helpers"
import { useNavigate } from "react-router-dom"
import { LeagueMatchLivescoreCodeForm } from "./leagueMatchLivescoreCodeForm"
import { isPageLiveScore } from "../../commons/utils/businessUtils"
import { SignalRService } from "../../api/signalR.service"
import { signalRStateActions } from "../../commons/singalR/signalRStateReducer"
import TableCell from "../muiComponents/core/TableCell";

type props = {
  isLivescore: boolean,
  isReadonly: boolean
}

export const LeagueMatchTable: FC<props> = (props) => {
  const leagueMatchesOnLive = 'leagueMatchesOnLive'

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMatch, setSelectedMatch] = useState<LeagueMatchResponse>(null)
  const [hasToOpenDialog, setHasToOpenDialog] = useState<boolean>(false);
  const [isLiveScore] = useState<boolean>(isPageLiveScore())
  const leagueMatchesState = useSelector<RootState>((state) => {
    return state.leagueMatch.leagueMatches
  }) as ReducerStateProperties<LeagueMatchResponse[]>;
  
  const [hasConnectSignalR, setHasConnectSignalR] = useState(false)
  const [hubConnection, setHubConnection] = useState<signalR.HubConnection | undefined>()
  const [signalRService] = useState(new SignalRService())
  const [leagueMatchLiveResult, setLeagueMatchLiveResult] = useState<{ [key: number]: LeagueMatchLivescoreResult }>(undefined)
  const [tableDate, setTableData] = useState<SportResultsTableRow[]>([])
  const receiveResults = (state: { [key: number]: LeagueMatchLivescoreResult }) => {
    if (state && !lodash.isEqual(state, leagueMatchLiveResult)) {
      setLeagueMatchLiveResult(state)
    }
  }

  useEffect(() => {
    if (leagueMatchLiveResult && leagueMatchesState.model) {
      const { model } = leagueMatchesState
      if (model) {
        model.forEach(match => {
          const stateItem = leagueMatchLiveResult[match.leagueMatchId]
          if (stateItem?.point1 !== undefined
            && stateItem?.point2 !== undefined) {
            match.score1 = stateItem.point1
            match.score2 = stateItem.point2
          }
        })
      }
    }
    if (leagueMatchesState.model) {
      setTableData(getTableData())
    }

  }, [leagueMatchLiveResult, leagueMatchesState.model])


  const setupConnection = () => {
    if (!hasConnectSignalR && !lodash.isEmpty(leagueMatchesState.model) && !hasConnectSignalR) {
      const hubConnection = signalRService.getConnection('leaguematch')
      setHubConnection(hubConnection)
      setHasConnectSignalR(true)
      hubConnection.start().then(a => {
        if (hubConnection.connectionId) {
          hubConnection.invoke("sendConnectionId", hubConnection.connectionId, `${leagueMatchesOnLive}`)
            .then(() => {
              hubConnection.invoke<any>('getLeagueMatchesOnLive')
                .then(results => {
                  receiveResults(results)
                })
              hubConnection.on('getleagueMatchesOnLive', receiveResults)
            })
        }
      }).finally(() => {
        const refreshFunc = () => {
          hubConnection.stop().then(() => {
            setHubConnection(undefined)
            setHasConnectSignalR(false)
          })
        }
        dispatch(signalRStateActions.setState(hubConnection, refreshFunc))
      })
    }
  }

  const getTableData = () => {
    let result: SportResultsTableRow[] = []
    if (!lodash.isEmpty(leagueMatchesState.model)) {
      result = leagueMatchesState.model.map((leagueMatch, index) => {
        const cells: JSX.Element[] = [
          <TableCell aria-label={headerLabels[0]} scope='row'>{leagueMatch.leagueMatchId}</TableCell>,
          <TableCell aria-label={headerLabels[1]} align='right'>{getMomentDate(leagueMatch.matchTime)}</TableCell>,
          <TableCell aria-label={headerLabels[2]} align='right'>{leagueMatch.leagueDivisionName}</TableCell>,
          <TableCell aria-label={headerLabels[3]} align='right'>{leagueMatch.homeTeamName}</TableCell>,
          <TableCell aria-label={headerLabels[4]} align='right'>{leagueMatch.awayTeamName}</TableCell>,
          <TableCell aria-label={headerLabels[5]} align='right'>{`${leagueMatch.score1} - ${leagueMatch.score2}`}</TableCell>
        ]
        const result: SportResultsTableRow = {
          id: leagueMatch.leagueMatchId,
          cells
        }
        return result;
      });
    }
    return result;
  }

  useEffect(() => {
    setupConnection()
  }, [hasConnectSignalR, leagueMatchesState.model])

  const onLeageSelect = (event: any) => {
    const selectedMatch = leagueMatchesState?.model.find(x => x.leagueMatchId === event.id);
    setSelectedMatch(selectedMatch);
    if (!isLiveScore) {
      setHasToOpenDialog(true);
    } else {
      navigateToLeagueMatch(selectedMatch.leagueMatchId)
    }
  }
  const onLiveScoreSubmit = (code: string) => {
    navigateToLeagueMatch(selectedMatch.leagueMatchId, code)
  }

  const navigateToLeagueMatch = (leagueMatchId: number, code?: string) => {
    let url = `${leagueMatchId}`
    if (code) {
      url += `/${code}`
    }
    // url = getPage(url, props.isLivescore, props.isReadonly)

    navigate(url)
  }
  const isLoading = leagueMatchesState.isLoading && leagueMatchesState.loadStarted;
  return (
    <React.Fragment>
      {(tableDate || isLoading) &&
        <SportResultsTable
          isRowsLoading={isLoading}
          data={tableDate}
          headers={headers}
          footerLabel="Kampe pr. side"
          tableLabel="tilgængelige kampe"
          onRowSelect={onLeageSelect}
        />
      }
      {
        hasToOpenDialog &&
        <LeagueMatchLivescoreCodeForm
          liveScoreCode={selectedMatch?.livescoreCode}
          onClose={() => setHasToOpenDialog(false)}
          onLiveScoreCodeSubmit={onLiveScoreSubmit}
        />
      }
    </React.Fragment>
  )
}

const headerLabels = [
  'Kampnr', 'Runde', 'Række', 'Hjemmehold', 'Udehold', 'Resultat'
]

const headers: JSX.Element[] = [
  <TableCell>{headerLabels[0]}</TableCell>,
  <TableCell align="right">{headerLabels[1]}</TableCell>,
  <TableCell align="right">{headerLabels[2]}</TableCell>,
  <TableCell align="right">{headerLabels[3]}</TableCell>,
  <TableCell align="right">{headerLabels[4]}</TableCell>,
  <TableCell align="right">{headerLabels[5]}</TableCell>
]

export interface LeagueMatchLivescoreResult {
  point1: number;
  point2: number
}