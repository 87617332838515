import axios from "axios";
import { ClubResponse } from "../commons/clubs/model";
import { BaseService } from "./base.service";

export class ClubsService extends BaseService {
    constructor(){
        super("Club");
    }

    public getAll = (): Promise<ClubResponse[]> => {
        const url = `${this.baseUrl}/all`;
        return axios.get<ClubResponse[]>(url).then(res => res.data);
    }
}