import React, { FC, useEffect, useState } from "react";
import { MatchSetResult } from "./matchCounter";
import useTheme from "./muiComponents/core/useTheme";
import Dialog from "./muiComponents/core/Dialog";
import Grid from "./muiComponents/core/Grid";

type Props = {
  onClose: (isBreakFinished: boolean) => void,
  isClosed: boolean,
  matchScore: () => MatchSetResult,
  endOfSet: boolean,
}

export const BreakCounterMatch: FC<Props> = (props) => {
  const match = props.matchScore();
  const elevenPointsBreak = 11;

  const [open, setOpen] = useState<boolean>(props.isClosed);

  const [seconds, setSeconds] = useState(
    match.score1 === elevenPointsBreak || match.score2 === elevenPointsBreak
      ? 60
      :
      props.endOfSet
        ? 120
        : 0
  )

  const [countdownInterval, setCountdownInterval] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const ctdInterval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(ctdInterval);
          handleDialog(false);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);
    setCountdownInterval(ctdInterval);

    return () => {
      clearInterval(countdownInterval);
      handleDialog(false);
    };
  }, []);

  const handleDialog = (value: boolean) => {
    props.onClose(value)
    setOpen(value);
    clearInterval(countdownInterval);
    setCountdownInterval(null);
  }

  return (
    <Dialog
      className='dialogCustom'
      onClose={() => handleDialog(false)} open={open}>
      <div>
        <Grid container style={{ width: '200px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '2rem' }}>{'Ophold'}</p>
            <p style={{ fontSize: '3rem' }}>{seconds}</p>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}