import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { Tournament, TournamentClassLiveScoreState, TournamentEvent } from '../../commons/tournament/model';
import { DisciplineCodeMapping, Disciplines } from '../../commons/leagueMatch/disciplines';
import { TournamentClassResponse, TournamentEventDto } from '../../commons/tournamentClass/model';
import { TournamentClassState } from '../../components/tournamentClass/tournamentClassState';
import { TournamentEventDetails } from '../../components/tournament/tournamentEventDetails';
import { TournamentEventRowId } from '../../components/tournament/tournamentEvent.table';
import { useNavigate } from 'react-router-dom';
import { getPage } from '../../commons/utils/ui.utils';
import { TournamentStateManager } from './tournamentState';
import { TournamentEventDetailsLive } from '../../components/tournament/tournamentEventDetailsLive';
import Tab from "../../components/muiComponents/core/Tab";
import TabPanel from "../../components/muiComponents/lab/TabPanel";
import TabContext from "../../components/muiComponents/lab/TabContext";
import AppBar from "../../components/muiComponents/core/AppBar";
import Grid from "../../components/muiComponents/core/Grid";
import Tabs from "../../components/muiComponents/core/Tabs";

type Props = {
  tournamentEvents: TournamentEvent[],
  defaultTournamentEventId?: number | undefined,
  tournamentClassId: number,
  onTournamentEventChanged: (tournamentEventId: number) => void,
  isLivescore: boolean,
  isReadonly: boolean,
  isUmpire: boolean,
  showOnlyEmpty: boolean,
  tournamentClassLiveState?: TournamentClassLiveScoreState
}

export const TournamentDetailsList: FC<Props> = props => {
  const urlPath = props.isUmpire ? "umpire" : "count";

  const navigate = useNavigate()
  const [tournament, setTournament] = useState<Tournament>()
  const getDisciplines = (tournamentEvents: TournamentEventDto[]) => {
    return Array.from(new Set<string>(tournamentEvents.map(te => te.disciplineCode)))
  }
  const getDefaultSelectedTabIndex = (tournamentEvents: TournamentEvent[], defaultTournamentEventId?: number) => {
    let defaultSelectedTabIndex = DisciplineCodeMapping.MensSingles.Index
    let defaultTournamentEvent = tournamentEvents.find(
      te => te.tournamentEventId === defaultTournamentEventId
    )
    if (!defaultTournamentEvent) {
      defaultTournamentEvent = tournamentEvents
        .find(te => te.disciplineCode === DisciplineCodeMapping.MensSingles.LongName)
    }
    if (defaultTournamentEvent) {
      defaultSelectedTabIndex = DisciplineCodeMapping[defaultTournamentEvent.disciplineCode].Index
    }
    return defaultSelectedTabIndex
  }
  const onTournamentClassResponseChanged = () => {
    if (tournamentClassResponse && tournamentClassResponse?.tournamentEvents?.length > 0) {
      setDisciplines(getDisciplines(tournamentClassResponse?.tournamentEvents))
    }
  }

  const disableClickingRowsWithEmptyPlayers = (tournamentEventsByDiscipline: TournamentEvent[]): TournamentEvent[] => {
    return tournamentEventsByDiscipline.map(event => {
      event.unifiedTournamentMatches.forEach(match => {
        match.isPossibleToCount = !(match.awayPlayers?.length === 0 && match.homePlayers?.length === 0);
      });
      return event;
    });
  }

  const onFilterParamsChanged = () => {
    const tabHeaders = new Array<any>()
    const tabPanels = disciplines?.map(d => {
      const te = tournamentClassResponse?.tournamentEvents?.find(te => te.disciplineCode === d)
      const disciplineName = Disciplines.getLabel(d)
      const tabHeaderIndex = tabHeaders.length;
      tabHeaders.push(
        <Tab onClick={(event: any) => {
          setSelectedTab(tabHeaderIndex)
          props.onTournamentEventChanged(te.tournamentEventId)
        }} value={tabHeaderIndex} key={`link-tab-${disciplineName}`} label={disciplineName}
        />
      );
      const tournamentEventsByDiscipline = props.tournamentEvents.filter(
        te => te.disciplineCode === d
      )

      const tournamentEventsByDisciplineWithoutEmptyPlayers = disableClickingRowsWithEmptyPlayers(tournamentEventsByDiscipline);

      return (
        <TabPanel key={`tab-panel-${tabHeaderIndex}`} value={tabHeaderIndex.toString()}>
          {
            tournamentEventsByDisciplineWithoutEmptyPlayers?.length > 0 &&
            <TournamentEventDetails
              showOnlyEmpty={props.showOnlyEmpty}
              onRowSelect={onTournamentRowSelect}
              key={`tournament-event-details-${tabHeaderIndex}`}
              tournamentEvents={tournamentEventsByDisciplineWithoutEmptyPlayers}
            />
          }
        </TabPanel>
      )
    })
    if (tabPanels !== undefined) {
      const liveScoreIndex = 100
      tabHeaders.push(
        <Tab onClick={(event: any) => {
          setSelectedTab(liveScoreIndex)

        }} value={liveScoreIndex} key={`link-tab-live`} label='Live'
        />
      );
      tabPanels.push(
        <TabPanel key={`tab-panel-${liveScoreIndex}`} value={liveScoreIndex.toString()}>
          {
            props.tournamentClassLiveState &&
            <TournamentEventDetailsLive
              showOnlyEmpty={props.showOnlyEmpty} onRowSelect={onTournamentRowSelect}
              key={`tournament-event-details-${liveScoreIndex}`}
              tournamentClassId={props.tournamentClassId} tournamentClassLiveState={props.tournamentClassLiveState} />
          }
        </TabPanel>
      )
      setHeaders({
        ...headers,
        tabPanels: tabPanels,
        tabHeaders: tabHeaders
      })
    }
  }
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  const onTournamentRowSelect = (event: TournamentEventRowId) => {
    if (!event.isPossibleToCount) {
      return;
    }

    setSelectedRowId(event);
    let url = `${urlPath}/tournament/counter/${props.tournamentClassId}/${event.tournamentEventId}/${event.matchId}/${tournament?.clubId}`
    navigate(getPage(url, props.isLivescore, props.isReadonly))
  }
  const [selectedRowId, setSelectedRowId] = useState<TournamentEventRowId>(null);
  const [tournamentClassResponse, setTournamentClassResponse] = useState<TournamentClassResponse | undefined>(undefined)
  const [disciplines, setDisciplines] = useState<string[]>()
  const defaultSelectedTabIndex = getDefaultSelectedTabIndex(props.tournamentEvents, props.defaultTournamentEventId)
  const [selectedTab, setSelectedTab] = useState<number>(defaultSelectedTabIndex);
  const [headers, setHeaders] = useState<TabHeader | undefined>()
  useEffect(onTournamentClassResponseChanged, [tournamentClassResponse])
  useEffect(onFilterParamsChanged, [disciplines, props.tournamentEvents, props.showOnlyEmpty, props.tournamentClassLiveState])
  return (<React.Fragment>
    <TournamentStateManager
      tournamentClassId={props.tournamentClassId}
      onStateChange={(model: Tournament) => setTournament(model)}
    />
    <TournamentClassState
      onStateChanged={(model: TournamentClassResponse) => setTournamentClassResponse(model)}
    />
    <TabContext key={'tournamentDetailsList__tab-context'} value={selectedTab?.toString()}>
      <AppBar key={'tournamentDetailsList__app-bar'} position="static" color='secondary'>
        <Grid key={'tournamentDetailsList__grid'} container>
          <Tabs key={'tournamentDetailsList__tabs'} value={selectedTab} onChange={handleChange}>
            {headers?.tabHeaders}
          </Tabs>
        </Grid>
      </AppBar>
      {headers?.tabPanels}
    </TabContext>
  </React.Fragment>)
}

interface TabHeader {
  tabHeaders: JSX.Element[]
  tabPanels: JSX.Element[]
}