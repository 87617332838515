import * as React from 'react';
import { FC } from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import * as lodash from 'lodash';
import { ReducerStateProperties, RequestStatus } from '../../commons/baseReducer';
import { responseHandlerActions } from '../../commons/responseHandler/responseHandlerActionReducer';
import { ErrorResponse } from '../../commons/models/ErrorModel';

type Props = {
    onStateChange : (model: any) => void,
    selectorFunc: (state: DefaultRootState) => ReducerStateProperties<any>,
    getterFunc: () => (dispatch: any) => Promise<any> | Promise<void>,
    dipatchOnInstance?: boolean,
    dispatchChange?: boolean,
    onChangeApplied?: () => void
}

export const BaseStateManager: FC<Props> = (props) => {
    const [currentModel, setCurrentModel] = React.useState<any | undefined>(undefined)
    const [isInit, setIsInit] = React.useState(true)    
    const selector = useSelector(props.selectorFunc) as ReducerStateProperties<any>
    const dispatch = useDispatch();
    const dispatchOrChangeState = () => {
        const {model, status} = selector
        // workaround for missing fetch type of actions in redux - task #818
        const isBlob = typeof(model?.data) === 'object'
        if((status === RequestStatus.NotInitiated || (props.dipatchOnInstance && isInit) || isBlob)){
            setIsInit(false)
            dispatch(props.getterFunc())
        }else if(!lodash.isEmpty(model) && !lodash.isEqual(model,currentModel) && status !== RequestStatus.Error){
            setCurrentModel(model)
            props.onStateChange(model)
        }
    }
    React.useEffect(dispatchOrChangeState,[selector.status])
    const onChangeStateOutside = () => {
        if(!selector.isLoading && props.dispatchChange){
            let newModel = undefined;
            if(lodash.isArray(currentModel)){
                newModel = selector.model.concat(currentModel ?? [])
            }else{
                newModel = selector.model
            }
            setCurrentModel(newModel)
            props.onChangeApplied();            
            dispatch(responseHandlerActions.call({success: true}))
            dispatch(props.getterFunc())
        }else if(!lodash.isEmpty(selector.errors)){            
            dispatch(responseHandlerActions.call({error: {appError: selector.errors as ErrorResponse}}))
        }
    }
    React.useEffect(onChangeStateOutside, [props.dispatchChange, selector.isLoading])
    return(<React.Fragment></React.Fragment>)
}