import { GeoRegionService } from "../../api/geoRegion.service";
import { BaseActionReducer } from "../baseActionReducer";

export const geoRegionActionReducer = new BaseActionReducer({ keyStart: 'GEOREGION', payloadName: 'georegion' });
const syncActionCreators = geoRegionActionReducer.buildSyncActions();

const geoRegionClient = new GeoRegionService();

const asyncActionCreators = {
    getAll: () => {
        return geoRegionActionReducer.createAsyncAction(
            geoRegionClient.getAll());
    },
};

export const geoRegionActions = {
    ...syncActionCreators,
    ...asyncActionCreators
};