import React, { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer"
import { CourtLeagueMatchIndividualMatchResponse, CourtTournamentEventMatchResponse } from "../../commons/courts/model"
import { RootState } from "../../store/rootReducer"
import * as lodash from 'lodash'
import { courtsActions } from "../../commons/courts/courtsActionReducer"
import { getMode } from "../../commons/utils/ui.utils"
import { CourtTournamentMatchesForm } from "./courtTournamentMatchesForm"
import { tournamentActions } from "../../commons/tournament/tournamentActionReducer"
import { CourtForm } from "../courts/courtForm"
import { TournamentEvent } from "../../commons/tournament/model"
import Grid from "../muiComponents/core/Grid";
import IconButton from "../muiComponents/core/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "../muiComponents/core/Button";
import { SaveOutlined } from "@mui/icons-material";

type props = {
  clubId: number,
  currentMatchId: number,
  currentTournamentEvent: number,
  tournamentClassId: number,
  handleNextMatch: (currentMatchId: number, currentTournamentEvent: number) => void,
  handleChangeCourtData: (courtData: CourtData) => void,
  handleRemove: () => void,
  handleAdd: () => void,
  isReadonly: boolean
}

export interface CourtData {
  courtId?: number,
  venueId?: number,
  courtNumber?: number,
  isMatchii?: boolean,
  courtExtId?: string
}

export const NextCounterTournament: FC<props> = (props) => {
  const dispatch = useDispatch()
  const [courtData, setCourtData] = useState<CourtData>(undefined)
  const assigmentState = useSelector<RootState>(x => {
    return x.courts.courtTournamentAssigment
  }) as ReducerStateProperties<CourtTournamentEventMatchResponse>
  const isAssigmentModelEmpty = () => lodash.isEmpty(assigmentState?.model)
  const [isDispatched, setIsDispatched] = useState<boolean>(false)
  const handleCourtChange = (courtId: number, courtNumber: number, isMatchii: boolean) => {
    const court: CourtData = {
      ...courtData,
      courtId,
      courtNumber,
      isMatchii
    }

    setCourtData(court);
    props.handleChangeCourtData(court)
  }
  const [matchId, setMatchId] = useState<number | undefined>(isAssigmentModelEmpty() ? props.currentMatchId : undefined)
  const [tournamentEventId, setTournamentEventId] = useState<number | undefined>(isAssigmentModelEmpty() ? props.currentTournamentEvent : undefined)

  const tournamentEventState = useSelector<RootState>(x => {
    return x.tournaments.tournamentEvents
  }) as ReducerStateProperties<TournamentEvent[]>

  const resultAssignTournamentMatchToCourt = useSelector<RootState>(x => {
    return x.courts.courtAssigment
  }) as ReducerStateProperties<CourtLeagueMatchIndividualMatchResponse>

  useEffect(() => {
    dispatch(courtsActions.getCurrentTournamentCourt(props.tournamentClassId, props.currentTournamentEvent, props.currentMatchId))
  }, [resultAssignTournamentMatchToCourt])

  useEffect(() => {
    if (lodash.isEmpty(tournamentEventState) && tournamentEventState.status === RequestStatus.NotInitiated)
      dispatch(tournamentActions.getTournamentEvents(props.tournamentClassId))
  }, [tournamentEventState])

  useEffect(() => {
    const stateMatchId = assigmentState.model?.tournamentEventMatch?.matchId
    const stateTournamentId = assigmentState.model?.tournamentEventMatch?.tournamentEventId
    const { model } = assigmentState

    if (!isAssigmentModelEmpty() && !lodash.isEqual(model?.courtId, courtData?.courtId)) {
        const { courtNumber, venueId, courtId, courtExtId } = assigmentState?.model
      const newCourtData: CourtData = {
        ...courtData,
        courtId,
        courtNumber,
          venueId,
          courtExtId
      }
      setCourtData(newCourtData)

      props.handleChangeCourtData(newCourtData)
    }

    if (!isAssigmentModelEmpty() && isDispatched &&
      (lodash.isEqual(stateMatchId, matchId) && lodash.isEqual(stateTournamentId, tournamentEventId))) {
      setIsDispatched(false)
      props.handleNextMatch(matchId, tournamentEventId)
    }

    if (isAssigmentModelEmpty() && assigmentState.status === RequestStatus.NotInitiated) {
      dispatch(courtsActions.getCurrentTournamentCourt(props.tournamentClassId, props.currentTournamentEvent, props.currentMatchId))
    }

    if (isAssigmentModelEmpty() && (!lodash.isEqual(props.currentMatchId, matchId) && !lodash.isEqual(props.currentTournamentEvent, tournamentEventId))) {
      setMatchId(props.currentMatchId)
      setTournamentEventId(props.currentTournamentEvent)
    }
  }, [assigmentState, assigmentState.model])

  const createOrUpdateAssigment = () => {
    if (courtData?.courtId !== undefined && matchId !== undefined && tournamentEventId !== undefined) {
      dispatch(courtsActions.assignTournamentMatchToCourt(courtData?.courtId, props.tournamentClassId, tournamentEventId, matchId, 0))

      const newCourtData: CourtData = {
        ...courtData,
        courtId: courtData?.courtId,
        courtNumber: courtData?.courtNumber,
        isMatchii: courtData?.isMatchii
      }
      setCourtData(newCourtData)

      props.handleAdd();
    }
  }

  const removeAssigment = () => {
    dispatch(courtsActions.removeTournamentAssigment(props.currentTournamentEvent, props.currentMatchId))
    props.handleRemove()
  }

  const buttonText = isAssigmentModelEmpty() ? 'Gem banen' : 'Start næste kamp'
  const mode = useSelector<RootState>(x => x.mode.model ?? getMode()) as 'light' | 'dark'
  const style = { color: mode == 'light' ? 'initial' : 'white' }
  const handleMatchChange = (tournamentEventId: number, matchId: number) => {
    setTournamentEventId(tournamentEventId)
    setMatchId(matchId)
  }
  return (
    <React.Fragment>
      <Grid container>
        {!isAssigmentModelEmpty() && !props.isReadonly && !props.isReadonly &&
          <React.Fragment>
            <Grid item xs={2} style={{ marginTop: '1%', marginBottom: '1%' }}>
              <label style={style}>Kampen er sat til {`${assigmentState.model.venueName} Bane: ${assigmentState.model.courtNumber}`}</label></Grid>
            <Grid item xs={2}>
              <IconButton onClick={removeAssigment}><DeleteIcon /></IconButton>
            </Grid>
          </React.Fragment>
        }
        {isAssigmentModelEmpty() &&
          <label style={style}>Kampen mangler at f&#229; valgt bane</label>
        }

      </Grid>
      {!isAssigmentModelEmpty() && tournamentEventState.model && !props.isReadonly && !props.isReadonly &&
        <CourtTournamentMatchesForm
          handleMatchChange={handleMatchChange}
          tournamentEvents={tournamentEventState.model}
          clubId={props.clubId}
          matchId={props.currentMatchId}
          tournamentEventId={props.currentTournamentEvent}
          tournamentClassId={props.tournamentClassId}
        />
      }
      {props.clubId && !props.isReadonly && !props.isReadonly && <CourtForm
        handleCourtChange={handleCourtChange}
        clubId={props.clubId} />
      }
      {
        (courtData?.courtId !== undefined && matchId !== undefined && tournamentEventId !== undefined)
        && !props.isReadonly && !props.isReadonly &&
        <Button
          variant="contained"
          color="secondary"
          endIcon={<SaveOutlined />}
          onClick={createOrUpdateAssigment}>
          {buttonText}
        </Button>
      }
    </React.Fragment>
  )
}