import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TournamentClassLiveScoreState, TournamentEvent, TournamentPatchRequest, UnifiedTournamentMatch } from "../../commons/tournament/model";
import { RootState } from "../../store/rootReducer";
import { default as lodash } from 'lodash';
import { MatchCounter, MatchPlayer, MatchPlayers, MatchSetResult, PlayersFiledLocation } from "../matchCounter";
import { titleActions } from "../../commons/navigationActionReducer";
import { SignalRService } from "../../api/signalR.service";
import { LiveScoreMatchState } from "../../pages/leagueMatch/leagueMatchSetCounter";
import { isPageLiveScore } from "../../commons/utils/businessUtils";
import { MatchPointsViewer } from "../matchPointsViewer";
import { BaseProps } from "../../routes";
import { useNavigate, useParams } from "react-router-dom";
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer";
import { tournamentActions } from "../../commons/tournament/tournamentActionReducer";
import { CourtData, NextCounterTournament } from "./nextCounterTournament";
import { getPage } from "../../commons/utils/ui.utils";
import { responseHandlerActions } from "../../commons/responseHandler/responseHandlerActionReducer";
import { getDispatchException } from "../../commons/utils/matchCounterUtils";
import { TournamentService } from "../../api/tournament.service";
import { LeagueMatchLivescoreCodeForm } from "../leagueMatch/leagueMatchLivescoreCodeForm";
import { TournamentEventsLiveScore } from "../../pages/tournament/tournamentEventsLiveScore";
import { BulkCourtResponse } from "../../commons/courts/model";

type props = BaseProps

export const TournamentCounter: FC<props> = (props) => {
  const urlPath = props.isUmpire ? "umpire" : "count";
  const { tournamentClassIdStr, matchId, tournamentEventIdStr, clubId, password } = useParams();
  const tournamentClassId = Number(tournamentClassIdStr);
  const tournamentEventId = Number(tournamentEventIdStr);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTitleSet, setIsTitleSet] = useState<boolean>();
  const [courtData, setCourtData] = useState<CourtData>()
  const [courtIdFake, setCourtIdFake] = useState<number>(0)

  const courts = useSelector<RootState>((state) => {
    return state.courts.venueCourts;
  }) as ReducerStateProperties<BulkCourtResponse>;

  if (!lodash.isEmpty(courts.model)) {
    const courtIds = courts?.model?.courtResponses?.map(item => item.courtId);

    if (!lodash.isEmpty(courtIds) && courtIdFake === 0) {
      //Random numbers 10000, 200
      const randomInteger = Math.floor(Math.random() * (100000 - 200)) + 200;

      if (courtIdFake === 0) {
        setCourtIdFake(randomInteger);
      }
    }
  }

  const tournamentMatch = useSelector<RootState>((state) => {
    const tournamentEvents = state.tournaments.tournamentEvents.model;
    const events = state.tournaments.tournamentEvents.model?.find(x => x?.tournamentEventId === tournamentEventId);
    return {
      tournamentEvents: tournamentEvents,
      model: events?.unifiedTournamentMatches?.find(x => x?.matchId.toString() === matchId),
      status: state.tournaments.tournamentEvents.status
    }
  }) as TournamentEventMatchState;

  const [tournamentMatchState, setTournamentMatchState] = useState(null);
  const [hasConnectSignalR, setHasConnectSignalR] = useState(false)
  const [hubConnection, setHubConnection] = useState<signalR.HubConnection | undefined>()
  const [isLiveScore] = useState<boolean>(isPageLiveScore())
  const [liveScoreState, setLiveScoreState] = useState<LiveScoreMatchState>()
  const [signalRService] = useState(new SignalRService())
  const [liveScoreCodeStr, setLiveScoreCodeStr] = useState<string>('');
  const [hasToOpenDialog, setHasToOpenDialog] = useState<boolean>(false);
  const [tournamentEvents, setTournamentEvents] = useState<TournamentEvent[]>([])
  const [tournamentClassLiveState, setTournamentClassLiveState] = useState<TournamentClassLiveScoreState>()

  const tournamentService = new TournamentService();

  useEffect(() => {
    // Need to pass venueId and courtId to the livescore hub
    const courtIdState = courtData?.courtId

    if (!hasConnectSignalR && (courtIdFake > 0 || courtIdState > 0) && !lodash.isEmpty(liveScoreState?.matchResults)) {
      const hubConnection = signalRService.getConnection('tournament')
      setHubConnection(hubConnection)
      setHasConnectSignalR(true)
      hubConnection.start().then(a => {
        if (hubConnection.connectionId) {
          hubConnection.invoke("sendConnectionId", hubConnection.connectionId, matchId)
            .then(() => {
              hubConnection.invoke<LiveScoreMatchState>('getMatchLastResults',
                Number.parseInt(matchId))
                .then(results => {
                  receiveLiveScoreResults(results)
                })
            });
          if (isLiveScore) {
            hubConnection.on('matchNewResults', receiveLiveScoreResults)
          } else {
            hubConnection.on('matchNewResults', handleException)
          }
          if (!isLiveScore && !props.isReadonly) {
            tournamentEventSendResult(hubConnection, liveScoreState.matchResults, liveScoreState.playersLocation,
              liveScoreState?.currentPlayerServeId, Number.parseInt(matchId))
          }
        }
      })
    }
  }, [hasConnectSignalR, courtData?.venueId, courtData?.courtId, liveScoreState?.matchResults])

  useEffect(() => {
    const GetLeagueMatch = async () => {
      const tournamentClassId = lodash.toNumber(tournamentClassIdStr)
      const clubIdNumber = lodash.toNumber(clubId);

      let searchCriteria = new TournamentPatchRequest()
      searchCriteria.clubIds = [clubIdNumber];
      searchCriteria.dateFrom = null;

      const result = await tournamentService.get(searchCriteria);
      const tournamentAdmin = lodash.find(result.tournamentAdmins, (item) => item.tournamentClassID === tournamentClassId);

      if (!lodash.isEmpty(password)) {
        if (tournamentAdmin?.liveScoreCode !== password) {
          setLiveScoreCodeStr(tournamentAdmin?.liveScoreCode);
          setHasToOpenDialog(true);
        }
      }

      return result;
    }

    GetLeagueMatch();
  }, [password, clubId]);

  const handleException = (liveScoreState: LiveScoreMatchState) => {
    if (liveScoreState?.exceptions) {
      const { exceptions } = liveScoreState
      exceptions?.forEach(ex => {
        dispatch(responseHandlerActions.call(getDispatchException(ex)))
      })
    }
  }

  const receiveLiveScoreResults = (liveScoreState: LiveScoreMatchState) => {
    setLiveScoreState(liveScoreState)
  }

  useEffect(() => {
    if (!isTitleSet) {
      dispatch(titleActions.setNavigation('Tæl turnering'));
      setIsTitleSet(true);
    }

    if (hasToOpenDialog) {
      dispatch(titleActions.setNavigation(''));
      setIsTitleSet(false);
    }
  }, [isTitleSet, hasToOpenDialog])

  useEffect(() => {
    if (!lodash.isEqual(tournamentMatchState, tournamentMatch)) {
      setTournamentMatchState(tournamentMatch);
    }
    if (lodash.isEmpty(tournamentMatch.model) && tournamentMatch.status === RequestStatus.NotInitiated) {
      dispatch(tournamentActions.getTournamentEvents(tournamentClassId, tournamentEventId))
    }
  }, [tournamentMatch]);

  const processResult = (result: string) => {
    if (result) {
      var numberPattern = /\d+/g;
      const sets = result.match(numberPattern);
      if (sets.length > 0) {
        while ((sets.length % 2) === 1) {
          sets.push('0');
        }
        const matchResults = mapResultToMatchSet(sets, (sets.length / 2) - 1);

        return matchResults;
      }
      return null;
    }
  }

  const getMatchPlayers = (tournamentMatch: UnifiedTournamentMatch): MatchPlayers => {
    const homePlayers = tournamentMatch.homePlayers;
    const awayPlayers = tournamentMatch.awayPlayers;
    const firstHomePlayer = homePlayers[0]
    const secondHomePlayer = homePlayers[1]
    const firstAwayPlayer = awayPlayers[0]
    const secondAwayPlayer = awayPlayers[1]
    let result: MatchPlayers = {
      awayTeam: {
        player1: MatchPlayer.createInstance(firstAwayPlayer),
        player2: MatchPlayer.createInstance(secondAwayPlayer),
        clubId: firstAwayPlayer?.clubId
      },
      homeTeam: {
        player1: MatchPlayer.createInstance(firstHomePlayer),
        player2: MatchPlayer.createInstance(secondHomePlayer),
        clubId: firstHomePlayer?.clubId
      }
    }

    return result
  }

  const mapResultToMatchSet = (sets: RegExpMatchArray, index: number,
    matchResults: MatchSetResult[] = new Array<MatchSetResult>()) => {
    if (sets.length > 0) {
      const score2 = new Number(sets.pop()).valueOf();
      const score1 = new Number(sets.pop()).valueOf();
      const matchResult: MatchSetResult = {
        score1,
        score2,
        setIndex: index
      }
      matchResults.push(matchResult);
      mapResultToMatchSet(sets, index - 1, matchResults)
    }
    return matchResults;
  }

  const onChangeSet = (event: MatchSetResult[]) => {
    console.log("ChangeSet not implmemented")
  }

  const onLiveScoreStateChanged = (matchResults: MatchSetResult[], playersFiledLocation: PlayersFiledLocation,
    currentPlayerServeId: number) => {

    if (!lodash.isEqual(liveScoreState?.matchResults, matchResults) &&
      !lodash.isEqual(liveScoreState?.playersLocation, playersFiledLocation))
      setLiveScoreState({
        ...liveScoreState,
        matchResults: matchResults,
        playersLocation: playersFiledLocation,
        currentPlayerServeId: currentPlayerServeId
      })
    if (hubConnection) {
      if (!isLiveScore && !props.isReadonly) {
        tournamentEventSendResult(hubConnection, matchResults, playersFiledLocation, currentPlayerServeId, Number.parseInt(matchId))
      }
    }
  }

  const tournamentEventSendResult = (hubConnection: signalR.HubConnection, matchResults: MatchSetResult[],
    playersFiledLocation: PlayersFiledLocation, currentPlayerServeId: number, matchId: number) => {
 
    const liveScoreState: LiveScoreMatchState = {
      matchResults,
      playersLocation: playersFiledLocation,
      parentMatchId: tournamentEventId,
      matchId: matchId,
      courtNumber: courtData?.courtNumber,
      courtExtId: courtData?.courtExtId,
      courtId: courtData?.courtId,
      venueId: courtData?.venueId,
      currentPlayerServeId
    }
    hubConnection.invoke("matchSendResultTournament", liveScoreState, tournamentEventId, tournamentClassId);
  }
      
  const handleNextMatch = (currentMatchId: number, currentTournamentEvent: number) => {
    let url = `${urlPath}/tournament/counter/${tournamentClassId}/${currentTournamentEvent}/${currentMatchId}/${clubId}`

    navigate(getPage(url, props.isLivescore, props.isReadonly))
    window.location.reload();
  }

  const handleChangeCourtData = (newCourtData: CourtData) => {
    if (courtData !== newCourtData) {
      setCourtData(newCourtData)
    }
  }

  const handleAdd = () => {
    tournamentEventSendResult(hubConnection, undefined, undefined, undefined, undefined)
  }

  const handleRemove = () => {
    tournamentEventSendResult(hubConnection, undefined, undefined, undefined, undefined)
  }

  const navigateToLeagueMatch = (code: string) => {
    const path = `${urlPath}/tournament/counter/${tournamentClassId}/${tournamentEventId}/${matchId}/${clubId}/${code}`

    const resultPage = getPage(path, props.isLivescore, props.isReadonly)

    navigate(resultPage);
    window.location.reload();
  };

  return (
    <React.Fragment>

      <TournamentEventsLiveScore
        tournamentClassId={tournamentClassId}
        tournamentEvents={tournamentMatch?.tournamentEvents}
        onTournamentEventsUpdate={
          (tournamentEvents: TournamentEvent[]) => {
            setTournamentEvents(tournamentEvents)
          }
        }
        onTournamentClassLiveStateUpdate={(liveState: TournamentClassLiveScoreState) => {
          setTournamentClassLiveState(liveState)
        }}
      />

      {hasToOpenDialog && (
        <LeagueMatchLivescoreCodeForm
          liveScoreCode={liveScoreCodeStr}
          onClose={() => setHasToOpenDialog(false)}
          onLiveScoreCodeSubmit={navigateToLeagueMatch}
        />
      )}

      {!hasToOpenDialog && isLiveScore && tournamentMatch?.model &&
        <MatchPointsViewer
          initMatchSetResult={processResult(tournamentMatch?.model.result) ?? []}
          matchPlayers={getMatchPlayers(tournamentMatch?.model)}
        />
      }
      {
        !hasToOpenDialog && !isLiveScore && tournamentMatch?.model &&
        <React.Fragment>
          <MatchCounter
            isUmpire={props.isUmpire}
            isReadonly={props.isReadonly}
            liveScoreState={liveScoreState}
            onStateChange={onLiveScoreStateChanged}
            matchPlayers={getMatchPlayers(tournamentMatch?.model)}
            isLoading={false} initMatchSetResult={processResult(tournamentMatch?.model.result) ?? []}
            onChange={onChangeSet}
            matchResultFromMatchCounter={() => { }}
            isLeagueMatch={false}
            isTournament={true}
          />

          <NextCounterTournament
            isReadonly={props.isReadonly}
            tournamentClassId={tournamentClassId}
            clubId={Number(clubId)}
            currentMatchId={tournamentMatch.model.matchId}
            currentTournamentEvent={tournamentEventId}
            handleNextMatch={handleNextMatch}
            handleChangeCourtData={handleChangeCourtData}
            handleRemove={handleRemove}
            handleAdd={handleAdd}
          />
        </React.Fragment>
      }
    </React.Fragment>
  )
}

interface TournamentEventMatchState {
  tournamentEvents: TournamentEvent[],
  model: UnifiedTournamentMatch,
  status: RequestStatus
}
