import { makeStyles as MuiMakeStyles } from "@mui/styles";
import { DefaultTheme } from "@mui/styles/defaultTheme";
import { Styles, WithStylesOptions } from "@mui/styles/withStyles";

const makeStyles = <Theme = DefaultTheme,
    Props extends object = {},
    ClassKey extends string = string,>(styles: Styles<Theme, Props, ClassKey>,
                                       options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,) => {
    return MuiMakeStyles(styles, options);
}

export default makeStyles;