import { HubConnection } from "@microsoft/signalr";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SignalRService } from "../../api/signalR.service";
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer";
import { CourtResponse } from "../../commons/courts/model";
import { signalRStateActions } from "../../commons/singalR/signalRStateReducer";
import { getMode, ModeType } from "../../commons/utils/ui.utils";
import { LiveScoreMatchState } from "../../pages/leagueMatch/leagueMatchSetCounter";
import { BaseProps } from "../../routes";
import { RootState } from "../../store/rootReducer";
import { MatchPlayer, MatchPlayers, MatchSideEnum, PlayerLocation } from "../matchCounter";
import { MatchPointsViewer } from "../matchPointsViewerCourt";
import * as lodash from 'lodash'
import { courtsActions } from "../../commons/courts/courtsActionReducer";
import Grid from "../muiComponents/core/Grid";

type props = BaseProps & {
  hubPath: string

}

export const CourtMatchViewer: FC<props> = (props) => {
  const { venueId, courtNumber } = useParams();
  const dispatch = useDispatch();
  const [signalRService] = useState(new SignalRService())
  const [hasConnectSignalRCourt, setHasConnectSignalRCourt] = useState(false)
  const [courtHubConnection, setCourtHubConnection] = useState<signalR.HubConnection | undefined>()
  const [matchPlayers, setMatchPlayers] = useState<MatchPlayers>()
  const venueCourtState = useSelector<RootState>(x => x.courts.venueCourt) as ReducerStateProperties<CourtResponse>
  const [liveScoreState, setLiveScoreState] = useState<LiveScoreMatchState>(undefined)
  const receiveLiveScoreResults = (liveScoreState: LiveScoreMatchState) => {
    setLiveScoreState(liveScoreState)
  }
  const isLivescoreStatePresent = () => {
    return liveScoreState?.matchId > 0
  }
  useEffect(() => {
    const IsSameVenueId = lodash.isEqual(venueCourtState.model?.venueId, Number.parseInt(venueId))
    if ((lodash.isEmpty(venueCourtState.model) && venueCourtState.status === RequestStatus.NotInitiated)
      || !IsSameVenueId && venueCourtState.status !== RequestStatus.Started) {

      if (venueCourtState.status !== RequestStatus.Error) {
        dispatch(courtsActions.getByVenueIdAndCourtNumber(Number.parseInt(venueId), Number.parseInt(courtNumber)))
      }

    }
  }, [venueCourtState])
  const signalrConnectionSetup = (hubConnection: signalR.HubConnection,
    setHubConnection: (hubConnection: HubConnection) => void,
    setHasConnection: (value: boolean) => void,
    hasConnection: boolean) => {
    if (!hasConnection) {
      hubConnection.start().then(a => {
        hubConnection.on('matchNewResults', receiveLiveScoreResults)
        if (hubConnection.connectionId) {
          hubConnection.invoke("sendConnectionId", hubConnection.connectionId,
            `${venueId}_${courtNumber}`)
            .then(() => {
              hubConnection.invoke<LiveScoreMatchState>('getMatchLastResultsByStr',
                `${venueId}_${courtNumber}`)
                .then(results => {
                  receiveLiveScoreResults(results)
                })
            });
        }
        setHubConnection(hubConnection)
        setHasConnection(true)
      })

    }
  }

  const getTeamArray = (side: MatchSideEnum) => {
    if (!liveScoreState)
      return;
    const { playersLocation } = liveScoreState
    const team: PlayerLocation[] = new Array<PlayerLocation>()
    Object.keys(playersLocation).forEach(val => {
      const playerLocation = playersLocation[val] as PlayerLocation
      if (playerLocation?.side == side && !lodash.isEmpty(playerLocation?.name)) {
        team.push(playerLocation)
      }
    })
    return team;
  }

  useEffect(() => {
    if (!isLivescoreStatePresent())
      return;
    const homeTeam = getTeamArray(MatchSideEnum.Home)
    const awayTeam = getTeamArray(MatchSideEnum.Away)
    const awayFirstPlayer = awayTeam[0]
    const awaySecondPlayer = awayTeam[1]
    const homeFirstPlayer = homeTeam[0]
    const homeSecondPlayer = homeTeam[1]
    const matchPlayers: MatchPlayers = {
      awayTeam: {
        clubId: awayFirstPlayer?.clubId, player1: MatchPlayer.createInstance(awayFirstPlayer),
        player2: MatchPlayer.createInstance(awaySecondPlayer)
      },
      homeTeam: {
        clubId: homeTeam[0]?.clubId, player1: MatchPlayer.createInstance(homeFirstPlayer),
        player2: MatchPlayer.createInstance(homeSecondPlayer)
      }
    }
    setMatchPlayers(matchPlayers)
  }, [liveScoreState])

  const initialSignalRConnection = () => {
    const hubConnection = signalRService.getConnection(props.hubPath)
    signalrConnectionSetup(hubConnection,
      setCourtHubConnection,
      setHasConnectSignalRCourt,
      hasConnectSignalRCourt
    )
  }

  useEffect(() => {
    initialSignalRConnection()
  }, [hasConnectSignalRCourt])

  const refreshSignaRFunc = () => {
    courtHubConnection.stop().then(() => {
      setCourtHubConnection(undefined)
      setHasConnectSignalRCourt(false)
    })
  }

  useEffect(() => {
    if (lodash.isEmpty(courtHubConnection?.connectionId) && hasConnectSignalRCourt) {
      initialSignalRConnection()
    }

    dispatch(signalRStateActions.setState(courtHubConnection, refreshSignaRFunc))
  }, [courtHubConnection])
  const modeState: ModeType = getMode()
  if (!liveScoreState && !venueCourtState.model)
    return (<React.Fragment></React.Fragment>)
  return (
    <React.Fragment>
      {/* Label for debugging purpose */}
      {/* <label>CONNECTION_ID {courtHubConnection?.connectionId}</label> */}
      {
        isLivescoreStatePresent() && matchPlayers &&
        <MatchPointsViewer initMatchSetResult={liveScoreState.matchResults}
          currentServerPlayerId={liveScoreState.currentPlayerServeId}
          matchPlayers={matchPlayers} />
      }
      {
        !isLivescoreStatePresent() && venueCourtState.model &&
        <div style={{ paddingTop: '0%', color: modeState === 'dark' ? 'white' : 'initial' }}>

          <Grid item xs={12}><div style={{ fontSize: '500%' }}
          >{venueCourtState.model.venueName}</div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ fontSize: '500%' }}>{venueCourtState.model.courtNumber}
            </div>
          </Grid>
        </div>
      }
    </React.Fragment>
  )
}