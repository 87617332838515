import * as React from 'react';
import { FC } from 'react';
import { loginStyles, SubComponentTypes } from './model';
import DialogTitle from "../../components/muiComponents/core/DialogTitle";
import Grid from "../../components/muiComponents/core/Grid";
import Button from "../../components/muiComponents/core/Button";

type Props = {
  nextPage: (value: number, isLivescore: boolean, isReadonly: boolean, isUmpire: boolean) => void,
};

export const CountOrLivescore: FC<Props> = props => {
  const styles = loginStyles();

  const toggleFunctions = (value: number, isLivescore: boolean, isReadonly: boolean, isUmpire: boolean) => {
    props.nextPage(value, isLivescore, isReadonly, isUmpire);
  }

  return (
    <React.Fragment>
      <DialogTitle className={styles.dialogTitle}><div className={styles.dialogTitle}>Hvad ønsker du ?</div></DialogTitle>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Button className={styles.button} onClick={() => toggleFunctions(SubComponentTypes.LeagueMatchOrTournament, false, false, false)}
            variant="contained" color="secondary">Tælle</Button>
        </Grid>
        <Grid item xs={4}>
          <Button className={styles.button} color="secondary" onClick={() => toggleFunctions(SubComponentTypes.LeagueMatchOrTournament, false, false, true)}
            variant="contained" >Dommer</Button>
        </Grid>

        <Grid item xs={4}>
          <Button className={styles.button} onClick={() => toggleFunctions(SubComponentTypes.LeagueMatchOrTournament, true, false, false)}
            variant="contained" color="secondary">Jeg vil se livescore</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
// Old button for Jeg vil tælle without login to later use
//<Grid item xs={4}>
//<Button className={styles.button} onClick={() => props.nextPage(SubComponentTypes.LoginCountId, false, true)}
//    variant="contained" color="secondary">Jeg vil tælle</Button>
// </Grid >