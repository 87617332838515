import { CourtsService } from "../../api/courts.service";
import { BaseActionReducer } from "../baseActionReducer";
import * as lodash from 'lodash';

export const courtsVenueActionReducer = new BaseActionReducer({ keyStart: 'COURTSVENUE', payloadName: 'courtsvenue' });
const courtsVenueSyncActionCreators = courtsVenueActionReducer.buildSyncActions();
export const courtsMatchesActionReducer = new BaseActionReducer({ keyStart: 'COURTSMATCHES', payloadName: 'courtsmatches' });
const courtsMatchesSyncActionCreators = courtsMatchesActionReducer.buildSyncActions();
export const courtsAssigmentActionReducer = new BaseActionReducer({ keyStart: 'COURTSASSIGMENT', payloadName: 'courtsassigment' });
const courtsAssigmentSyncActionCreators = courtsAssigmentActionReducer.buildSyncActions();
export const courtVenuetActionReducer = new BaseActionReducer({ keyStart: 'COURTVENUE', payloadName: 'courtvenue' });
const courtVenueSyncActionCreators = courtVenuetActionReducer.buildSyncActions();
export const courtsTournamentAssigmentActionReducer = new BaseActionReducer({ keyStart: 'COURTSTOURNAMENTASSIGMENT', payloadName: 'courtstournamentassigment' });
const courtsTournamentAssigmentActionCreators = courtsTournamentAssigmentActionReducer.buildSyncActions();

export const courtTournamentMatchesActionReducer = new BaseActionReducer({
  keyStart: 'COURTTOURNAMENTMATCHES', payloadName: 'courttournamentassigments'
});
const courtTournamentMatchesActionCreators = courtTournamentMatchesActionReducer.buildSyncActions();

const courtsClient = new CourtsService();

const asyncActionCreators = {
  getCourtsByIndividualMatchId: (leagueMatchIndividualMatchId: number) => {
    return courtsVenueActionReducer.createAsyncAction(
      courtsClient.getCourtsByIndividualMatchId(leagueMatchIndividualMatchId));
  },
  getMatchesForCourtByLeagueMatchId: (leagueMatchId: number) => {
    return courtsMatchesActionReducer.createAsyncAction(
      courtsClient.getMatchesForCourtByLeagueMatchId(leagueMatchId))
  },
  getCourtsByClubId: (clubId: number) => {
    return courtsVenueActionReducer.createAsyncAction(
      courtsClient.getCourtsByClubId(clubId))
  },
  assignLeagueMatchIndividualMatchToCourt: (courtId: number, leagueMatchIndividualMatchId: number, index: number) => {
    return courtsAssigmentActionReducer.createAsyncAction(
      courtsClient.assignLeagueMatchIndividualMatchToCourt(courtId, leagueMatchIndividualMatchId, index))
  },
  assignTournamentMatchToCourt: (courtId: number, tournamentId: number, tournamentEventId: number, matchId: number,
    index: number) => {
    return courtsAssigmentActionReducer.createAsyncAction(
      courtsClient.assignTournamentMatchToCourt(courtId, tournamentId, tournamentEventId, matchId, index))
  },
  removeAssigment: (leagueMatchIndividualMatchId: number) => {
    return courtsAssigmentActionReducer.createAsyncAction(
      courtsClient.removeAssigment(leagueMatchIndividualMatchId), (response) => lodash.isEmpty(response))
  },
  removeTournamentAssigment: (tournamentEventId: number, matchId: number) => {
    return courtsTournamentAssigmentActionReducer.createAsyncAction(
      courtsClient.removeTournamentAssigment(tournamentEventId, matchId),
      (response) => lodash.isEmpty(response)
    )
  },
  getCourtTournamentMatches: (clubId: number,
    tournamentId: number, matchId: number, tournamentEventId: number) => {
    return courtTournamentMatchesActionReducer.createAsyncAction(
      courtsClient.getCourtTournamentMatches(clubId, tournamentId, matchId, tournamentEventId)
    )
  },
  getCurrentCourt: (leagueMatchIndividualMatchId: number) => {
    return courtsAssigmentActionReducer.createAsyncAction(
      courtsClient.getCurrentCourt(leagueMatchIndividualMatchId),
      (response) => lodash.isEmpty(response) || !lodash.isEmpty(response)
    )
  },
  getCurrentTournamentCourt: (tournamentId: number, tournamentEventId: number, matchId: number) => {
    return courtsTournamentAssigmentActionReducer.createAsyncAction(
      courtsClient.getCurrentTournamentCourt(tournamentId, tournamentEventId, matchId)
    )
  },
  getByVenueIdAndCourtNumber: (venueId: number, courtNumber: number) => {
    return courtVenuetActionReducer.createAsyncAction(
      courtsClient.getByVenueIdAndCourtNumber(venueId, courtNumber)
    )
  }
};

export const courtsActions = {
  ...courtsVenueSyncActionCreators,
  ...courtsMatchesSyncActionCreators,
  ...courtsAssigmentSyncActionCreators,
  ...courtVenueSyncActionCreators,
  ...courtsTournamentAssigmentActionCreators,
  ...courtTournamentMatchesActionCreators,
  ...asyncActionCreators
};