import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateProperties } from '../commons/baseReducer';
import { ClubResponse } from '../commons/clubs/model';
import { RootState } from '../store/rootReducer';
import { MatchPlayers, MatchSetResult, MatchSideSet, MatchTeam } from './matchCounter';
import {default as lodash} from 'lodash';
import { clubActions } from '../commons/clubs/clubsActionReducer';
import { getMode } from '../commons/utils/ui.utils';
import { getPlayerServeStyle } from '../commons/utils/matchCounterUtils';
import Grid from "./muiComponents/core/Grid";

type props = {
    initMatchSetResult: MatchSetResult[],
    currentServerPlayerId: number,
    matchPlayers: MatchPlayers,
}

export const MatchPointsViewer: FC<props> = (props) =>{
    const dispatch = useDispatch();
    const clubState = useSelector<RootState>(state => state.clubs.all) as ReducerStateProperties<ClubResponse[]>
    const matchResults = props.initMatchSetResult?.sort((a,b) => a.setIndex - b.setIndex)
    const modeState = getMode()
    const [clubs, setClubs] = useState<ClubResponse[]>();
    const backgrounPointsDark = '#555';
    const backgroundPointsLight = '#f50057'
    const PointsDark = 'white';
    const PointsLight = 'white'
    useEffect(() => {
        if(lodash.isEmpty(clubState.errors) && lodash.isEmpty(clubState.model)
        && !(clubState.loadStarted && clubState.isLoading)){
            dispatch(clubActions.getAll())
        }
        if(!lodash.isEmpty(clubState.model) && !lodash.isEqual(clubState.model, clubs)){
            setClubs(clubState.model)
        }
    }, [clubState])
    const getClubName = (clubId: number) => {
        return clubs?.find(x => x.clubId === clubId)?.clubName
    }
    const renderPlayers = (players: MatchTeam) => {
        let player2: JSX.Element | undefined = undefined;
        if(players.player2){
            player2 = (
                <React.Fragment>
                    {players.player2.name}
                </React.Fragment>
            )
        }
        return (
            <div style={{ paddingTop: '0%', color: modeState === 'dark' ?  'white' : 'initial'}}>
                <Grid item xs={12}><div style={{ fontSize: '500%' }} className={getPlayerServeStyle(props.currentServerPlayerId, players.player1.playerId)}
                >{players.player1.name}</div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{fontSize: '500%'}} className={getPlayerServeStyle(props.currentServerPlayerId, players.player2.playerId)}>
                        {player2}
                    </div>
                </Grid>                
            </div>
        )
    }

    const renderClubs = (players: MatchTeam) => {
        let player2: JSX.Element | undefined = undefined;
        if (players.player2) {
            player2 = (
                <React.Fragment>
                    {players.player2.name}
                </React.Fragment>
            )
        }
        return (
            <div style={{ color: modeState === 'dark' ? 'white' : 'initial' }}>
            <Grid item xs={12}><div style={{ fontSize: '250%' }}
            >{getClubName(players.clubId)}</div>
            </Grid></div>
                
        )
    }

    const renderPoints = (matchResults: MatchSideSet[]) => {
        return matchResults.map(set => {
            return <Grid item xs={3} style={{ maxWidth: '24rem', minWidth: '22rem', flexBasis: 'initial', marginLeft: '1%'}}>
                    <div style={{padding: '1px 1px 1px 2rem',
                        color: modeState == 'dark' ? PointsDark : PointsDark,
                        fontSize: '16rem',
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        backgroundColor:  modeState == 'dark' ? backgrounPointsDark : backgroundPointsLight}}
                    >{set.score}</div>
                </Grid>
        })
    }
    return (
        <React.Fragment>
            <Grid container spacing={1} xs={12} style={{position:'relative'}}>
                <Grid item xs={12} style={{ display: 'inline-flex' }}>
                    <Grid item xs={5}>
                        {renderClubs(props.matchPlayers.homeTeam)}
                        {renderPlayers(props.matchPlayers.homeTeam)}
                    </Grid>
                    <Grid item xs={12} style={{ display: 'inline-flex' }}>
                        {renderPoints(matchResults.map(x => {
                            const matchSide: MatchSideSet = {
                                score: x.score1,
                                setIndex: x.setIndex
                            }
                            return matchSide;
                        }))}
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'inline-flex' }}>
                    <Grid item xs={5}>
                    {renderClubs(props.matchPlayers.awayTeam)}
                    {renderPlayers(props.matchPlayers.awayTeam)}

                </Grid>
                    <Grid item xs={12} style={{display: 'inline-flex'}}>
                        {renderPoints(matchResults.map(x => {
                            const matchSide: MatchSideSet = {
                                score: x.score2,
                                setIndex: x.setIndex
                            }
                            return matchSide;
                        }))}
                    </Grid> 
                </Grid>
            </Grid>
        </React.Fragment>
    )
}