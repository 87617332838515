import { combineReducers } from "redux";
import { ReduxCompatibleReducer } from "redux-actions";
import { ageGroupActionReducer } from "../commons/ageGroup/ageGroupActionReducer";
import { AgeGroupApiViewModel } from "../commons/ageGroup/model";
import { ReducerStateProperties } from "../commons/baseReducer";
import { classGroupActionReducer } from "../commons/classGroup/classGroupActionReducer";
import { ClassGroupApiVIewModel } from "../commons/classGroup/model";
import { clubsActionReducer } from "../commons/clubs/clubsActionReducer";
import { ClubResponse } from "../commons/clubs/model";
import { geoRegionActionReducer } from "../commons/geoRegion/geoRegionActionReducer";
import { GeoRegionApiVIewModel } from "../commons/geoRegion/model";
import { homeClubIdLeagueMatchActionReducer, leagueMatchActionReducer, leagueMatchesActionReducer } from "../commons/leagueMatch/leagueMatchReducer";
import { LeagueMatchAggregateViewModel, LeagueMatchResponse } from "../commons/leagueMatch/model";
import { IRedirect, redirectActionReducer } from "../commons/redirectReducer";
import { RegionResponse } from "../commons/regions/model";
import { regionActionReducer } from "../commons/regions/regionActionReducer";
import { responseHandlerActionReducer } from "../commons/responseHandler/responseHandlerActionReducer";
import { navigationActionReducer, NavigationState } from "../commons/navigationActionReducer";
import { AgeGroupClass, GetTournamentApplicationsAdmin, Tournament, TournamentEvent, TournamentEventMatch } from "../commons/tournament/model";
import { tournamentActionReducer, tournamentEventsActionReducer, tournamentsActionReducer } from "../commons/tournament/tournamentActionReducer";
import { tournamentEventMatchActionReducer } from "../commons/tournamentEventMatch/tournamentEventMatchReducer";
import { ResponseHandlerState } from "../components/responseHandler/ResponseHandler";
import { ageGroupClassActionReducer } from "../commons/ageGroupClass/ageGroupClassReducer";
import { uiModeActionReducer } from "../commons/uiModeActionReducer";
import { signalRHubActionReducer, SignalRState } from "../commons/singalR/signalRStateReducer";
import { courtsAssigmentActionReducer, courtsMatchesActionReducer, courtsTournamentAssigmentActionReducer, courtsVenueActionReducer, courtTournamentMatchesActionReducer, courtVenuetActionReducer } from "../commons/courts/courtsActionReducer";
import { BulkCourtResponse, CourtLeagueMatchIndividualMatchResponse, CourtMatchesResponse, CourtResponse, CourtTournamentEventMatchResponse, VenueCourtsResponse } from "../commons/courts/model";
import { tournamentClassActionReducer } from "../commons/tournamentClass/tournamentClassActionReducer";
import { TournamentClassResponse } from "../commons/tournamentClass/model";
import { tournamentLiveActionReducer } from "../commons/tournamentLiveActionReducer";
import { blockMatchActionReducer, blockMatchesActionReducer } from "../commons/blockMatch/blockMatchActionReducer";
import { BlockMatchModel } from "../commons/blockMatch/model";

export const rootReducer = combineReducers({
    redirect: redirectActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<IRedirect>, unknown>,
    responsehandler: responseHandlerActionReducer.buildReducer(false) as ReduxCompatibleReducer<ReducerStateProperties<ResponseHandlerState>, unknown>,
    leagueMatch: combineReducers({
        leagueMatch: leagueMatchActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<LeagueMatchAggregateViewModel>, unknown>,
        leagueMatches: leagueMatchesActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<LeagueMatchResponse[]>, unknown>,
        homeClubId: homeClubIdLeagueMatchActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<number>, unknown>,
    }),
    clubs: combineReducers({
        all: clubsActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<ClubResponse[]>, unknown>
    }),
    regions: regionActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<RegionResponse[]>, unknown>,
    ageGroups: ageGroupActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<AgeGroupApiViewModel[]>, unknown>,
    ageGroupClasses: ageGroupClassActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<AgeGroupClass[]>, unknown>,
    classGroups: classGroupActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<ClassGroupApiVIewModel[]>, unknown>,
    geoRegions: geoRegionActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<GeoRegionApiVIewModel[]>, unknown>,
    tournaments: combineReducers({
        tournamentClass: tournamentClassActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<TournamentClassResponse>, unknown>,
        tournaments: tournamentsActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<GetTournamentApplicationsAdmin>, unknown>,
        tournament: tournamentActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<Tournament>, unknown>,
        tournamentEvents: tournamentEventsActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<TournamentEvent[]>, unknown>,
        tournamentEventMatch: tournamentEventMatchActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<TournamentEventMatch>, unknown>,
        tournamentLive: tournamentLiveActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<TournamentEvent[]>, unknown>
    }),
    navigation: navigationActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<NavigationState>, unknown>,
    mode: uiModeActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<'light' | 'dark'>, unknown>,
    signalRState: signalRHubActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<SignalRState>, unknown>,
    courts: combineReducers({
        venueCourt: courtVenuetActionReducer.buildReducer() as  ReduxCompatibleReducer<ReducerStateProperties<CourtResponse>, unknown>,
        venueCourts: courtsVenueActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<BulkCourtResponse>, unknown>,
        courtsMatches: courtsMatchesActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<CourtMatchesResponse[]>, unknown>,
        courtAssigment: courtsAssigmentActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<CourtLeagueMatchIndividualMatchResponse>, unknown>,
        courtTournamentAssigment: courtsTournamentAssigmentActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<CourtTournamentEventMatchResponse>, unknown>,
        courtsTournamentMatches: courtTournamentMatchesActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<CourtTournamentEventMatchResponse[]>, unknown>,
    }),

    blockMatch: combineReducers({
      blockMatch : blockMatchActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<BlockMatchModel>, unknown>,
      all: blockMatchesActionReducer.buildReducer() as ReduxCompatibleReducer<ReducerStateProperties<BlockMatchModel[]>, unknown>
    })
});

export type RootState = ReturnType<typeof rootReducer>;



export default rootReducer;