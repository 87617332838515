import { LeagueMatchService } from "../../api/leagueMatch.service";
import { BaseActionReducer } from "../baseActionReducer";
import { LeagueMatchAggregateViewModel } from "./model";

export const leagueMatchActionReducer = new BaseActionReducer({ keyStart: 'LEAGUE_MATCH', payloadName: 'match' });
export const leagueMatchesActionReducer = new BaseActionReducer({ keyStart: 'LEAGUE_MATCHES', payloadName: 'matches' });
export const homeClubIdLeagueMatchActionReducer = new BaseActionReducer({ keyStart: 'HOME_CLUBID_LEAGUEMATCH', payloadName: 'homeclubidleaguematch' });
const syncActionCreators = leagueMatchActionReducer.buildSyncActions();
const syncMatchesActionCreators = leagueMatchesActionReducer.buildSyncActions();
const syncHomeClubIdActionCreators = homeClubIdLeagueMatchActionReducer.buildSyncActions();
const leagueMatchClient = new LeagueMatchService();

const asyncActionCreators = {
    getLeagueMatch: (leagueMatchId: number, password?: string, regionId?: number, isLivescore?: boolean) => {
        return leagueMatchActionReducer.createAsyncAction(
            leagueMatchClient.getLeagueMatch(leagueMatchId, password ? password : '', regionId, isLivescore));
    },
    updateLeagueMatch : (leagueMatchId: number, leagueMatch: LeagueMatchAggregateViewModel, password?: string,
        regionId?: number) => {
            return leagueMatchActionReducer.createAsyncAction(
                leagueMatchClient.updateLeagueMatch(leagueMatchId, leagueMatch, password, regionId)
            )
    },
    getByParams: (clubId?: number, regionId?: number, leagueMatchId?: string, startDate?: Date, endDate?: Date, ageGroupId?: number) => {
        const lgmNum = leagueMatchId ? new Number(leagueMatchId).valueOf() : undefined;
        return leagueMatchesActionReducer.createAsyncAction(
            leagueMatchClient.getByParameters(clubId, regionId,lgmNum, startDate, endDate, ageGroupId)
        )
    },
    getHomeClubId: (leagueMatchId: number) => {
        return homeClubIdLeagueMatchActionReducer.createAsyncAction(
            leagueMatchClient.getHomeClubIdByLeagueMatch(leagueMatchId)
        )
    }
};

export const leagueMatchActions = {
    ...syncActionCreators,
    ...syncMatchesActionCreators,
    ...syncHomeClubIdActionCreators,
    ...asyncActionCreators
};