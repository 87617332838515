import React, { FC, useEffect, useState } from "react"
import { TournamentClassLiveScoreState, TournamentEvent } from "../../commons/tournament/model"
import { TournamentEventRowId } from "./tournamentEvent.table";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer";
import { tournamentLiveActions } from "../../commons/tournamentLiveActionReducer";
import * as lodash from 'lodash'
import { getLiveTournamentEvents } from "../../commons/utils/tournament.utils";
import { TournamentEventDetails } from "./tournamentEventDetails";

type props = {
    onRowSelect: (event: TournamentEventRowId) => void,
    showOnlyEmpty: boolean,
    tournamentClassLiveState: TournamentClassLiveScoreState,
    tournamentClassId: number
}

export const TournamentEventDetailsLive: FC<props> = (props) => {
    const tournamentLiveSelector = useSelector<RootState>(state => state.tournaments.tournamentLive) as ReducerStateProperties<TournamentEvent[]>
    const [tournamentEventsLive, setTournamentEventsLive] = useState<TournamentEvent[]>()
    const dispatch = useDispatch()
    const onTournamentSelectorChanged = () => {
        const {model, status} = tournamentLiveSelector
        const propstournamentEventIds = Object.keys(props.tournamentClassLiveState)?.map(x => Number.parseInt(x))
        const currentTournamentEventIds = tournamentEventsLive?.map(x => x.tournamentEventId)
        if(status !== RequestStatus.Error
            && !lodash.isEqual(propstournamentEventIds, currentTournamentEventIds)) {
            dispatch(tournamentLiveActions.get(props.tournamentClassId, propstournamentEventIds))
        }
        if(!lodash.isEmpty(model) && !lodash.isEqual(model, tournamentEventsLive)){
            const state = getLiveTournamentEvents(props?.tournamentClassLiveState, model)
            if(!state){
                return
            }
            if(state.hasBeenChanged){
                const liveModel = state.result.map(m => {
                    m.unifiedTournamentMatches = m.unifiedTournamentMatches.filter(u => u.liveScore)
                    return m;
                })
                setTournamentEventsLive(liveModel)
            }            
        }
    }
    useEffect(onTournamentSelectorChanged, [props.tournamentClassLiveState, tournamentLiveSelector])
    return(<React.Fragment>
        {tournamentEventsLive &&
        <TournamentEventDetails showOnlyEmpty={props.showOnlyEmpty} 
        onRowSelect={props.onRowSelect} tournamentEvents={tournamentEventsLive}
    />}
    </React.Fragment>)
}