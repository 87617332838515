import { handleActions, Action, ReduxCompatibleReducer } from 'redux-actions';
import { generateKeyNames, IKeyStartAndPayloadName } from './keyAndPayloadName';
import { ErrorResponse } from './models/ErrorModel';

export enum RequestStatus {
    NotInitiated,
    Started,
    Ok,
    Error
}

export interface ReducerStateProperties<TModel> {
    model: TModel,
    isLoading: boolean,
    loadStarted: boolean,
    errors: Error | ErrorResponse;
    status: RequestStatus
}

export class BaseReducer<TModel> {
    initialState: ReducerStateProperties<TModel>;

    keyStartAndPayloadName: IKeyStartAndPayloadName;
    loadByDefault: boolean;

    constructor(keyStartAndPayloadName: IKeyStartAndPayloadName, loadByDefault = true) {
        this.buildReducer = this.buildReducer.bind(this);
        this.keyStartAndPayloadName = keyStartAndPayloadName;
        this.loadByDefault = loadByDefault;
        this.initialState = { model: null, isLoading: loadByDefault, errors: null, loadStarted: false, status: RequestStatus.NotInitiated };
    }

    buildReducer(): ReduxCompatibleReducer<ReducerStateProperties<TModel>, TModel> {
        const self = this;
        type State = typeof self.initialState;

        const keyNames = generateKeyNames(this.keyStartAndPayloadName);

        return handleActions<State, TModel>({  
            [keyNames.START]: (state: State) => 
            Object.assign({},
                state,
                {
                    ...state,
                    isLoading: true,
                    status: RequestStatus.Started,
                    loadStarted: true
                }),
            [keyNames.SUCCESS]: (state: State, action: Action<TModel>) =>
                Object.assign({},
                    state,
                    {
                        ...state,
                        isLoading: false,
                        errors: undefined,
                        status: RequestStatus.Ok,
                        [this.keyStartAndPayloadName.payloadName]: action.payload,
                        model: action.payload
                    }),
            [keyNames.FAILURE]: (state: State, action: Action<TModel>) => Object.assign({},
                state,
                {
                    ...state,
                    isLoading: false,
                    status: RequestStatus.Error,
                    errors: action.payload
                }),
            [keyNames.RESET]: (state: State) =>
                Object.assign({},
                    state,
                    {
                        ...state,
                        [this.keyStartAndPayloadName.payloadName]: undefined,
                        ...this.initialState
                    }),
        },
        self.initialState);
    }
}