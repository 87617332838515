
import * as React from 'react';
import { FC, useState } from 'react';
import { SelectItem } from '../../common.types';


import makeStyles from "../../../components/muiComponents/core/makeStyles";
import Popper from "../../../components/muiComponents/core/Popper";
import FormControl from "../../../components/muiComponents/core/FormControl";
import Autocomplete from "../../../components/muiComponents/core/Autocomplete";
import { FormHelperText } from "@mui/material";
import TextField from "../../../components/muiComponents/core/TextField";

type Props = {
label: string,
data: SelectItem[],
helperText?: string,
handleChange: (event: any) => void,
disabled?: boolean,
defaultValue?: SelectItem,
multiple?: boolean
};

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const styles = () =>{
    return {
        popper: {
            width: "fit-content"
        }
    }
}

const PopperMy = function (props: any) {
    return <Popper {...props} style={styles().popper} placement="bottom-start" />;
 };

export const SportResultsDropdown: FC<Props> = props => {
    const classes = useStyles();
    // TODO: check if that state can be removed
    const [selection, setSelection] = useState<SelectItem>(null)
    const handleChange = (option: SelectItem) => {
        setSelection(option ? option.value : null);
        props.handleChange(option);
      };
    const controlId = `sportresults-dropdown__${props.label}`;
    return (
        <FormControl disabled={props.disabled} className={classes.formControl} style={{color: 'white'}}>
                    <Autocomplete
                    noOptionsText={"Mangel på værdier"}
                    multiple={props.multiple}
                    defaultValue={props.defaultValue}
                    disabled={props.disabled}
                    PopperComponent = {PopperMy}
                    fullWidth={true}
                    id={controlId}
                    isOptionEqualToValue={(option: SelectItem,value: SelectItem) => option.value === value.value}
                    options={props.data}
                    color={'white'}
                    getOptionLabel={(option: SelectItem) => option.name}
                    onChange={(event: any, option: any) => handleChange(option)}
                    renderInput={(params) => <TextField {...params} label={props.label} />}
                    />
                { props.helperText && <FormHelperText>{ props.helperText }</FormHelperText> }
        </FormControl>  
    )
}