
import { TournamentClassService } from '../../api/tournamentClass.service';
import { BaseActionReducer } from '../baseActionReducer';

export const tournamentClassActionReducer = new BaseActionReducer({ keyStart: 'TOURNAMENTCLASSES', payloadName: 'model' });
const syncTournamentClassActions = tournamentClassActionReducer.buildSyncActions();
const tournamentClassClient = new TournamentClassService();

const asyncTournamentClassCreators = {
    get: (tournamentClassId: number) => {
        return tournamentClassActionReducer.createAsyncAction(
            tournamentClassClient.getRealtedWithTournament(tournamentClassId)
        );
    }
};

export const tournamentClassActions = {
    ...syncTournamentClassActions,
    ...asyncTournamentClassCreators,
};