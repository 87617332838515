import axios from "axios";
import { ClubResponse } from "../commons/clubs/model";
import { RegionResponse } from "../commons/regions/model";
import { BaseService } from "./base.service";

export class RegionsService extends BaseService {
    constructor(){
        super("Region");
    }

    public getAll = (): Promise<RegionResponse[]> => {
        const url = `${this.baseUrl}/regions/invitationer`;
        return axios.get<RegionResponse[]>(url).then(res => res.data);
    }
}