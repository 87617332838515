import { AgeGroupService } from "../../api/ageGroup.service";
import { AgeGroupClassService } from "../../api/ageGroupClassService";
import { ClubsService } from "../../api/clubs.service";
import { BaseActionReducer } from "../baseActionReducer";

export const ageGroupClassActionReducer = new BaseActionReducer({ keyStart: 'AGEGROUP_CLASS', payloadName: 'agegroupclass' });
const syncActionCreators = ageGroupClassActionReducer.buildSyncActions();

const ageGroupClassClient = new AgeGroupClassService();

const asyncActionCreators = {
    getAll: () => {
        return ageGroupClassActionReducer.createAsyncAction(
            ageGroupClassClient.getAll());
    },
};

export const ageGroupClassActions = {
    ...syncActionCreators,
    ...asyncActionCreators
};