import Moment from 'react-moment';
import moment, * as momentjs from 'moment';
import React from 'react';

export const jsonParse = (value: string) =>{
    try{
        return JSON.parse(value);
    }
    catch{
        return null;
    }
}

export const getMomentDate = (date: Date) => {
    return <React.Fragment>
        <Moment format="DD-MM-YYYY HH:mm">{date}</Moment>
    </React.Fragment>
}
export const getMomentDateOutTime = (date: Date) => {
    return <React.Fragment>
        <Moment format="DD-MM-YYYY">{date}</Moment>
    </React.Fragment>
}
export const getLabelDate = (date: Date): string => {
    return moment(date).format("DD-MM-YYYY");
}


// javascript converts date to local time by default by only assigning timezone even from response
// swagger converts the date into UTC by time offset
// method gets utc from that date, save as local time with the same time zone offset. 
// this prevents from unwanted changes.
export const getUctDateAsLocal = (date: Date): Date => {
    const utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
    date.getHours(), date.getMinutes(), date.getSeconds(),
    date.getMilliseconds());
    return new Date(utc);
}