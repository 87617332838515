import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer";
import { LeagueMatchAggregateViewModel, LeagueMatchSet } from "../../commons/leagueMatch/model";
import { RootState } from "../../store/rootReducer";
import { default as lodash } from 'lodash';
import { leagueMatchActions } from "../../commons/leagueMatch/leagueMatchReducer";
import { LeagueMatchLineupTable } from "../../components/leagueMatch/leagueMatchLineup.table";
import { NavigationState, titleActions } from "../../commons/navigationActionReducer";
import { BaseProps } from '../../routes'
import { getPage } from "../../commons/utils/ui.utils";
import { SignalRService } from "../../api/signalR.service";
import { signalRStateActions } from "../../commons/singalR/signalRStateReducer";
import { isPageLiveScore } from "../../commons/utils/businessUtils";
import { responseHandlerActions } from "../../commons/responseHandler/responseHandlerActionReducer";
import { BlockMatchModel } from "../../commons/blockMatch/model";
import BlockMatchDialogWhenMatchIsInUse from "../../components/blockMatch/blockMatchDialogWhenMatchIsInUse";
import { BlockMatchService } from "../../api/blockMatch.service";

type props = BaseProps & {

}

export const LeagueMatchLineup: FC<props> = (props) => {
  const blockMatchClient = new BlockMatchService();

  const urlPath = props.isUmpire ? "umpire" : "count";
  const { leagueMatchIdStr, password } = useParams();
  const [isLiveScore] = useState<boolean>(isPageLiveScore())
  const navigate = useNavigate();
  const [individualMatchId, setIndividualMatchId] = useState<number>(null);
  const [leagueMatchResult, setLeagueMatchResult] = useState<MatchScoreResult | undefined>(undefined)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [hasConnectSignalR, setHasConnectSignalR] = useState(false)
  const [hubConnection, setHubConnection] = useState<signalR.HubConnection | undefined>()
  const [signalRService] = useState(new SignalRService())
  const [isMatchBlock, setIsMatchBlock] = useState<boolean>(false)
  const dispatch = useDispatch();

  let leagueMatchId: number;
  const [liveScoreState, setLiveScoreState] = useState<LiveScoreLeagueMatch>()
  if (leagueMatchIdStr) {
    leagueMatchId = new Number(leagueMatchIdStr).valueOf();
  }
  const matchSelector = useSelector<RootState>((state) => state.leagueMatch.leagueMatch) as ReducerStateProperties<LeagueMatchAggregateViewModel>;
  const [match, setMatch] = useState(matchSelector)

  const isLeagueMatchIdConflict = () => {
    return !lodash.isEqual(leagueMatchId, match?.model?.match?.leagueMatchId)
  }

  useEffect(() => {
    fetchBlockMatchGetAll();
  }, [])

  useEffect(() => {
    if (!hasConnectSignalR && !lodash.isEmpty(match.model) && !hasConnectSignalR) {
      const hubConnection = signalRService.getConnection('leaguematch')

      setHubConnection(hubConnection)
      setHasConnectSignalR(true)
      hubConnection.start().then(a => {
        if (hubConnection.connectionId) {
          hubConnection.invoke("sendConnectionId", hubConnection.connectionId, `${leagueMatchId}`)
            .then(() => {
              hubConnection.invoke<any>('getParentMatchLastResults', leagueMatchId.toString())
                .then(results => {
                  receiveResults(results)
                })
              hubConnection.on('LeagueMatchNewResults', receiveResults)
            })
          hubConnection.invoke("sendConnectionId", hubConnection.connectionId, "leagueMatchesOnLive")
            .then(() => {
              hubConnection.on("getleagueMatchesOnLive", (state: any) => {
                receiveLeagueMatchResult(state, leagueMatchId)
              })
            })
        }
      }).finally(() => {
        const refreshFunc = () => {
          hubConnection.stop().then(() => {
            setHubConnection(undefined)
            setHasConnectSignalR(false)
          })
        }
        dispatch(signalRStateActions.setState(hubConnection, refreshFunc))
      })
    }
  }, [hasConnectSignalR, match.model])

  useEffect(() => {
    let numberOfTheSameSets = 0
    if (liveScoreState?.individualMatches && match?.model?.combinedTeamMatches) {
      let newMatchState: ReducerStateProperties<LeagueMatchAggregateViewModel>;
      match.model.combinedTeamMatches.forEach(combinedMatch => {
        const newResults = liveScoreState.individualMatches.find(im => im.leagueMatchIndividualMatchId == combinedMatch.leagueMatchIndividualMatchId)
        const leagueMatchSets = newResults.leagueMatchSets.map((x) => {
          const oldSet = combinedMatch.leagueMatchSet.find(lm => lm.setIndex === x.setIndex)
          if (x.score1 === oldSet?.points1 && x?.score2 === oldSet?.points2) {
            numberOfTheSameSets += 1;
          }
          let matchSet: LeagueMatchSet = {
            leagueMatchIndividualMatchId: newResults.leagueMatchIndividualMatchId,
            setIndex: x.setIndex,
            points1: x.score1,
            points2: x.score2,
            teamIndex1: 0,
            teamIndex2: 1
          }
          return matchSet
        })
        combinedMatch.leagueMatchSet = leagueMatchSets
        combinedMatch.currentPlayerServeId = newResults?.currentPlayerServeId
      })
      newMatchState = Object.assign({}, match)
      const numberOfSets = match.model.combinedTeamMatches.map(x => x.leagueMatchSet).reduce((a, b) => a.concat(b)).length
      if (numberOfSets !== numberOfTheSameSets) {
        setMatch({
          ...match,
        })
      }
    }
  }, [liveScoreState, match])

  useEffect(() => {
    if (isSubmitted && matchSelector.status == RequestStatus.Ok) {
      dispatch(leagueMatchActions.getLeagueMatch(match.model.match.leagueMatchId, password, null, isLiveScore))
      setIsSubmitted(false);
    }

  }, [isSubmitted, matchSelector])

  useEffect(() => {
    if (((lodash.isEmpty(matchSelector.model)) || isLeagueMatchIdConflict())
      && (matchSelector.status !== RequestStatus.Started && matchSelector.status !== RequestStatus.Error)) {
      dispatch(leagueMatchActions.getLeagueMatch(leagueMatchId, password, null, isLiveScore))
      setMatch({
        ...match
      })
      return;
    }
    if (!lodash.isEmpty(matchSelector.model) && !matchSelector.model?.settings?.isPublicVisible) {
      dispatch(responseHandlerActions.call({ warningMsg: 'Liga kampopstilling er ikke tilgængelig' }))
      return;
    }
    if (matchSelector.status === RequestStatus.Error) {
      const errorState = matchSelector.errors as any
      dispatch(responseHandlerActions.call({ error: { appError: errorState.errors } }))
      return;
    }
    if (!(lodash.isEmpty(matchSelector.model) && lodash.isEqual(match, matchSelector))) {
      setMatch(matchSelector)
    }
  }, [matchSelector])

  const fetchBlockMatchGetAll = async (): Promise<BlockMatchModel[]> => {
    try {
      const response = await blockMatchClient.getAllBlockMatch();
      return response;
    }
    catch (error) {
      return [];
    }
  }

  const receiveResults = (results: LiveScoreLeagueMatch) => {
    setLiveScoreState(results);
  }

  const receiveLeagueMatchResult = (leagueMatchResults: LeagueMatchResults, leagueMatchId: number) => {
    const leagueMatchResult = leagueMatchResults[leagueMatchId]
    if (leagueMatchResult) {
      setLeagueMatchResult({
        point1: leagueMatchResult?.point1,
        point2: leagueMatchResult?.point2
      })
    }
  }

  const checkIfMatchIsBlocked = (leagueMatchIndividualId: number, blockMatchesGetAllResponse: BlockMatchModel[]) => {
    let matchBlocked = false;
    blockMatchesGetAllResponse?.forEach(bm => {
      if (bm.parentMatchId === leagueMatchId && bm.matchId === leagueMatchIndividualId) {
        matchBlocked = true;
        return;
      }
    });

    return matchBlocked
  }

  const onLeagueMatchSetChanged = async (leagueMatchIndividualId: number) => {
    const blockMatchesGetAllResponse = await fetchBlockMatchGetAll()

    const matchBlocked = checkIfMatchIsBlocked(leagueMatchIndividualId, blockMatchesGetAllResponse);

    if (matchBlocked) {
      setIsMatchBlock(true);
    }
    else {
      setIsMatchBlock(false);

      let url = `${urlPath}/leagueMatch/counter/${leagueMatchId}/${leagueMatchIndividualId}`
      if (!isLiveScore) {
        url += `/${password}`
      }

      navigate(getPage(url, props.isLivescore, props.isReadonly))
    }
  }

  const titleState = useSelector<RootState>(state => state.navigation.model) as NavigationState

  useEffect(() => {
    const title = 'Vælg en kamp';
    const isEmptyMatchId = lodash.isEqual(individualMatchId, null)
    if (isEmptyMatchId && lodash.isEmpty(titleState?.backwardAction)) {
      dispatch(titleActions.setNavigation(title, undefined, titleState?.forwardAction))
    }
  }, [individualMatchId])

  const generateLineup = (match: LeagueMatchAggregateViewModel) => {
    return (
      <React.Fragment>

        {isMatchBlock && (
          <BlockMatchDialogWhenMatchIsInUse
            isMatchBlock={isMatchBlock}
            setIsMatchBlock={setIsMatchBlock}
          />
        )}

        {!individualMatchId && !isLeagueMatchIdConflict() && matchSelector.model?.settings?.isPublicVisible &&
          <LeagueMatchLineupTable
            homePoints={leagueMatchResult?.point1 ?? match.match.Score1}
            awayPoints={leagueMatchResult?.point2 ?? match.match.Score2}
            team1Name={match.match.teamName1}
            team2Name={match.match.teamName2}
            maxSets={match.match.maxSets}
            leagueGroupTeamId1={match.match.leagueGroupTeamId1}
            leagueGroupTeamId2={match.match.leagueGroupTeamId2}
            combinedTeamMatches={match.combinedTeamMatches}
            onLeagueMatchSetChange={onLeagueMatchSetChanged} />
        }
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      {match?.model?.combinedTeamMatches && generateLineup(match.model,)}
    </React.Fragment>
  );
}

type ComputedTeamPlayer = {
  playerId: number,
  playerNumber: string,
  playerName: string,
  playerGender: string,
  selectPlayerType: number,
  points: number
};

type ComputedSetPoints = {
  points1: number,
  points2: number
}


type ComputedTeam = {
  teamIndex: number,
  players: ComputedTeamPlayer[],
  teamId: number,
  name: string,
  shortName: string,
  points: number,
  moveDirectionToBeValid?: string,
  showWarning?: string
};

export type ComputedMatchRow = {
  matchIndex: number,
  matchId: number,
  disciplineCode: string,
  disciplineRanking: number,
  setPoints: ComputedSetPoints[],
  winnerWalkOver?: number,
  teams: ComputedTeam[],
  isDoubleMatch: boolean,
  teamId?: number,
  isGoldenSet: boolean,
  selectPlayerType: SelectPlayerTypeEnum
}

export enum SelectPlayerTypeEnum {
  Search = 0,
  PreviousMatches = 1,
  NoPlayer = 2,
  Substitute = 3,
  PlayerTroopMen = 4,
  PlayerTroopWomen = 5,
}

export interface LiveScoreLeagueMatch {
  leagueMatchId: number;
  individualMatches: LiveScoreLeagueMatchIndividualMatch[]
}

export interface MatchScoreResult {
  point1: number,
  point2: number
}

export type LeagueMatchResults = { [key: number]: MatchScoreResult }

export interface LiveScoreLeagueMatchIndividualMatch {
  leagueMatchIndividualMatchId: number;
  currentPlayerServeId?: number;
  leagueMatchSets: LiveScoreLeagueMatchSet[]
}

export interface LiveScoreLeagueMatchSet {
  setIndex: number;
  score1: number;
  score2: number;
}