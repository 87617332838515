import React, { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer";
import { RootState } from "../../store/rootReducer";
import { ClubDropdown } from "../../components/clubs/club.dropdown";
import { RegionDropdown } from "../../components/regions/region.dropdown";
import { DropdownResourceType, SelectItem } from "../../commons/common.types";
import { LeagueMatchTable } from "../../components/leagueMatch/leagueMatch.table";
import { leagueMatchActions } from "../../commons/leagueMatch/leagueMatchReducer";
import { LeagueMatchResponse } from "../../commons/leagueMatch/model"
import { default as lodash } from 'lodash';
import { responseHandlerActions } from "../../commons/responseHandler/responseHandlerActionReducer";
import { FormControl } from "../../commons/forms";
import { FormValidationError } from "../../commons/formValidationError";
import moment from "moment";
import { AgeGroupDropdown } from "../../components/ageGroup/ageGroupDropdown";
import { titleActions } from "../../commons/navigationActionReducer";
import { BaseProps } from "../../routes";
import makeStyles from "../../components/muiComponents/core/makeStyles";
import { Theme } from "@mui/material";
import Grid from "../../components/muiComponents/core/Grid";
import InputLabel from "../../components/muiComponents/core/InputLabel";
import TextField from "../../components/muiComponents/core/TextField";
import Skeleton from "../../components/muiComponents/core/Skeleton";
import Button from "../../components/muiComponents/core/Button";
import SearchIcon from "@mui/icons-material/Search";

type props = BaseProps & {

}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  }
}));


export const LeagueMatchSearch: FC<props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isInit, setIsInit] = useState<boolean>(true);
  useEffect(() => {
    if (isInit) {
      dispatch(titleActions.setNavigation('Vælg en holdkamp'))
      setIsInit(false);
    }
  }, [isInit])

  const [searchParameters, setSearchParameters] = useState<SearchParameters>({
    startDate: { touched: false, value: new Date() },
    endDate: null, leagueMatchId: null, selectedAgeGroup: null, selectedClub: null, selectedRegion: null
  });
  const leagueMatchesState = useSelector<RootState>((state) => state.leagueMatch.leagueMatches) as ReducerStateProperties<LeagueMatchResponse[]>
  const isfilterDataLoading = useSelector<RootState>((state) => {
    const { ageGroups, regions, clubs } = state;
    return (ageGroups.isLoading && ageGroups.loadStarted)
      || (regions.isLoading && regions.loadStarted)
      || (clubs.all.isLoading && clubs.all.loadStarted)
  }) as boolean
  const [submitted, setSubmitted] = useState<boolean>();
  const handleChangeDropdowns = (event: SelectItem, resource: DropdownResourceType) => {
    switch (resource) {
      case DropdownResourceType.Club:
        setSearchParameters({
          ...searchParameters,
          selectedClub: event
        });
        break;
      case DropdownResourceType.Region:
        setSearchParameters({
          ...searchParameters,
          selectedRegion: event
        });
        break;
      case DropdownResourceType.AgeGroup:
        setSearchParameters({
          ...searchParameters,
          selectedAgeGroup: event
        })
      default:
        break;
    }
  }
  const handleSearch = () => {
    dispatch(
      leagueMatchActions
        .getByParams(searchParameters?.selectedClub?.value,
          searchParameters?.selectedRegion?.value,
          searchParameters?.leagueMatchId?.value,
          searchParameters?.startDate?.value,
          searchParameters?.endDate?.value,
          searchParameters?.selectedAgeGroup?.value
        )
    )
    setSubmitted(true);
  }

  useEffect(() => {
    if (lodash.isEmpty(leagueMatchesState.model) && leagueMatchesState.status === RequestStatus.Ok && submitted) {
      dispatch(responseHandlerActions.call({ success: true, warningMsg: 'Manglende resultater for disse parametre, som har sat livescore-koden' }))
      setSubmitted(false);
    }
  }, [leagueMatchesState])
  const leagueMatchIdValidate = (val: string) => {
    const numVal = new Number(val)
    return !lodash.isNaN(numVal);
  }
  const onFieldChange = (name: string, value: any) => {
    const formControl: FormControl = {
      value,
      touched: true
    }
    setSearchParameters({
      ...searchParameters,
      [name]: formControl
    })
  }
  const isLoadingMatches = (leagueMatchesState.isLoading && leagueMatchesState.loadStarted)
  return (
    <React.Fragment>
      <Grid container spacing={3} xs={6} md={6} xl={6}>
        <Grid item xs={12} md={12} xl={12}>
          <InputLabel style={{ marginBottom: '2%' }}>Søg via kampnr</InputLabel>
          {!isfilterDataLoading && <TextField
            color='secondary'
            name="leagueMatchId"
            variant="outlined"
            type="number"
            InputLabelProps={{ shrink: true }}
            label={"Kampnr"}
            autoComplete="off"
            required={true}
            onChange={(props) =>
              setSearchParameters(
                { ...searchParameters, leagueMatchId: { touched: true, value: props.currentTarget.value } }
              )
            }
            value={searchParameters?.leagueMatchId?.value}
          />}
          {isfilterDataLoading && <Skeleton variant='rectangular' />}
          {searchParameters?.leagueMatchId?.touched && !leagueMatchIdValidate(searchParameters?.leagueMatchId?.value)
            && <FormValidationError message={'Kampnr identitet skal være numerisk'} />}
        </Grid>

        <Grid item xs={12} md={12} xl={12}>
          <InputLabel>Søg via klub og/eller Kreds/Landsdel</InputLabel>
          <ClubDropdown
            handleChange={(event) => handleChangeDropdowns(event, DropdownResourceType.Club)} />
          <RegionDropdown
            handleChange={(event) => handleChangeDropdowns(event, DropdownResourceType.Region)} />
          <AgeGroupDropdown
            handleChange={(event) => handleChangeDropdowns(event, DropdownResourceType.AgeGroup)} />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {!isfilterDataLoading && <TextField name="startDate" value={moment(searchParameters?.startDate?.value).format("YYYY-MM-DD")} label="Dato fra"
            type="date" InputLabelProps={{ shrink: true }} autoComplete="off" fullWidth onChange={(props) => onFieldChange(
              props.currentTarget.name, props.currentTarget.value)} />}
          {isfilterDataLoading && <Skeleton variant='rectangular' />}
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {!isfilterDataLoading && <TextField name="endDate" value={searchParameters?.endDate?.value || ""} label="Dato til" type="date" InputLabelProps={{ shrink: true }} autoComplete="off"
            fullWidth onChange={(props) => onFieldChange(
              props.currentTarget.name, props.currentTarget.value)} />}
          {isfilterDataLoading && <Skeleton variant='rectangular' />}
        </Grid>
        <Grid item xs={10}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            endIcon={<SearchIcon />}
            disabled={isfilterDataLoading && isLoadingMatches}
            onClick={handleSearch}>
            Søg
          </Button>
          <InputLabel style={{ marginBottom: '2%' }}>Skærmvisning er optimeret til bredformat (flip din mobil).</InputLabel>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LeagueMatchTable isLivescore={props.isLivescore} isReadonly={props.isReadonly} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

interface SearchParameters {
  selectedClub: SelectItem,
  selectedRegion: SelectItem,
  selectedAgeGroup: SelectItem,
  leagueMatchId: FormControl,
  startDate: FormControl,
  endDate: FormControl
}

