export interface SelectItem{
    value: any,
    name: any,
    extendedProps?: any
}

export enum DropdownResourceType{
    Club,
    Region,
    AgeGroup,
    ClassGroup,
    GeoRegion,
    Discipline
}

export type Modify<T, R> = Omit<T, keyof R> & R;