import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStateProperties } from "../../commons/baseReducer";
import {default as lodash} from 'lodash';
import { RootState } from "../../store/rootReducer";
import { SportResultsDropdown } from "../../commons/ui/dropdown/sportResultsDropdown";
import { SelectItem } from "../../commons/common.types";
import { ClassGroupApiVIewModel } from "../../commons/classGroup/model";
import { classGroupActions } from "../../commons/classGroup/classGroupActionReducer";
import Skeleton from "../muiComponents/core/Skeleton";

type props = {
    handleChange: (event: any) => void
}

export const ClassGroupDropdown: FC<props> = (props) => {
    const classGroupState = useSelector<RootState, ReducerStateProperties<ClassGroupApiVIewModel[]>>((state) => state.classGroups)
    const [selectItems, setSelectItems] = useState<SelectItem[]>(null);
    const distapch = useDispatch();
    useEffect(() => {
        if(!classGroupState.loadStarted && lodash.isEmpty(classGroupState.errors)){            
            distapch(classGroupActions.getAll())
            
        }
        const selectItemIds = selectItems?.map(x => x.value);
        const classGroupIds = classGroupState?.model?.map(x => x.classID);
        if(!lodash.isEmpty(classGroupState.model) && !lodash.isEqual(selectItemIds, classGroupIds)){
            setSelectItems(classGroupState.model?.map(x => {
                const item: SelectItem = {
                    name: x.className,
                    value: x.classID
                }
                return item;
            }))
        }
    }, [classGroupState])
    return(
        <React.Fragment>
            {selectItems &&
                <SportResultsDropdown label="Række" data={selectItems} handleChange={props.handleChange}/>
            }
            {
               classGroupState.isLoading && classGroupState.loadStarted && <Skeleton variant="rectangular" />
            }            
        </React.Fragment>
    )
}