import { RegionsService } from "../../api/regions.service";
import { BaseActionReducer } from "../baseActionReducer";

export const regionActionReducer = new BaseActionReducer({ keyStart: 'REGION', payloadName: 'region' });
const syncActionCreators = regionActionReducer.buildSyncActions();

const regionService = new RegionsService();

const asyncActionCreators = {
    getall: () => {
        return regionActionReducer.createAsyncAction(
            regionService.getAll());
    },
};

export const regionActions = {
    ...syncActionCreators,
    ...asyncActionCreators
};