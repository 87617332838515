import { ResponseHandlerState } from "../../components/responseHandler/ResponseHandler";
import { BaseActionReducer } from "../baseActionReducer";

export const responseHandlerActionReducer = new BaseActionReducer({ keyStart: 'RESPONSE_HANDLER', payloadName: 'responsehandler' });
const syncResponseHandlerActions = responseHandlerActionReducer.buildSyncActions();

const asyncResponseHandlerActionCreators = {
    call: (state: ResponseHandlerState) => {
        return responseHandlerActionReducer.createAsyncAction(Promise.resolve(state), (response: any) => response);
    }
};

export const responseHandlerActions: typeof asyncResponseHandlerActionCreators = {
    ...syncResponseHandlerActions,
    ...asyncResponseHandlerActionCreators
};
