import axios from "axios";
import { extend } from "lodash";
import { AgeGroupApiViewModel } from "../commons/ageGroup/model";
import { BaseService } from "./base.service";
import { TournamentClassResponse } from "../commons/tournamentClass/model";

export class TournamentClassService extends BaseService{
    constructor(){
        super('TournamentClass')
    }

    public getRealtedWithTournament = (tournamentClassId: number): Promise<TournamentClassResponse> => {
        const url = `${this.baseUrl}?tournamentClassId=${tournamentClassId}`;
        return axios.get<TournamentClassResponse>(url).then(res => res.data);
    }
}