import { Action, ActionFunctionAny, ReduxCompatibleReducer } from 'redux-actions';
import { BaseActions } from './baseActions';
import { BaseReducer, ReducerStateProperties } from './baseReducer';
import { IActionNames, IKeyStartAndPayloadName } from "./keyAndPayloadName";
const camelcase = require('camelcase');

export { RequestStatus } from './baseReducer';
type SyncAction<K extends string | number | symbol> = {
    [k in K]: ActionFunctionAny<Action<any>>
}

export class BaseActionReducer<TModel> {
    keyStartAndPayloadName: IKeyStartAndPayloadName;
    keyNames: IActionNames;
    baseReducer: BaseReducer<TModel>;
    baseActions: BaseActions;

    constructor(keyStartAndPayloadName: IKeyStartAndPayloadName) {
        this.keyStartAndPayloadName = keyStartAndPayloadName;
    }

    buildReducer(loadByDefault = true): ReduxCompatibleReducer<ReducerStateProperties<TModel>, TModel> {
        if (!this.baseReducer)
            this.baseReducer = new BaseReducer(this.keyStartAndPayloadName, loadByDefault);
        return this.baseReducer.buildReducer();
    }

    buildSyncActions() {
        if (!this.baseActions)
            this.baseActions = new BaseActions(this.keyStartAndPayloadName);
        return this.baseActions.buildSyncActions();
    }

    createAsyncAction<TPayload>(promise: Promise<TPayload>, successResponseFunc?: (response: any) => boolean, isReset?: boolean): (dispatch: any) => Promise<TPayload> | Promise<void> {
        if (!this.baseActions)
            this.baseActions = new BaseActions(this.keyStartAndPayloadName);
        return this.baseActions.createAsyncAction(promise, successResponseFunc);
    }
}