import { BaseActionReducer } from './baseActionReducer';
import { storageModeItemName } from './utils/ui.utils';

export const uiModeActionReducer = new BaseActionReducer({ keyStart: 'UIMODE', payloadName: 'mode' });
const syncUiModeActions = uiModeActionReducer.buildSyncActions();

const asyncUiModeActionCreators = {
    toggleMode: (value: 'light' | 'dark') => {
        const newVal = value === 'dark' ? 'light' : 'dark'
        localStorage.setItem(storageModeItemName, newVal)
        return uiModeActionReducer.createAsyncAction(Promise.resolve(newVal));
    }
};

export const uiModeActions = {
    ...syncUiModeActions,
    ...asyncUiModeActionCreators
};

