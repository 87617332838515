import { isEmpty } from 'lodash';
import { ContextPages } from '../../pages/contextPagesEnum';

export const storageModeItemName = 'mode'

export type ModeType = "light" | "dark";

export const getMode = () : ModeType =>{
    const initialMode = 'dark'
    let modeStr = localStorage.getItem(storageModeItemName)
    if(!modeStr){
        modeStr = initialMode
      localStorage.setItem(storageModeItemName, initialMode)
    }
    return modeStr === initialMode ? 'dark' : 'light'
}

export const getFontColor = () => {
    const mode = getMode()
    return mode === 'light' ? '#000000': '#ffffff'
}

export const getPage = (name:string, isLivescore: boolean, isReadonly?:boolean) => {
    let page  =  `/${name}`
    if(isEmpty(name)){
        return ''
    }
    if(isLivescore){
        page = `/${ContextPages.Livescore}/${name}`
    }else if(isReadonly){
        page = `/${ContextPages.Readonly}/${name}`
    }
    return page;
}