import React, { FC, useState } from "react";
import Dialog from "./muiComponents/core/Dialog";
import useTheme from "./muiComponents/core/useTheme";
import useMediaQuery from "./muiComponents/core/useMediaQuery";
import Grid from "./muiComponents/core/Grid";
import Button from "./muiComponents/core/Button";

type props = {
    onDecission: (isMatchFinished: boolean) => void
}

export const CounterFinishMatch: FC<props> = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const [open, setOpen] = useState<boolean>(true);


    const handleSubmit = (value: boolean) => {
        props.onDecission(value)
    }    

    return (
        <React.Fragment>
            <Dialog
            disableEscapeKeyDown = {true}
            hideBackdrop
            className='dialogCustom'            
            fullScreen={fullScreen}
            onClose={() => setOpen(false)} open={open}>
                <div style={{padding: '5%'}}>
                    <Grid container alignItems="center" spacing={2} style={{justifyContent: 'space-around'}}>
                        <Grid item xs={12} style={{textAlign:'center'}}>
                            <h2>Kampen er færdig. Er du sikker?</h2>
                        </Grid>
                        <Grid item>
                                    <Button
                                    variant="contained" size="small" color="inherit"
                                    onClick={() => handleSubmit(false)}>
                                        No
                                    </Button>
                            </Grid> 
                            <Grid item>
                                    <Button
                                    variant="contained" size="small" color="secondary"
                                    onClick={() => handleSubmit(true)}>
                                        Yes
                                    </Button>
                            </Grid>                                                       
                    </Grid>
                </div>
            </Dialog>            
        </React.Fragment>
    )
}