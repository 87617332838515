
import {default as lodash} from 'lodash';
import { BaseActionReducer } from './baseActionReducer';

export const redirectActionReducer = new BaseActionReducer({ keyStart: 'REDIRECT', payloadName: 'redirect' });
const syncRedirectActions = redirectActionReducer.buildSyncActions();

const asyncRedirecActionCreators = {
    postRedirectPath: (url: string) => {
        const redirect: IRedirect = {
            path: url
        }
        return redirectActionReducer.createAsyncAction(Promise.resolve(redirect));
    }
};

export const redirectActions = {
    ...syncRedirectActions,
    ...asyncRedirecActionCreators
};

export interface IRedirect{
    path: string
}