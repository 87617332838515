import axios from "axios";
import { GeoRegionApiVIewModel } from "../commons/geoRegion/model";
import { GetTournamentApplicationsAdmin, Tournament, TournamentEvent, TournamentPatchRequest } from "../commons/tournament/model";
import { BaseService } from "./base.service";

export class TournamentService extends BaseService {
  constructor() {
    super('Tournament')
  }

  public get = (sr: TournamentPatchRequest): Promise<GetTournamentApplicationsAdmin> => {
    const url = `${this.baseUrl}`;
    return axios.patch<GetTournamentApplicationsAdmin>(url, sr).then(res => res.data);
  }

  public getTournamentEvents = (tournamentClassId: number, tournamentEventId?: number): Promise<TournamentEvent[]> => {
    let url: string
    if (tournamentEventId) {
      url = `${this.baseUrl}?tournamentClass=${tournamentClassId}&tournamentEventId=${tournamentEventId}`;
    } else {
      url = `${this.baseUrl}?tournamentClass=${tournamentClassId}`;
    }
    
    return axios.get<TournamentEvent[]>(url).then(res => res.data);
  }
  public getTournamentEventsByMultiTe = (tournamentClassId: number, tournamentEventIds: number[]): Promise<TournamentEvent[]> => {
    const url = `${this.baseUrl}?tournamentClass=${tournamentClassId}`;

    return axios.post<TournamentEvent[]>(url, tournamentEventIds).then(res => res.data);
  }
  public getByTournamentClassId = (tournamentId: number): Promise<Tournament> => {
    const url = `${this.baseUrl}/id?tournamentClassId=${tournamentId}`;
    return axios.get<Tournament>(url).then(res => res.data);
  }

  public updateAgeGroup = (tournamentClassId: number, ageGroupId: number): Promise<TournamentEvent> => {
    const url = `${this.baseUrl}/agegroup?tournamentClassId=${tournamentClassId}&ageGroupId=${ageGroupId}`;
    return axios.put<TournamentEvent>(url).then(res => res.data);
  }
}