import axios from "axios";
import { TournamentEventMatch } from "../commons/tournament/model";
import { BaseService } from "./base.service";

export class TournamentEventMatchService extends BaseService{
    constructor(){
        super('TournamentEventMatch')
    }

    public update = (payload: TournamentEventMatch): Promise<TournamentEventMatch> => {
        const url = `${this.baseUrl}`;
        return axios.post<TournamentEventMatch>(url, payload).then(res => res.data);
    }
}