import { SelectItem } from "../common.types";

export enum DisciplineCategory {
    Single,
    Double,
    Mixed
}

export enum DisciplineGender {
    Men,
    Women,
    Mixed
}

type DisciplineKeys = "MixedDoubles" | "WomensSingles" | "MensSingles" | "WomensDoubles" | "MensDoubles" | "Single" | "Double";

type DisciplineMap = { [discipline in DisciplineKeys]: { LongName: string, ShortName: string, Label: string, Index: number } };

export const DisciplineCodeMapping: DisciplineMap = {
    MensSingles: {
        Index: 0,
        LongName: "MensSingles",
        ShortName: "HS",
        Label: 'Herresingle'
    },
    WomensSingles: {
        Index: 1,
        LongName: "WomensSingles",
        ShortName: "DS",
        Label: 'Damesingle'
    },
    MensDoubles: {
        Index: 2,
        LongName: "MensDoubles",
        ShortName: "HD",
        Label: 'Herredouble'
    },
    WomensDoubles: {
        Index: 3,
        LongName: "WomensDoubles",
        ShortName: "DD",
        Label: 'Damedouble'
    },
    MixedDoubles: {
        Index: 4,
        LongName: "MixedDoubles",
        ShortName: "MD",
        Label: 'Mixdouble'
    },
    Single: {
        Index: 5,
        LongName: "Single",
        ShortName: "S",
        Label: 'Single'
    },
    Double: {
        Index: 6,
        LongName: "Double",
        ShortName: "D",
        Label: 'Double'
    }
}

export class Disciplines {
    static getShortName(longName: string): string {
        if (!longName) return;

        const mapping = Object.keys(DisciplineCodeMapping).filter(key => DisciplineCodeMapping[key].LongName === longName)
            .map(key => DisciplineCodeMapping[key])[0];
        return mapping ? mapping.ShortName : undefined;
    }

    static getLongName(shortName: string): string {
        if (!shortName) return;

        const mapping = Object.keys(DisciplineCodeMapping).filter(key => DisciplineCodeMapping[key].ShortName === shortName)
            .map(key => DisciplineCodeMapping[key])[0];
        return mapping ? mapping.LongName : undefined;
    }

    static getCodes(): SelectItem[]{
        return Object.keys(DisciplineCodeMapping).map(key =>{
            const result: SelectItem = {
                name: DisciplineCodeMapping[key].ShortName,
                value: DisciplineCodeMapping[key].LongName
            };
            return result;
        })
    }

    static getLabel(longname: string): string {
        if (!longname) return;

        const mapping = Object.keys(DisciplineCodeMapping)
        .filter(key => DisciplineCodeMapping[key].LongName === longname)
            .map(key => DisciplineCodeMapping[key])[0];
        return mapping ? mapping.Label : undefined;
    }

    static getDisciplineCategoryFromCode(disciplineCode: string): DisciplineCategory {
        switch (disciplineCode) {
            case DisciplineCodeMapping.MixedDoubles.LongName:
            case DisciplineCodeMapping.MixedDoubles.ShortName:
                return DisciplineCategory.Mixed;
            case DisciplineCodeMapping.MensDoubles.LongName:
            case DisciplineCodeMapping.MensDoubles.ShortName:
            case DisciplineCodeMapping.WomensDoubles.LongName:
            case DisciplineCodeMapping.WomensDoubles.ShortName:
            case DisciplineCodeMapping.Double.LongName:
            case DisciplineCodeMapping.Double.ShortName:
                return DisciplineCategory.Double;
            case DisciplineCodeMapping.MensSingles.LongName:
            case DisciplineCodeMapping.MensSingles.ShortName:
            case DisciplineCodeMapping.WomensSingles.LongName:
            case DisciplineCodeMapping.WomensSingles.ShortName:
            case DisciplineCodeMapping.Single.LongName:
            case DisciplineCodeMapping.Single.ShortName:
                return DisciplineCategory.Single;
            default:
                return undefined;
        }
    }

    static getDisciplineGenderFromCode(disciplineCode: string): DisciplineGender {
        switch (disciplineCode) {
            case DisciplineCodeMapping.MixedDoubles.LongName:
            case DisciplineCodeMapping.MixedDoubles.ShortName:
            case DisciplineCodeMapping.Double.LongName:
            case DisciplineCodeMapping.Double.ShortName:
            case DisciplineCodeMapping.Single.LongName:
            case DisciplineCodeMapping.Single.ShortName:
                return DisciplineGender.Mixed;
            case DisciplineCodeMapping.MensDoubles.LongName:
            case DisciplineCodeMapping.MensDoubles.ShortName:
            case DisciplineCodeMapping.MensSingles.LongName:
            case DisciplineCodeMapping.MensSingles.ShortName:
                return DisciplineGender.Men;
            case DisciplineCodeMapping.WomensDoubles.LongName:
            case DisciplineCodeMapping.WomensDoubles.ShortName:
            case DisciplineCodeMapping.WomensSingles.LongName:
            case DisciplineCodeMapping.WomensSingles.ShortName:
                return DisciplineGender.Women;
        }
    }
}