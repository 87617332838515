import { LiveScoreMatchState } from "../../pages/leagueMatch/leagueMatchSetCounter";
import { TournamentClassLiveScoreState, TournamentEvent, UnifiedTournamentMatch } from "../tournament/model";
import * as lodash from 'lodash'

export const getLiveTournamentEvents = (state: TournamentClassLiveScoreState, tournamentEvents: TournamentEvent[]) => {

  let result: TournamentEvent[] = [...tournamentEvents]
  let hasBeenChanged = false;

  if (!lodash.isEmpty(state)) {
    result?.forEach(tEvent => {
      const liveTournamentEventMatches: UnifiedTournamentMatch[] = new Array<UnifiedTournamentMatch>();
      const tournamentEventState = state[tEvent?.tournamentEventId]
      tEvent.unifiedTournamentMatches.forEach(tMatch => {
        if (!lodash.isNil(tournamentEventState)) {
          const liveScoreMatch = tournamentEventState[tMatch.matchId]; 
          if (!lodash.isEmpty(liveScoreMatch)) {
            const matchResult = processMatchResult(liveScoreMatch);  
            if (tMatch.result !== matchResult) {
              hasBeenChanged = true;
              tMatch.liveScore = true;
              tMatch.result = matchResult;
            }
            liveTournamentEventMatches.push(tMatch);
          }
        }
      })
    })

  }

  return {
    hasBeenChanged,
    result
  };
}

const processMatchResult = (liveScoreMatchState: LiveScoreMatchState) => {
  if(lodash.isNil(liveScoreMatchState?.matchResults)) {
    return;
  }

  return liveScoreMatchState?.matchResults
    .sort((a, b) => a.setIndex > b.setIndex ? 1 : -1)
    .map(mr => `${mr.score1}/${mr.score2}`).join(',')
}

export const randomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;