import React, { FC } from "react";
import { Alert as MuiAlert } from "@mui/lab";

type Props = React.ComponentProps<typeof MuiAlert>;

const Alert: FC<Props> = (props) => {
    let color: string | undefined;
    if(props.severity === "warning"){
        color = "#fff"
    }
    return <MuiAlert {...props} style={{ color }} />
}

export default Alert;