import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { uiModeActions } from "../commons/uiModeActionReducer";
import { getMode } from "../commons/utils/ui.utils";
import FormControlLabel from "./muiComponents/core/FormControlLabel";
import Switch from "./muiComponents/core/Switch";

export const UiModeComponent: FC = () => {
    const label = 'Mørk tilstand'
    const dispatch = useDispatch()
    const handleChange = () => {
        dispatch(uiModeActions.toggleMode(getMode()))
    }
    return(
        <React.Fragment>
            <FormControlLabel control={<Switch color="default"/>} label={label} value= {getMode() === 'dark'} onChange={() => handleChange()}/>
        </React.Fragment>
    )
}