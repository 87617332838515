import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { createStore, applyMiddleware, AnyAction, Action } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const store = createStore(rootReducer, applyMiddleware(thunk));

type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): Dispatch<AnyAction> => useDispatch<Dispatch<Action<unknown>>>();

export default store;