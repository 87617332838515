import {default as lodash} from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { responseHandlerActions } from '../../commons/responseHandler/responseHandlerActionReducer';
import { ErrorHandler, ErrorHandlerProps } from './ErrorHandler';
import { SuccessHandler } from './SuccessHandler';

export type ResponseHandlerState = {
    success?: boolean,
    warningMsg?: string,
    error?: ErrorHandlerProps,
}

const initState: ResponseHandlerState = {
    error: null,
    success: false
}

export const ResponseHandler: React.FC = () => {
    const dispatch = useDispatch();
    const [state, setState] = React.useState(initState)
    const [open, setOpen] = React.useState(false);
    const {success, error, warningMsg} = state;
    useSelector(rootState => {
        const model = (rootState as any).responsehandler.model as ResponseHandlerState
        const responsehandler = model ? model : initState ;
        if(!lodash.isEqual(responsehandler, state)){
            setState(responsehandler);
            setOpen(true);
        }
    })
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);        
        dispatch(responseHandlerActions.call(initState))
    };
    if(error){        
        return(
            <ErrorHandler 
            appError={error.appError} errorBody404={error.errorBody404} errorBody={error.errorBody} 
            systemErrors={error.systemErrors} errorTitle404={error.errorTitle404} handleClose={handleClose}
            isCustomError={error.isCustomError}/>
        )
    }
    else if(!success && warningMsg){
        return(
            <SuccessHandler isTriggered={true} handleClose={handleClose} warningMsg={warningMsg} open={open}/>
        )
    }else{
        return(null)
    }
}