import { BlockMatchService } from "../../api/blockMatch.service";
import { BaseActionReducer } from "../baseActionReducer";
import { BlockMatchToSave } from "./model";

export const blockMatchActionReducer = new BaseActionReducer({ keyStart: 'BLOCKMATCH', payloadName: 'model' });
export const blockMatchesActionReducer = new BaseActionReducer({ keyStart: 'BLOCKMATCHES', payloadName: 'model' });

const syncBlockMatchActions = blockMatchActionReducer.buildSyncActions();
const syncBlockMatchesActions = blockMatchesActionReducer.buildSyncActions();

const blockMatchClient = new BlockMatchService();

const asyncBlockMatchCreators = {
  getAllBlockMatch: () => {
    return blockMatchesActionReducer.createAsyncAction(
      blockMatchClient.getAllBlockMatch());
  },

  saveBlockMatch: (payload: BlockMatchToSave) => {
    return blockMatchActionReducer.createAsyncAction(
      blockMatchClient.saveBlockMatch(payload));
  },

  deleteBlockMatch: (payload: BlockMatchToSave) => {
    return blockMatchActionReducer.createAsyncAction(
      blockMatchClient.deleteBlockMatch(payload));
  }
  
};

export const blockMatchActions = {
  ...syncBlockMatchActions,
  ...syncBlockMatchesActions,
  ...asyncBlockMatchCreators
};