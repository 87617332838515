import React, { FC } from "react";
import { Tab as MuiTab, TabProps } from "@mui/material";

const Tab: FC<TabProps> = (props) => {
    return <MuiTab style={{
        color: "#fff",
        ...props.style
    }} {...props} />
}

export default Tab;