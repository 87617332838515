import React, { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer"
import { CourtMatchesResponse } from "../../commons/courts/model"
import { RootState } from "../../store/rootReducer"
import * as lodash from 'lodash'
import { courtsActions } from "../../commons/courts/courtsActionReducer"
import { SelectItem } from "../../commons/common.types"
import { LeagueMatchPlayerViewModel } from "../../commons/leagueMatch/model"
import { ClubResponse } from "../../commons/clubs/model"
import { clubActions } from "../../commons/clubs/clubsActionReducer"
import { getMode } from "../../commons/utils/ui.utils"
import makeStyles from "../muiComponents/core/makeStyles";
import Popper from "../muiComponents/core/Popper";
import Grid from "../muiComponents/core/Grid";
import { AutocompleteRenderOptionState } from "@mui/material";
import FormControl from "../muiComponents/core/FormControl";
import Autocomplete from "../muiComponents/core/Autocomplete";
import TextField from "../muiComponents/core/TextField";
import Skeleton from "../muiComponents/core/Skeleton";

type props = {
    leagueMatchId: number,
    leagueGroupTeamId1: number,
    leagueGroupTeamId2: number,
    currentLeagueMatchIndividualMatchId: number,
    handleLeagueMatchIndividualChange: (leagueMatchIndividualMatchId: number) => void
}

const styles = () =>{
    return {
        popper: {
            width: "fit-content"
        }
    }
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const PopperMy = function (props: any) {
    return <Popper {...props} style={styles().popper} placement="bottom-start" />;
 };


export const CourtMatchesFormIndividualMatch: FC<props> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const courtsMatchesState = useSelector<RootState>(x => {
        return x.courts.courtsMatches
    }) as ReducerStateProperties<CourtMatchesResponse[]>
    const clubsState = useSelector<RootState>((x) => {
        return x.clubs.all;
    }) as ReducerStateProperties<ClubResponse[]>
    const [selectItems, setSelectItems] = useState<SelectItem[]>(new Array<SelectItem>())    
    const leagueMatchIndividualMatchIds = courtsMatchesState?.model?.map(x => x.match.leagueMatchIndividualMatchId)
    const mode = useSelector<RootState>(x => x.mode.model ?? getMode()) as 'light' | 'dark'
    useEffect(() => {
        if(!lodash.isEmpty(clubsState?.model) && clubsState.status == RequestStatus.NotInitiated){
            dispatch(clubActions.getAll())
        }
    }, [clubsState.model])

    const getSelectItemName = (controlProps: React.HTMLAttributes<HTMLLIElement>, courtMatchResponse: CourtMatchesResponse, isRender: boolean) => {

        if(!courtMatchResponse?.match?.teamPlayers){
            return;
        }
        const homePlayers = courtMatchResponse.match.teamPlayers[props.leagueGroupTeamId1];
        const awayPlayers = courtMatchResponse.match.teamPlayers[props.leagueGroupTeamId2];
        const joinSeparator = ", "
        const getdisplayName =(hp: LeagueMatchPlayerViewModel) => {
            return `${hp.player.name} ${clubsState.model?.find(c => c.clubId === Number(hp.player.clubId).valueOf())?.clubName}`
        }
        const style={color: mode == 'light' ? 'initial' : 'white'}
        const homeDisplay = `${homePlayers.map(getdisplayName).join(joinSeparator)}`
        const awayDisplay = `${awayPlayers.map(getdisplayName).join(joinSeparator)}`
        if(!isRender){
            return `${homeDisplay} ${awayDisplay}`
        }
        return (
            <div {...controlProps as any}>
                <Grid container spacing={3} xs={12}>
                    <Grid item xs={4}>
                        <label style={style}>{homeDisplay}</label>
                    </Grid>
                    <Grid item xs={4}>
                        <label style={style}>{awayDisplay}</label>
                    </Grid>
                    {courtMatchResponse.isAssign && 
                        <Grid item xs={4}>
                            <label style={style}>Allerede tildelt til: {courtMatchResponse.alreadyAssignedCourt.venueName} - {courtMatchResponse.alreadyAssignedCourt.courtNumber}</label>
                        </Grid>
                    }                   
                </Grid>
            </div>
        )
    }

    useEffect(() => {
        if(lodash.isEmpty(courtsMatchesState.model) && courtsMatchesState.status == RequestStatus.NotInitiated){
            dispatch(courtsActions.getMatchesForCourtByLeagueMatchId(props.leagueMatchId))
           
        }else if(!lodash.isEmpty(courtsMatchesState.model) && !lodash.isEqual(leagueMatchIndividualMatchIds, selectItems.map(x => x.value))){
            const selectItems: SelectItem[] = new Array<SelectItem>()
            courtsMatchesState.model.forEach(cm => {
                if(cm.match.leagueMatchIndividualMatchId === props.currentLeagueMatchIndividualMatchId){
                    return;
                }
                const name = getSelectItemName({}, cm, false) as string
                const {leagueMatchIndividualMatchId} = cm.match
                if(!(lodash.isEmpty(name) && lodash.isEmpty(leagueMatchIndividualMatchId))){
                    selectItems.push({
                        name: name,
                        value: leagueMatchIndividualMatchId
                    })
                }
            })
            if(selectItems.length > 0){
                setSelectItems(selectItems)
            }            
        }
    },[courtsMatchesState])
    const handleAssign = (option?: SelectItem) =>{
        props.handleLeagueMatchIndividualChange(option?.value)
    }


    const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: SelectItem,
        state: AutocompleteRenderOptionState) => {
            const courtResponse = courtsMatchesState.model
            .find(x => x.match.leagueMatchIndividualMatchId === option.value)
            return getSelectItemName(props, courtResponse, true)
        }
    const isEq = (option: SelectItem, value: SelectItem) => {
        return option.value === value.value
    }
    return(
        <React.Fragment>
            {selectItems &&
                <FormControl>
                    <Autocomplete
                        disableListWrap
                    className={classes.formControl}
                    noOptionsText={"Mangel på værdier"}
                    multiple={false}                                        
                    PopperComponent = {PopperMy}
                    fullWidth={true}
                    renderOption={(props, opt: SelectItem, state) => renderOption(props, opt, state)}
                    id={`courtMatchesForm-${props.leagueMatchId}`}
                    isOptionEqualToValue={isEq}
                    options={selectItems}
                    getOptionLabel={(option: SelectItem) => option.name}
                    onChange={(event: any, option: any) => handleAssign(option)}
                    renderInput={(params) => <TextField {...params} label={"Vælg spillere"} />}
                    />
            </FormControl> 
            }
            {
               courtsMatchesState.isLoading && courtsMatchesState.loadStarted && <Skeleton variant="rectangular" />
            } 
        </React.Fragment>
    )
}