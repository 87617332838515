import { Route, Routes } from "react-router-dom"
import { LeagueMatchSearch } from "./pages/leagueMatch/leagueMatchSearch"
import React from 'react'
import { LeagueMatchLineup } from "./pages/leagueMatch/leagueMatchLineup"
import { TournamentSearch } from "./pages/tournament/tournamentSearch"
import { TournamentEvents } from "./pages/tournament/tournamentEvents"
import { LeagueMatchSetCounter } from "./pages/leagueMatch/leagueMatchSetCounter"
import { CourtMatchViewer } from "./components/courts/courtMatchViewer"
import { ContextPages } from "./pages/contextPagesEnum"
import { TournamentCounter } from "./components/tournament/tournament.counter"
import { LeagueMatchLineupMoveToCheckPassword } from "./pages/leagueMatch/leagueMatchLineupMoveToCheckPassword"
import { TournamentMoveToCheckPassword } from "./pages/tournament/tournamentMoveToCheckPassword"


export const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/umpire/leagueMatch/:leagueMatchIdStr" element={<LeagueMatchLineupMoveToCheckPassword isLivescore={false} isUmpire={true} />} />
      <Route path="/count/leagueMatch/:leagueMatchIdStr" element={<LeagueMatchLineupMoveToCheckPassword isLivescore={false} isUmpire={false} />} />

      <Route path="/umpire/leagueMatch/:leagueMatchIdStr/:password" element={<LeagueMatchLineup isLivescore={false} isUmpire={true} />} />
      <Route path="/count/leagueMatch/:leagueMatchIdStr/:password" element={<LeagueMatchLineup isLivescore={false} isUmpire={false} />} />

      <Route path="/umpire/leagueMatch/counter/:leagueMatchIdStr/:leagueMatchIndividualMatchId/:password" element={<LeagueMatchSetCounter isLivescore={false} isUmpire={true} />} />
      <Route path="/count/leagueMatch/counter/:leagueMatchIdStr/:leagueMatchIndividualMatchId/:password" element={<LeagueMatchSetCounter isLivescore={false} isUmpire={false} />} />

      <Route path="/umpire/leagueMatch/counter/:leagueMatchIdStr/:leagueMatchIndividualMatchId" element={<LeagueMatchLineupMoveToCheckPassword isLivescore={false} isUmpire={true} />} />
      <Route path="/count/leagueMatch/counter/:leagueMatchIdStr/:leagueMatchIndividualMatchId" element={<LeagueMatchLineupMoveToCheckPassword isLivescore={false} isUmpire={false} />} />

      <Route path="/umpire/leagueMatch" element={<LeagueMatchSearch isLivescore={false} isUmpire={true} />} />
      <Route path="/count/leagueMatch" element={<LeagueMatchSearch isLivescore={false} isUmpire={false} />} />

      <Route path="/umpire/tournament" element={<TournamentSearch isLivescore={false} isUmpire={true} />} />
      <Route path="/count/tournament" element={<TournamentSearch isLivescore={false} isUmpire={false} />} />

      <Route path="/umpire/tournament/:tournamentClassIdStr">
        <Route path=":tournamentEventIdStr" element={<TournamentEvents isLivescore={false} isUmpire={true} />} />
        <Route path="" element={<TournamentEvents isLivescore={false} isUmpire={true} />} />
      </Route>

      <Route path="/count/tournament/:tournamentClassIdStr">
        <Route path=":tournamentEventIdStr" element={<TournamentEvents isLivescore={false} isUmpire={false} />} />
        <Route path="" element={<TournamentEvents isLivescore={false} isUmpire={false} />} />
      </Route>

      <Route path="/umpire/tournament/counter/:tournamentClassIdStr/:tournamentEventIdStr/:matchId/:clubId/:password" element={<TournamentCounter isLivescore={false} isUmpire={true} />} />
      <Route path="/count/tournament/counter/:tournamentClassIdStr/:tournamentEventIdStr/:matchId/:clubId/:password" element={<TournamentCounter isLivescore={false} isUmpire={false} />} />

      <Route path="/umpire/tournament/counter/:tournamentClassIdStr/:tournamentEventIdStr/:matchId/:clubId" element={<TournamentMoveToCheckPassword isLivescore={false} isUmpire={true} />} />
      <Route path="/count/tournament/counter/:tournamentClassIdStr/:tournamentEventIdStr/:matchId/:clubId" element={<TournamentMoveToCheckPassword isLivescore={false} isUmpire={false} />} />

      <Route path={`/${ContextPages.Livescore}/umpire/leagueMatch/counter/:leagueMatchIdStr/:leagueMatchIndividualMatchId`} element={<LeagueMatchSetCounter isLivescore={true} isUmpire={true} />} />
      <Route path={`/${ContextPages.Livescore}/count/leagueMatch/counter/:leagueMatchIdStr/:leagueMatchIndividualMatchId`} element={<LeagueMatchSetCounter isLivescore={true} isUmpire={false} />} />

      <Route path={`/${ContextPages.Livescore}/umpire/leagueMatch/:leagueMatchIdStr`} element={<LeagueMatchLineup isLivescore={true} isUmpire={true} />} />
      <Route path={`/${ContextPages.Livescore}/count/leagueMatch/:leagueMatchIdStr`} element={<LeagueMatchLineup isLivescore={true} isUmpire={false} />} />

      <Route path={`/${ContextPages.Livescore}/umpire/leagueMatch`} element={<LeagueMatchSearch isLivescore={true} isUmpire={true} />} />
      <Route path={`/${ContextPages.Livescore}/count/leagueMatch`} element={<LeagueMatchSearch isLivescore={true} isUmpire={false} />} />

      <Route path={`/${ContextPages.Livescore}/umpire/leagueMatch/:venueId/:courtNumber`} element={<CourtMatchViewer isLivescore={true} isUmpire={true} hubPath={'leaguematch'} />} />
      <Route path={`/${ContextPages.Livescore}/leagueMatch/:venueId/:courtNumber`} element={<CourtMatchViewer isLivescore={true} isUmpire={false} hubPath={'leaguematch'} />} />

      <Route path={`/${ContextPages.Livescore}/umpire/tournament/:tournamentClassIdStr/:tournamentEventIdStr`} element={<TournamentEvents isUmpire={true} isLivescore={true} />} />
      <Route path={`/${ContextPages.Livescore}/count/tournament/:tournamentClassIdStr/:tournamentEventIdStr`} element={<TournamentEvents isUmpire={false} isLivescore={true} />} />

      <Route path={`/${ContextPages.Livescore}/umpire/tournament`} element={<TournamentSearch isLivescore={true} isUmpire={true} />} />
      <Route path={`/${ContextPages.Livescore}/count/tournament`} element={<TournamentSearch isLivescore={true} isUmpire={false} />} />

      <Route path={`/${ContextPages.Livescore}/umpire/tournamentMatch/:venueId/:courtNumber`} element={<CourtMatchViewer isLivescore={true} isUmpire={true} hubPath={'tournament'} />} />
      <Route path={`/${ContextPages.Livescore}/tournamentMatch/:venueId/:courtNumber`} element={<CourtMatchViewer isLivescore={true} isUmpire={false} hubPath={'tournament'} />} />

      <Route path={`/${ContextPages.Livescore}/umpire/tournament/:tournamentClassIdStr`}>
        <Route path=":tournamentEventIdStr" element={<TournamentEvents isLivescore={true} isUmpire={true} />} />
        <Route path="" element={<TournamentEvents isLivescore={true} isUmpire={true} />} />
      </Route>
      <Route path={`/${ContextPages.Livescore}/count/tournament/:tournamentClassIdStr`}>
        <Route path=":tournamentEventIdStr" element={<TournamentEvents isLivescore={true} isUmpire={false} />} />
        <Route path="" element={<TournamentEvents isLivescore={true} isUmpire={false} />} />
      </Route>

      <Route path={`/${ContextPages.Livescore}/umpire/tournament/counter/:tournamentClassIdStr/:tournamentEventIdStr/:matchId/:clubId`} element={<TournamentCounter isLivescore={true} isUmpire={true} />} />
      <Route path={`/${ContextPages.Livescore}/count/tournament/counter/:tournamentClassIdStr/:tournamentEventIdStr/:matchId/:clubId`} element={<TournamentCounter isLivescore={true} isUmpire={false} />} />

      <Route path={`/${ContextPages.Readonly}/umpire/leagueMatch`} element={<LeagueMatchSearch isLivescore={false} isUmpire={true} isReadonly={true} />} />
      <Route path={`/${ContextPages.Readonly}/count/leagueMatch`} element={<LeagueMatchSearch isLivescore={false} isUmpire={false} isReadonly={true} />} />

      <Route path={`/${ContextPages.Readonly}/umpire/leagueMatch/:leagueMatchIdStr/:password`} element={<LeagueMatchLineup isLivescore={false} isUmpire={true} isReadonly={true} />} />
      <Route path={`/${ContextPages.Readonly}/count/leagueMatch/:leagueMatchIdStr/:password`} element={<LeagueMatchLineup isLivescore={false} isUmpire={false} isReadonly={true} />} />

      <Route path={`/${ContextPages.Readonly}/umpire/leagueMatch/counter/:leagueMatchIdStr/:leagueMatchIndividualMatchId/:password`} element={<LeagueMatchSetCounter isLivescore={false} isUmpire={true} isReadonly={true} />} />
      <Route path={`/${ContextPages.Readonly}/count/leagueMatch/counter/:leagueMatchIdStr/:leagueMatchIndividualMatchId/:password`} element={<LeagueMatchSetCounter isLivescore={false} isUmpire={false} isReadonly={true} />} />

      <Route path={`/${ContextPages.Readonly}/umpire/tournament`} element={<TournamentSearch isLivescore={false} isUmpire={true} isReadonly={true} />} />
      <Route path={`/${ContextPages.Readonly}/count/tournament`} element={<TournamentSearch isLivescore={false} isUmpire={false} isReadonly={true} />} />

      <Route path={`/${ContextPages.Readonly}/umpire/tournament/:tournamentClassIdStr`}>
        <Route path=":tournamentEventIdStr" element={<TournamentEvents isLivescore={false} isUmpire={true} isReadonly={true} />} />
        <Route path="" element={<TournamentEvents isLivescore={false} isUmpire={true} isReadonly={true} />} />
      </Route>

      <Route path={`/${ContextPages.Readonly}/count/tournament/:tournamentClassIdStr`}>
        <Route path=":tournamentEventIdStr" element={<TournamentEvents isLivescore={false} isUmpire={false} isReadonly={true} />} />
        <Route path="" element={<TournamentEvents isLivescore={false} isUmpire={false} isReadonly={true} />} />
      </Route>

      <Route path={`/${ContextPages.Readonly}/umpire/tournament/counter/:tournamentClassIdStr/:tournamentEventIdStr/:matchId/:clubId`} element={<TournamentCounter isLivescore={false} isUmpire={true} isReadonly={true} />} />
      <Route path={`/${ContextPages.Readonly}/count/tournament/counter/:tournamentClassIdStr/:tournamentEventIdStr/:matchId/:clubId`} element={<TournamentCounter isLivescore={false} isUmpire={false} isReadonly={true} />} />
    </Routes>
  )
}

export type BaseProps = {
  isLivescore: boolean,
  isUmpire: boolean,
  isReadonly?: boolean
}