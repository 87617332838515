import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStateProperties } from "../../commons/baseReducer";
import {default as lodash} from 'lodash';
import { RootState } from "../../store/rootReducer";
import { SportResultsDropdown } from "../../commons/ui/dropdown/sportResultsDropdown";
import { SelectItem } from "../../commons/common.types";
import { RegionResponse } from "../../commons/regions/model";
import { regionActions } from "../../commons/regions/regionActionReducer";
import Skeleton from "../muiComponents/core/Skeleton";

type props = {
    handleChange: (event: any) => void,
    multiple?: boolean
}

export const RegionDropdown: FC<props> = (props) => {
    const regionsState = useSelector<RootState, ReducerStateProperties<RegionResponse[]>>((state) => state.regions)
    const [selectItems, setSelectItems] = useState<SelectItem[]>(null);
    const distapch = useDispatch();
    useEffect(() => {
        if(!regionsState.loadStarted && lodash.isEmpty(regionsState.errors)){            
            distapch(regionActions.getall())
            
        }
        const selectItemIds = selectItems?.map(x => x.value);
        const regionIds = regionsState?.model?.map(x => x.regionId);
        if(!lodash.isEmpty(regionsState.model) && !lodash.isEqual(selectItemIds, regionIds)){
            setSelectItems(regionsState.model?.map(x => {
                const item: SelectItem = {
                    name: `${x.name} (${x.shortName})`,
                    value: x.regionId
                }
                return item;
            }))
        }
    }, [regionsState])
    return(
        <React.Fragment>
            {selectItems &&
                <SportResultsDropdown multiple={props.multiple} label="Kreds" data={selectItems} handleChange={props.handleChange}/>
            }
            {
               regionsState.isLoading && regionsState.loadStarted && <Skeleton variant="rectangular" />
            }              
        </React.Fragment>
    )
}