import * as React from 'react';
import { FC } from 'react';
import makeStyles from "../components/muiComponents/core/makeStyles";
import FormLabel from "../components/muiComponents/core/FormLabel";
import { Theme } from "@mui/material";

type Props = {
    message?: string
};


export const FormValidationError: FC<Props> = props => {
    const styles = makeStyles((theme: Theme) => ({
        validationError: {
            color: 'red'
        }
    }))();
    return (
        <React.Fragment>
            <FormLabel className={styles.validationError}>{ props.message }</FormLabel>
        </React.Fragment>
    )
}