import React, { FC, useState } from "react"
import { useSelector } from "react-redux"
import { AgeGroupClass, TournamentEvent } from "../../commons/tournament/model"
import { RootState } from "../../store/rootReducer"
import { TournamentEventRowId, TournamentEventTable } from "./tournamentEvent.table";
import { default as lodash } from 'lodash';
import { AgeGroupClassState } from "../ageGroupClass/ageGroupClassState";

type props = {
  tournamentEvents: TournamentEvent[],
  onRowSelect: (event: TournamentEventRowId) => void,
  showOnlyEmpty: boolean
}

export const TournamentEventDetails: FC<props> = (props) => {
  const hasMatchEvents = props.tournamentEvents.length > 0 ? props.tournamentEvents
    ?.map(x => x.unifiedTournamentMatches)?.reduce((a, b) => a.concat(b))
    ?.some(x => !lodash.isEmpty(x)) : false
  const [ageGroupClass, setAgeGroupClass] = useState<AgeGroupClass[]>()

  const isLoading = useSelector<RootState>((state) => {
    const { isLoading, loadStarted } = state.tournaments.tournamentEvents
    return isLoading && loadStarted;
  }) as boolean;

  return (
    <React.Fragment>
      <AgeGroupClassState onStateChange={(model: AgeGroupClass[]) => setAgeGroupClass(model)} />
      {
        hasMatchEvents && ageGroupClass &&
        <TournamentEventTable
          ageGroupClasses={ageGroupClass}
          showOnlyEmpty={props.showOnlyEmpty}
          tournamentEvents={props.tournamentEvents}
          isLoading={isLoading}
          onSelect={props.onRowSelect}
        />
      }
      {
        !hasMatchEvents && <h2>Ingen kanpe i denne kategori</h2>
      }
    </React.Fragment>
  )
}