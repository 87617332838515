import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStateProperties } from "../../commons/baseReducer";
import { ClubResponse } from "../../commons/clubs/model";
import {default as lodash} from 'lodash';
import { RootState } from "../../store/rootReducer";
import { clubActions } from "../../commons/clubs/clubsActionReducer";
import { SportResultsDropdown } from "../../commons/ui/dropdown/sportResultsDropdown";
import { SelectItem } from "../../commons/common.types";
import Skeleton from "../muiComponents/core/Skeleton";
type props = {
    handleChange: (event: any) => void,
    isMultiple?: boolean
}

export const ClubDropdown: FC<props> = (props) => {
    const clubState = useSelector<RootState, ReducerStateProperties<ClubResponse[]>>((state) => state.clubs.all)
    const [selectItems, setSelectItems] = useState<SelectItem[]>(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if(!clubState.loadStarted && lodash.isEmpty(clubState.errors)){            
            dispatch(clubActions.getAll())
            
        }
        const selectItemIds = selectItems?.map(x => x.value);
        const clubIds = clubState?.model?.map(x => x.clubId);
        if(!lodash.isEmpty(clubState.model) && !lodash.isEqual(selectItemIds, clubIds)){
            setSelectItems(clubState.model?.map(x => {
                const item: SelectItem = {
                    name: x.clubName,
                    value: x.clubId
                }
                return item;
            }))
        }
    }, [clubState])
    return(
        <React.Fragment>
            {selectItems &&
                <SportResultsDropdown multiple={props.isMultiple} label="Klub" data={selectItems} handleChange={props.handleChange}/>
            }
            {
               clubState.isLoading && clubState.loadStarted && <Skeleton variant="rectangular" />
            }
        </React.Fragment>
    )
}