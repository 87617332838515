import { TournamentEventMatchService } from "../../api/tournamentEventMatch.service";
import { BaseActionReducer } from "../baseActionReducer";
import { TournamentEventMatch } from "../tournament/model";

export const tournamentEventMatchActionReducer = new BaseActionReducer({ keyStart: 'TOURNAMENT_EVENT_MATCH', payloadName: 'tournamentEventMatch' });

const tournamentEventMatchSyncActionCreators = tournamentEventMatchActionReducer.buildSyncActions();

const tournamentEventMatchClient = new TournamentEventMatchService();

const asyncActionCreators = {
    update: (payload: TournamentEventMatch) => {
        return tournamentEventMatchActionReducer.createAsyncAction(
            tournamentEventMatchClient.update(payload));
    
}};

export const tournamentEventMatchActions = {
    ...tournamentEventMatchSyncActionCreators,
    ...asyncActionCreators
};