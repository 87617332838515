export interface IKeyStartAndPayloadName {
    keyStart: string,
    payloadName: string
}

export interface IActionNames {
    START: string,
    SUCCESS: string,
    FAILURE: string,
    RESET: string
}

export const generateKeyNames = (keyAndPayloadNames: IKeyStartAndPayloadName): IActionNames => {
    return {
        START: keyAndPayloadNames.keyStart + "_FETCH_START",
        SUCCESS: keyAndPayloadNames.keyStart + "_FETCH_SUCCESS",
        FAILURE: keyAndPayloadNames.keyStart + "_FETCH_FAILURE",
        RESET: keyAndPayloadNames.keyStart + "_RESET"
    };
}