import React, { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReducerStateProperties, RequestStatus } from "../../commons/baseReducer"
import { BulkCourtResponse } from "../../commons/courts/model"
import { RootState } from "../../store/rootReducer"
import * as lodash from 'lodash'
import { courtsActions } from "../../commons/courts/courtsActionReducer"
import { SportResultsDropdown } from "../../commons/ui/dropdown/sportResultsDropdown"
import { SelectItem } from "../../commons/common.types"
import Skeleton from "../muiComponents/core/Skeleton";

type props = {
  clubId: number,
  handleCourtChange: (courtId: number, courtNumber: number, isMatchii: boolean) => void
}

export const CourtForm: FC<props> = (props) => {
  const dispatch = useDispatch();
  const venueMatchState = useSelector<RootState>(x => x.courts.venueCourts) as ReducerStateProperties<BulkCourtResponse>
  const [selectItems, setSelectItems] = useState<SelectItem[]>(new Array<SelectItem>())
  let courtIds: number[] = []

  if (!lodash.isEmpty(venueMatchState.model)) {
    const { courtResponses, venueCourts } = venueMatchState?.model;
    const courtResponsesCourtIds = courtResponses.map(c => c.courtId);
    const venueCourtsCourtIds = venueCourts.flatMap(v => v.courts || []).map(x => x.courtId);

    courtIds = [
      ...courtResponsesCourtIds,
      ...venueCourtsCourtIds
    ];
  }

  useEffect(() => {
    if (lodash.isEmpty(venueMatchState.model) && venueMatchState.status == RequestStatus.NotInitiated) {
      dispatch(courtsActions.getCourtsByClubId(props.clubId))
    } else if (!lodash.isEmpty(venueMatchState.model) && !lodash.isEqual(courtIds, selectItems.map(x => x.value))) {
      const selectItems: SelectItem[] = new Array<SelectItem>()
      const { courtResponses, venueCourts } = venueMatchState.model
      const courts = [...courtResponses]
      courts.forEach(vm => {
        selectItems.push({
          name: `${vm?.venueName ?? ''} ${vm?.courtNumber ?? ''} Is Matchii:  ${vm.isMatchii}`,
          value: vm.courtId,
          extendedProps: {
            isMatchii: vm.isMatchii,
            courtNumber: vm.courtNumber
          }

        })
      })
      if (selectItems.length > 0) {
        setSelectItems(selectItems)
      }
    }
  }, [venueMatchState, props.clubId])

  const handleAssign = (event: SelectItem) => {
    props.handleCourtChange(event?.value, event?.extendedProps?.courtNumber, event?.extendedProps?.isMatchii)
  }

  return (
    <React.Fragment>
      {selectItems &&
        <SportResultsDropdown multiple={false} label="V&#230;lg bane" data={selectItems}
          handleChange={handleAssign} />
      }
      {
        venueMatchState.isLoading && venueMatchState.loadStarted && <Skeleton variant="rectangular" />
      }
    </React.Fragment>
  )
}